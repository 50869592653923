var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$windowSize.isMobile && _vm.shouldShowAds
    ? _c(
        "div",
        [
          _vm.isGoogleAdsEnabled
            ? [
                _c("HideAdsBtn", { staticClass: "hide-ads" }),
                _vm._v(" "),
                _c("p", { staticClass: "advertisement-info" }, [
                  _vm._v(" " + _vm._s(_vm.translations["shared_Ads_Label"])),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { key: _vm.refreshKey, class: { "ad-border": !_vm.isProd } },
                  [
                    _c("Adsense", {
                      attrs: {
                        "data-ad-client": "ca-pub-2431348456451065",
                        "data-ad-slot": "2827628646",
                        "data-ad-format": "auto",
                        "data-full-width-responsive": "yes",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isPubliftEnabled
            ? [
                _c("HideAdsBtn", { staticClass: "hide-ads" }),
                _vm._v(" "),
                _c("p", { staticClass: "advertisement-info" }, [
                  _vm._v(_vm._s(_vm.translations["shared_Ads_Label"])),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticStyle: { "min-height": "50px", "max-height": "300px" },
                  attrs: {
                    id: "ad-mobile-incontent2",
                    "data-fuse": "23201782316",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }