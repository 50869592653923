var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("div", { staticClass: "modal-pro-player-wrapper" }, [
        _c("div", { staticClass: "rin-pro-player-modal-body-container" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "rin-pro-player-modal-titles-container" },
              [
                _c("div", { staticClass: "rin-pro-player-modal-supertitle" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.translations["monetization_Player_ModalSupertitle"]
                      ) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "rin-pro-player-modal-title" },
                  [
                    _vm._v("\n                        Rankedin "),
                    _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.translations["monetization_Player_ModalTitle"]
                        ) +
                        "\n                    "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "rin-pro-player-modal-subtitle" }, [
                  _vm._v(
                    _vm._s(
                      _vm.translations["monetization_Player_ModalSubtitle"]
                    )
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "rin-pro-player-modal-bulletpoints" }, [
              _c(
                "li",
                [
                  _c("BaseIcon", { attrs: { name: "check" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.translations[
                          "monetization_Player_ModalBulletpoint1"
                        ]
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("BaseIcon", { attrs: { name: "check" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.translations[
                          "monetization_Player_ModalBulletpoint2"
                        ]
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("BaseIcon", { attrs: { name: "check" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.translations[
                          "monetization_Player_ModalBulletpoint3"
                        ]
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("BaseIcon", { attrs: { name: "check" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.translations[
                          "monetization_Player_ModalBulletpoint4"
                        ]
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("BaseIcon", { attrs: { name: "check" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.translations["monetization_Real_Time_Indicator"]
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("BaseIcon", { attrs: { name: "check" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.translations[
                          "monetization_Player_ModalBulletpoint6"
                        ]
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("BaseIcon", { attrs: { name: "check" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.translations[
                          "monetization_Player_ModalBulletpoint7"
                        ]
                      )
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          !_vm.$windowSize.isMobile ? _c("div", [_vm._m(0)]) : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "choose-plan-wrapper" }, [
          _c("div", { staticClass: "rin-pro-player-modal-description" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.translations["monetization_Player_ModalDescription"]
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rin-pro-player-modal-plans-container" },
            _vm._l(_vm.plans, function (plan) {
              return _c("BaseCard", {
                key: plan.priceId,
                class: {
                  "rin-popular-plan-container":
                    plan.type ===
                    _vm.nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly,
                },
                attrs: { type: "primary", size: "s", shadow: false },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          plan.type ===
                          _vm.nomenclatures.PlayerSubscriptionPeriodTypesEnum
                            .Yearly
                            ? _c("BaseTypeLabel", {
                                attrs: { type: "popular" },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    plan.type ===
                    _vm.nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly
                      ? {
                          key: "header",
                          fn: function () {
                            return [_c("span", [_vm._v("-55%")])]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "rin-pro-player-modal-plan-period" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.translations[
                                      `monetization_Player_ModalPlanPeriod${
                                        _vm.nomenclatures
                                          .PlayerSubscriptionPeriodTypesEnum[
                                          plan.type
                                        ]
                                      }`
                                    ]
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "rin-pro-player-modal-plan-cost" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.parsePrice(plan.price)) +
                                  " " +
                                  _vm._s(_vm.currencySymbol(plan.currency)) +
                                  "\n                            "
                              ),
                              _c("span", [
                                _vm._v(
                                  "\n                                /\n                                " +
                                    _vm._s(
                                      _vm.translations[
                                        `monetization_Player_ModalPlanPer${
                                          _vm.nomenclatures
                                            .PlayerSubscriptionPeriods[
                                            _vm.nomenclatures
                                              .PlayerSubscriptionPeriodTypesEnum[
                                              plan.type
                                            ]
                                          ]
                                        }`
                                      ]
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "rin-pro-player-modal-plan-breakdown",
                            },
                            [
                              plan.type ===
                              _vm.nomenclatures
                                .PlayerSubscriptionPeriodTypesEnum.Yearly
                                ? [
                                    _vm._v(
                                      "\n                                12 " +
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalPlanMonths"
                                          ]
                                        ) +
                                        " " +
                                        _vm._s(_vm.annualPlanBreakdown) +
                                        " " +
                                        _vm._s(
                                          _vm.currencySymbol(plan.currency)
                                        ) +
                                        " /\n                                " +
                                        _vm._s(
                                          _vm.translations[
                                            `monetization_Player_ModalPlanPer${_vm.nomenclatures.PlayerSubscriptionPeriods.Monthly}`
                                          ]
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "cancel-any-time" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.translations[
                                    "monetization_Player_ModalPlanDescription"
                                  ]
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "BaseButton",
                            {
                              attrs: { type: "warn", size: "m" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.actionButton(
                                    plan.priceId,
                                    plan.type
                                  )
                                },
                              },
                            },
                            [
                              _vm.layout.player.isAuthenticated
                                ? [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalPlanBuyButton"
                                          ]
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.translations["shared_SeeMore"]
                                        ) +
                                        "\n                            "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ]),
      ])
    : _c("BaseSpinner")
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rin-pro-player-modal-image-container" }, [
      _c("img", {
        attrs: {
          src:
            "https://cdn.rankedin.com/images/static/players_pro_plan_modal_image.svg",
          alt: "image player",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }