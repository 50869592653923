var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-navmenu-action" },
    [
      !_vm.link
        ? _c(
            "router-link",
            {
              attrs: {
                to: {
                  name: _vm.destinationName,
                  params: _vm.params,
                  hash: _vm.hash,
                },
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeMenu.apply(null, arguments)
                },
              },
            },
            [
              _c("BaseIcon", { attrs: { name: _vm.icon } }),
              _vm._v(" "),
              _c("span", { staticClass: "rin-action-title" }, [
                _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "rin-action-description" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.description) + "\n        "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.link
        ? _c(
            "router-link",
            { attrs: { to: _vm.link } },
            [
              _c("BaseIcon", { attrs: { name: _vm.icon } }),
              _vm._v(" "),
              _c("span", { staticClass: "rin-action-title" }, [
                _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "rin-action-description" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.description) + "\n        "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }