var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-profile-matches" },
    [
      !_vm.initialContentLoaded
        ? _c(
            "div",
            { staticClass: "d-flex jcc" },
            [_c("BaseSpinner", { attrs: { size: "m" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isPlaceholderShown && _vm.initialContentLoaded
        ? _c("div", { staticStyle: { "margin-bottom": "1rem" } }, [
            _c(
              "div",
              {
                staticClass: "rin-collapse-toggle",
                on: {
                  click: function ($event) {
                    _vm.upcoming.isOpen = !_vm.upcoming.isOpen
                    _vm.loadUpcomingMatches(0, true)
                  },
                },
              },
              [
                _c("span", { staticClass: "tab-title" }, [
                  _vm._v(_vm._s(_vm.translations["shared_Events_Upcoming"])),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "icon icon-v",
                  class: { reversed: _vm.upcoming.isOpen },
                }),
              ]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.upcoming.isOpen
              ? _c("div", [
                  (_vm.upcoming.invitations.length > 0 ||
                    _vm.upcoming.matches.length > 0) &&
                  !(
                    _vm.upcoming.matchesLoading ||
                    _vm.upcoming.invitationsLoading
                  )
                    ? _c(
                        "div",
                        { staticClass: "matches-container" },
                        [
                          _vm._l(
                            _vm.upcoming.invitations,
                            function (invitation) {
                              return _c("InvitationCard", {
                                key: invitation.Id,
                                attrs: { invitation: invitation },
                                on: { reload: _vm.reloadInvitations },
                              })
                            }
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.upcoming.matches, function (match) {
                            return _c("MatchesCard", {
                              key: match.MatchId,
                              attrs: {
                                match: match,
                                showActionButtons: _vm.isOwnProfile,
                              },
                            })
                          }),
                        ],
                        2
                      )
                    : _c(
                        "div",
                        [
                          !_vm.isLoading
                            ? _c(
                                "BaseAlert",
                                {
                                  staticClass: "matches-alert",
                                  attrs: { type: "info" },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.translations[
                                          "playerProfile_ListEmpty"
                                        ]
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _vm.upcoming.hasMore
                    ? _c(
                        "div",
                        { staticClass: "matches-showmore" },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                disabled:
                                  _vm.upcoming.matchesLoading ||
                                  _vm.history.loadMore,
                                type: "tertiary",
                                size: "s",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.loadUpcomingMatches(
                                    _vm.upcoming.matches.length
                                  )
                                },
                              },
                            },
                            [
                              _vm.isLoadingMoreUpcoming
                                ? _c("BaseSpinner", { attrs: { size: "s" } })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isLoadingMoreUpcoming
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.translations["shared_ShowMore"]
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("AdBox1"),
      _vm._v(" "),
      !_vm.isPlaceholderShown && _vm.initialContentLoaded
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "rin-collapse-toggle",
                on: {
                  click: function ($event) {
                    _vm.history.isOpen = !_vm.history.isOpen
                    _vm.loadFinishedMatches(0, true)
                  },
                },
              },
              [
                _c("span", { staticClass: "tab-title" }, [
                  _vm._v(_vm._s(_vm.translations["matchesPartial_History"])),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "icon icon-v",
                  class: { reversed: _vm.history.isOpen },
                }),
              ]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.history.isOpen
              ? _c(
                  "div",
                  { staticClass: "finished-wrapper" },
                  [
                    _vm.history.matches.length > 0 && !_vm.history.isLoading
                      ? _c(
                          "div",
                          {
                            staticClass: "matches-container",
                            class: {
                              flash: _vm.isMatchProcessing,
                              blurred: !this.isProPlayer,
                            },
                          },
                          _vm._l(_vm.history.matches, function (match) {
                            return _c("MatchesCard", {
                              key: match.MatchId,
                              attrs: {
                                match: match,
                                showActionButtons: _vm.isOwnProfile,
                              },
                            })
                          }),
                          1
                        )
                      : _c(
                          "div",
                          [
                            !_vm.isLoading
                              ? _c(
                                  "BaseAlert",
                                  {
                                    staticClass: "matches-alert",
                                    attrs: { type: "info" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.translations[
                                            "playerProfile_ListEmpty"
                                          ]
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    !_vm.isProPlayer && !_vm.history.isLoading
                      ? _c("UpgradeToProButton", {
                          on: { toggleSubscriptionModal: _vm.openModal },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "bottom",
                                fn: function () {
                                  return [
                                    _c("p", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.translations[
                                              "monetization_Reason_Matches"
                                            ]
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            515992756
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.history.isOpen
              ? _c("div", [
                  _vm.history.hasMore && this.isProPlayer
                    ? _c("div", { staticClass: "matches-showmore" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: {
                                  disabled:
                                    _vm.history.isLoading ||
                                    _vm.history.loadMore,
                                  type: "tertiary",
                                  size: "s",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.loadFinishedMatches(
                                      _vm.history.matches.length
                                    )
                                  },
                                },
                              },
                              [
                                _vm.history.loadMore
                                  ? _c("BaseSpinner", { attrs: { size: "s" } })
                                  : _vm._e(),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.translations["shared_ShowMore"]
                                    ) +
                                    "\n                    "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isPlaceholderShown && _vm.initialContentLoaded
        ? _c("MatchesPlaceholder")
        : _vm._e(),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        attrs: { size: "l", show: _vm.showModal },
        on: {
          close: function ($event) {
            _vm.showModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [_c("ModalProPlayers")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }