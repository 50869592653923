var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-register-gender rin-register-form" }, [
    _c("h1", [_vm._v(_vm._s(_vm.translations["register_GenderHeading"]))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rin-register-form-container" },
      [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userGender,
                expression: "userGender",
              },
            ],
            staticClass: "rin-register-input",
            attrs: { id: "sexm", type: "radio", value: "1" },
            domProps: { checked: _vm._q(_vm.userGender, "1") },
            on: {
              change: function ($event) {
                _vm.userGender = "1"
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "rin-register-label", attrs: { for: "sexm" } },
            [_vm._v(_vm._s(_vm.translations["register_GenderMale"]))]
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userGender,
                expression: "userGender",
              },
            ],
            staticClass: "rin-register-input",
            attrs: { id: "sexf", type: "radio", value: "2" },
            domProps: { checked: _vm._q(_vm.userGender, "2") },
            on: {
              change: function ($event) {
                _vm.userGender = "2"
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "rin-register-label", attrs: { for: "sexf" } },
            [_vm._v(_vm._s(_vm.translations["register_GenderFemale"]))]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c(
              "BaseAlert",
              { attrs: { type: "danger", "has-background": false } },
              [
                _vm.$v.userGender.$dirty && !_vm.$v.userGender.required
                  ? _c("span", { staticClass: "rin-register-error" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.translations["register_2"]) +
                          "\n\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "BaseButton",
          {
            attrs: { type: "success", size: "m", disabled: _vm.isNextDisabled },
            on: { click: _vm.goToNext },
          },
          [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.translations["shared_Next"]) + "\n\t\t"
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }