var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "logo-bar" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "logo-text",
        staticStyle: {
          "line-height": "20px",
          "font-size": "10px",
          color: "#fff",
          "white-space": "nowrap",
        },
      },
      [
        _vm._v(
          "\n\t\t\t" +
            _vm._s(_vm.translations["logoTopBar_SportCam_Header"]) +
            "\n\t\t"
        ),
      ]
    ),
    _vm._v(" "),
    _c("a", { attrs: { href: "https://sportcam.app/", target: "_blank" } }, [
      _c(
        "span",
        {
          staticClass: "logo-text",
          staticStyle: {
            "line-height": "20px",
            "font-size": "12px",
            "text-decoration": "underline",
            "font-weight": "bold",
          },
        },
        [
          _vm._v(
            "\n\t\t\t\t" + _vm._s(_vm.translations["logoTopBar_SportCam_Link"])
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticStyle: { border: "none" },
        attrs: { href: "https://sportcam.app/", target: "_blank" },
      },
      [
        _c("img", {
          staticClass: "logo-img",
          staticStyle: {
            "max-height": "25px",
            "padding-right": "6px",
            height: "18px",
            "margin-top": "-4px",
          },
          attrs: {
            src: "https://cdn.rankedin.com/images/static/sportcam_logo.svg",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }