var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalWrapper", {
    attrs: { show: _vm.showModal },
    on: {
      close: function ($event) {
        _vm.showModal = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "rin-free-trial-end-content" }, [
              _c(
                "h4",
                { staticClass: "rin-free-trial-end-title" },
                [
                  _vm._v("\n\t\t\t\tRankedin "),
                  _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.translations["monetization_Trial_Plan"]) +
                      "\n\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h1", [
                _vm._v(
                  _vm._s(_vm.translations["monetization_TrialEnd_Title1"])
                ),
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "underline" }, [
                _vm._v(
                  _vm._s(_vm.translations["monetization_TrialEnd_Title2"])
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rin-free-trial-end-body" },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.translations["monetization_TrialEnd_BodyText1"]
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("UpgradeToProButton", {
                    attrs: { "is-overlayed": false },
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.translations["monetization_TrialEnd_BodyText2"]
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "h4",
                    {
                      staticClass: "underline",
                      on: {
                        click: function ($event) {
                          _vm.showModal = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.translations["monetization_TrialEnd_ChooseFree"]
                          ) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.translations["monetization_TrialEnd_Info"]) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }