var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "rin-main-body-modal-container",
      class: { "rin-body-modal-ads-offset": _vm.isAdVisible },
    },
    [
      _c(
        "div",
        [
          _vm._t("close", function () {
            return [
              _c("img", {
                staticClass: "rin-main-body-modal-close-btn",
                attrs: {
                  src:
                    "https://cdn.rankedin.com/images/static/close-icon-big.svg",
                  alt: "close icon",
                },
                on: { click: _vm.removeLastBodyModal },
              }),
            ]
          }),
          _vm._v(" "),
          _vm._t("content", function () {
            return [_vm._v(" example contetnt ")]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }