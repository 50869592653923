var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: { id: "spinner-modal", "data-backdrop": "static" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content background-muted" }, [
          _c(
            "div",
            {
              staticClass: "modal-body",
              staticStyle: { "text-align": "center" },
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  { staticClass: "spinner-border", attrs: { role: "status" } },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm.translations["loading"]) + "..."),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }