import axios from 'axios';
import debounce from 'lodash/debounce';

const postStateToServer = debounce((payload, apiDomain, commit) => {
    
    const { areControlsDisabled, ...data } = payload;
    commit('updatAreControlsDisabled', true);
    axios.post(`${apiDomain}/tournament/SaveTeamChampionshipRoundRobinSettingsAsync`, data)
        .then(response => {

            axios.post(`${apiDomain}/tournament/updatetournamentstandingsasync`, { TournamentId: payload.tournamentId, IsTeamSettingsChange: true })
                .then(res => {
                    commit('updatAreControlsDisabled', false);
                })
                .catch(err => {
                    alert('There was error when saving the settings. Please try again or contact us on our support chat');
                    console.error(error);
                });
        })
        .catch(error => {
            alert('There was error when saving the settings. Please try again or contact us on our support chat');
            console.error(error);
        });
}, 1200);

export default {
    namespaced: true,
    state: {
        individualPointSettings: null,
        individualRuleSettings: null,
        teamPointSettings: null,
        teamRuleSettings: null,
        tournamentId: null,
        teamMatchesScoringType: 0,
        areControlsDisabled: false
    },
    mutations: {

        updateState(state, payload) {
            state.individualPointSettings = payload.individualPointSettings;
            state.individualRuleSettings = payload.individualRuleSettings;
            state.teamMatchesScoringType = payload.teamMatchesScoringType;
            state.teamPointSettings = payload.teamPointSettings;
            state.teamRuleSettings = payload.teamRuleSettings;

            state.tournamentId = payload.tournamentId;
        },

        updateIndividualPointSettings(state, payload) {
            state.individualPointSettings.winPoints = payload.winPoints;
            state.individualPointSettings.losePoints = payload.losePoints;

            state.teamMatchesScoringType = payload.teamMatchesScoringType;
        },
        updateTeamPointSettings(state, payload) {
            state.teamPointSettings.winPoints = payload.winPoints;
            state.teamPointSettings.losePoints = payload.losePoints;
        },
        updateTeamRuleSettings(state, payload) {
            state.teamRuleSettings = payload;
        },
        updateIndividualRuleSettings(state, payload) {
            state.individualRuleSettings = payload;
        },
        updatAreControlsDisabled(state, payload) {
            state.areControlsDisabled = payload;
        }
    },
    actions: {

        loadData({ commit, rootState }, payload) {

            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            axios.get(`${apiDomain}/tournament/getteamchampionshiproundrobinsettingsasync?tournamentid=${payload.tournamentId}`)
                .then(res => {
                    commit('updateState', { ...res.data, tournamentId: payload.tournamentId });
                })
                .catch(e => {
                    alert('There was error when saving the settings. Please try again or contact us on our support chat');
                });
        },

        updateIndividualPointSettings({ commit, state, rootState }, payload) {
            commit('updateIndividualPointSettings', payload);

            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            postStateToServer(state, apiDomain, commit);
        },
        updateTeamPointSettings({ commit, state, rootState }, payload) {
            commit('updateTeamPointSettings', payload);

            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            postStateToServer(state, apiDomain, commit);
        },
        updateTeamRuleSettings({ commit, state, rootState }, payload) {
            commit('updateTeamRuleSettings', payload);

            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            postStateToServer(state, apiDomain, commit);
        },
        updateIndividualRuleSettings({ commit, state, rootState }, payload) {
            commit('updateIndividualRuleSettings', payload);

            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            postStateToServer(state, apiDomain, commit);
        }
    },
    getters: {
        individualPointSettings(state) {
            return state.individualPointSettings;
        },
        individualRuleSettings(state) {
            return state.individualRuleSettings;
        },
        teamMatchesScoringType(state) {
            return state.teamMatchesScoringType;
        },
        teamPointSettings(state) {
            return state.teamPointSettings;
        },
        teamRuleSettings(state) {
            return state.teamRuleSettings;
        },
        areControlsDisabled(state) {
            return state.areControlsDisabled;
        }
    }
};


