var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.selectedPremiumTournamentPlan
    ? _c("div", { staticClass: "rin-premium-tournament-container" }, [
        _c(
          "div",
          { staticClass: "rin-premium-tournament-header" },
          [
            _c("BaseIcon", { attrs: { name: "star" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "rin-premium-tournament-supertitle" },
              [
                !_vm.paidPlan
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.translations[
                              "monetization_Tournament_Supertitle1"
                            ]
                          ) +
                          "\n            "
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.translations["monetization_Tournament_Supertitle2"]
                      ) +
                      "\n            "
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            !_vm.paidPlan
              ? _c(
                  "div",
                  { staticClass: "rin-premium-tournament-title" },
                  [
                    _c("BaseTypeLabel", { attrs: { type: "premium" } }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.translations["shared_EventType_Tournament"]
                        ) +
                        "\n        "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.paidPlan
              ? _c("div", { staticClass: "rin-premium-tournament-subrtitle" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.translations["monetization_Tournament_Subtitle"]
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rin-premium-tournament-body" },
          [
            !_vm.paidPlan
              ? _c(
                  "ul",
                  { staticClass: "rin-premium-tournament-bulletpoints" },
                  [
                    _c(
                      "li",
                      [
                        _c("BaseIcon", { attrs: { name: "check" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.translations[
                                "monetization_Tournament_Bulletpoint1"
                              ]
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("BaseIcon", { attrs: { name: "check" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.translations[
                                "monetization_Tournament_Bulletpoint2"
                              ]
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("BaseIcon", { attrs: { name: "check" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.translations[
                                "monetization_Tournament_Bulletpoint4"
                              ]
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("BaseIcon", { attrs: { name: "check" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.translations[
                                "monetization_Tournament_Bulletpoint6"
                              ]
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("BaseIcon", { attrs: { name: "check" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.translations[
                                "monetization_Tournament_Bulletpoint7"
                              ]
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _c("BaseCard", {
                  staticClass: "rin-premium-tournament-current-plan",
                  attrs: { type: "primary", size: "s", shadow: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("BaseTypeLabel", {
                              attrs: { type: "current_plan" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c("h6", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.translations[
                                      "monetization_Tournament_UpTo"
                                    ]
                                  ) +
                                  " " +
                                  _vm._s(_vm.paidPlan.endTierUnits) +
                                  "\n                    " +
                                  _vm._s(
                                    _vm.translations[
                                      "monetization_Tournament_Players"
                                    ]
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.translations[
                                      "monetization_Tournament_YouPaid"
                                    ]
                                  ) +
                                  " " +
                                  _vm._s(_vm.parsePrice(_vm.paidPlan.price)) +
                                  "\n                    " +
                                  _vm._s(
                                    _vm.nomenclatures.Currencies[
                                      _vm.paidPlan.currency
                                    ].name
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    457619719
                  ),
                }),
            _vm._v(" "),
            _vm.showPlans
              ? _c(
                  "div",
                  { staticClass: "rin-premium-tournament-player-count" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.translations[
                            "monetization_Tournament_NumberOfEntries"
                          ]
                        ) +
                        " " +
                        _vm._s(_vm.playerCount) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showPlans
              ? _c(
                  "div",
                  { staticClass: "rin-premium-tournament-description" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.translations[
                            "monetization_Player_ModalDescription"
                          ]
                        )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showPlans
              ? _c(
                  "div",
                  { staticClass: "rin-premium-tournament-plans-container" },
                  _vm._l(_vm.plans, function (plan, idx) {
                    return _c("BaseCard", {
                      key: plan.price,
                      class: { "not-eligible": !plan.isEligible },
                      attrs: { type: "primary", size: "s", shadow: false },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "body",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "rin-premium-tournament-modal-plan-limits",
                                  },
                                  [
                                    plan.endTierUnits
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.translations[
                                                  "monetization_Tournament_UpTo"
                                                ]
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(plan.endTierUnits) +
                                              "\n                            " +
                                              _vm._s(
                                                _vm.translations[
                                                  "monetization_Tournament_Players"
                                                ]
                                              ) +
                                              "\n                        "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.translations[
                                                  "monetization_Tournament_Unlimited"
                                                ]
                                              ) +
                                              "\n                        "
                                          ),
                                        ]),
                                  ]
                                ),
                                _vm._v(" "),
                                !(
                                  _vm.paidPlan &&
                                  _vm.paidPlan.endTierUnits == plan.endTierUnits
                                )
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "rin-premium-tournament-modal-plan-cost",
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.parsePrice(plan.price)) +
                                            " " +
                                            _vm._s(
                                              _vm.nomenclatures.Currencies[
                                                plan.currency
                                              ].name
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      type: "premium",
                                      size: "s",
                                      disabled: !plan.isEligible,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.actionButton(idx)
                                      },
                                    },
                                  },
                                  [
                                    _vm.layout.player.isAuthenticated
                                      ? [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.translations[
                                                  "monetization_Player_ModalPlanBuyButton"
                                                ]
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.translations[
                                                  "shared_SeeMore"
                                                ]
                                              ) +
                                              "\n                        "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showPlans
              ? _c(
                  "div",
                  { staticClass: "rin-premium-tournament-additional-info" },
                  [
                    _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.translations[
                              "monetization_Tournament_AdditionalInfo1"
                            ]
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.translations[
                              "monetization_Tournament_AdditionalInfo2"
                            ]
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.translations[
                              "monetization_Tournament_AdditionalInfo3"
                            ]
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.translations[
                              "monetization_Tournament_AdditionalInfo4"
                            ]
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rin-premium-tournament-footer" },
          [
            !_vm.showPlans
              ? _c("BaseAlert", { attrs: { type: "info" } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.translations["monetization_Tournament_Footer"]
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ])
    : _c("div", [_c("ChooseBillingAddress")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }