var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRegisterModalOpen
    ? _c(
        "portal",
        { attrs: { to: "main-base-modal" } },
        [
          _c("BaseModalWrapper", {
            staticClass: "rin-register-modal-wrapper",
            attrs: { size: "fullv-s", show: _vm.isRegisterModalOpen },
            on: { close: _vm.closeRegisterModal },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "vueper-slides",
                        {
                          attrs: {
                            touchable: false,
                            bullets: false,
                            progress: true,
                            disableArrowsOnEdges: true,
                            infinite: false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "arrow-left",
                                fn: function () {
                                  return [
                                    _c("BaseIcon", { attrs: { name: "back" } }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "arrow-right",
                                fn: function () {
                                  return [
                                    _c("span", {
                                      ref: "arrowRight",
                                      staticStyle: { display: "none" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4205998925
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm._v(" "),
                          _vm._l(7, function (i) {
                            return _c("vueper-slide", {
                              key: i,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        i == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rin-register-slide",
                                              },
                                              [
                                                _c("RegisterEmailPassword", {
                                                  attrs: {
                                                    "next-ref":
                                                      _vm.$refs.arrowRight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        i == 2
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rin-register-slide",
                                              },
                                              [
                                                _c("RegisterName", {
                                                  attrs: {
                                                    "next-ref":
                                                      _vm.$refs.arrowRight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        i == 3
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rin-register-slide",
                                              },
                                              [
                                                _c("RegisterDateOfBirth", {
                                                  attrs: {
                                                    "next-ref":
                                                      _vm.$refs.arrowRight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        i == 4
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rin-register-slide",
                                              },
                                              [
                                                _c("RegisterGender", {
                                                  attrs: {
                                                    "next-ref":
                                                      _vm.$refs.arrowRight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        i == 5
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rin-register-slide",
                                              },
                                              [
                                                _c("RegisterCountry", {
                                                  attrs: {
                                                    "next-ref":
                                                      _vm.$refs.arrowRight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        i == 6
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rin-register-slide",
                                              },
                                              [
                                                _c("RegisterSport", {
                                                  attrs: {
                                                    "next-ref":
                                                      _vm.$refs.arrowRight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        i == 7
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "rin-register-slide",
                                              },
                                              [
                                                _c("RegisterPhone", {
                                                  attrs: {
                                                    "has-error":
                                                      _vm.$v.phone.$dirty &&
                                                      (!_vm.$v.phone.required ||
                                                        !_vm.$v.phone
                                                          .validatePhone),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                !_vm.isLoading
                                                  ? _c(
                                                      "BaseButton",
                                                      {
                                                        attrs: {
                                                          type: "success",
                                                          size: "m",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.registerAccount.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              _vm.translations[
                                                                "register_CreateAccount"
                                                              ]
                                                            ) +
                                                            "\n                            "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isLoading
                                                  ? _c("BaseSpinner")
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1669276282
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }