var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasBillingAddress
      ? _c("div", { staticClass: "choose-billing-address" }, [
          _c("span", [
            _c("b", [
              _vm._v(
                " " +
                  _vm._s(_vm.translations["monetization_ChooseBillingAddress"])
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-2 d-flex justify-content-center" },
            [
              _c("BaseCard", {
                staticClass: "address-card",
                attrs: { type: "primary", shadow: false },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "m-3" }, [
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v(_vm._s(_vm.billingAddressModel.name)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v(_vm._s(_vm.billingAddressModel.address)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v(_vm._s(_vm.billingAddressModel.address2)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v(
                                _vm._s(_vm.billingAddressModel.postalCode) +
                                  " " +
                                  _vm._s(_vm.billingAddressModel.city)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "m-0" }, [
                              _vm._v(_vm._s(_vm.billingAddressModel.country)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center mt-4",
                              },
                              [
                                _c(
                                  "BaseButton",
                                  {
                                    attrs: { type: "success", size: "m" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.continueToCheckout(false)
                                      },
                                    },
                                  },
                                  [
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.translations[
                                              "monetization_ChooseBillingAddress_Useit"
                                            ]
                                          ) +
                                          "\n                                "
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4250458260
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", [
            _c("b", [
              _vm._v(
                "     " +
                  _vm._s(
                    _vm.translations["monetization_ChooseBillingAddress_Or"]
                  )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "m-2 d-flex justify-content-center" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { type: "primary", size: "m" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clearBillingAddressAndCheckout.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.translations[
                            "monetization_ChooseBillingAddress_Createnew"
                          ]
                        ) +
                        "\n                "
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }