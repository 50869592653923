var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-register-usernames rin-register-form" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.translations["register_NamesHeading"]))]),
      _vm._v(" "),
      _c("div", { staticClass: "rin-register-input-container" }, [
        _c(
          "label",
          { staticClass: "rin-register-label", attrs: { for: "firstname" } },
          [_vm._v(_vm._s(_vm.translations["register_FirstName"]))]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.userFirstName,
              expression: "userFirstName",
              modifiers: { trim: true },
            },
          ],
          staticClass: "rin-register-input",
          class: {
            "has-error":
              _vm.$v.userFirstName.$dirty &&
              (!_vm.$v.userFirstName.required ||
                !_vm.$v.userFirstName.minLength ||
                !_vm.$v.userFirstName.maxLength),
          },
          attrs: {
            id: "firstname",
            type: "text",
            inputmode: "search",
            autocomplete: "off",
            placeholder: _vm.translations["register_FirstName"],
          },
          domProps: { value: _vm.userFirstName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.userFirstName = $event.target.value.trim()
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c(
            "BaseAlert",
            { attrs: { type: "danger", "has-background": false } },
            [
              _vm.$v.userFirstName.$dirty && !_vm.$v.userFirstName.required
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.translations["register_2"]) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$v.userFirstName.$dirty && !_vm.$v.userFirstName.minLength
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.interpolateString(
                            _vm.translations["register_FieldMinLength"],
                            [2]
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$v.userFirstName.$dirty && !_vm.$v.userFirstName.maxLength
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.interpolateString(
                            _vm.translations["register_FieldMaxLength"],
                            [150]
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "rin-register-input-container" }, [
        _c(
          "label",
          { staticClass: "rin-register-label", attrs: { for: "lastname" } },
          [_vm._v(_vm._s(_vm.translations["register_LastName"]))]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.userLastName,
              expression: "userLastName",
              modifiers: { trim: true },
            },
          ],
          staticClass: "rin-register-input",
          class: {
            "has-error":
              _vm.$v.userLastName.$dirty &&
              (!_vm.$v.userLastName.required ||
                !_vm.$v.userLastName.minLength ||
                !_vm.$v.userLastName.maxLength),
          },
          attrs: {
            id: "lastname",
            type: "text",
            inputmode: "search",
            placeholder: _vm.translations["register_LastName"],
          },
          domProps: { value: _vm.userLastName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.userLastName = $event.target.value.trim()
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c(
            "BaseAlert",
            { attrs: { type: "danger", "has-background": false } },
            [
              _vm.$v.userLastName.$dirty && !_vm.$v.userLastName.required
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.translations["register_2"]) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$v.userLastName.$dirty && !_vm.$v.userLastName.minLength
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.interpolateString(
                            _vm.translations["register_FieldMinLength"],
                            [2]
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$v.userLastName.$dirty && !_vm.$v.userLastName.maxLength
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.interpolateString(
                            _vm.translations["register_FieldMaxLength"],
                            [150]
                          )
                        ) +
                        "\n\t\t\t"
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "BaseButton",
        {
          attrs: { type: "success", size: "m", disabled: _vm.isNextDisabled },
          on: { click: _vm.goToNext },
        },
        [_vm._v(" " + _vm._s(_vm.translations["shared_Next"]) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }