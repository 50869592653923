var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.tournaments.length > 0 &&
      (_vm.filterSelected == "tournaments" || _vm.filterSelected == "all")
        ? _c("SearchResultGroup", {
            attrs: {
              events: _vm.tournaments,
              title: "Tournaments",
              type: "Tournaments",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "description",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        "\n            Start date: " +
                          _vm._s(item.StartDate) +
                          "\n            "
                      ),
                      _c("BaseIcon", { attrs: { name: item.Sport } }),
                    ]
                  },
                },
              ],
              null,
              false,
              987536605
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.clubleagues.length > 0 &&
      (_vm.filterSelected == "clubleagues" || _vm.filterSelected == "all")
        ? _c("SearchResultGroup", {
            attrs: {
              events: _vm.clubleagues,
              title: "Club Leagues",
              type: "Clubleagues",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "description",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        "\n            Start date: " +
                          _vm._s(item.StartDate) +
                          "\n            "
                      ),
                      _c("BaseIcon", { attrs: { name: item.Sport } }),
                    ]
                  },
                },
              ],
              null,
              false,
              987536605
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.teamleagues.length > 0 &&
      (_vm.filterSelected == "teamleagues" || _vm.filterSelected == "all")
        ? _c("SearchResultGroup", {
            attrs: {
              events: _vm.teamleagues,
              title: "Team Leagues",
              type: "Teamleagues",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "description",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        "\n            Start date: " +
                          _vm._s(item.StartDate) +
                          "\n            "
                      ),
                      _c("BaseIcon", { attrs: { name: item.Sport } }),
                    ]
                  },
                },
              ],
              null,
              false,
              987536605
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.players.length > 0 &&
      (_vm.filterSelected == "players" || _vm.filterSelected == "all")
        ? _c("SearchResultGroup", {
            attrs: {
              events: _vm.players,
              title: "Players",
              type: "Participants",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "description",
                  fn: function ({ item }) {
                    return [
                      _c("div", [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.PlayerInfo) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v("ID: " + _vm._s(item.RankedinId))]),
                    ]
                  },
                },
              ],
              null,
              false,
              453464280
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.rankings.length > 0 &&
      (_vm.filterSelected == "rankings" || _vm.filterSelected == "all")
        ? _c("SearchResultGroup", {
            attrs: {
              events: _vm.rankings,
              title: "Rankings",
              type: "Rankings",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "description",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        "\n            " +
                          _vm._s(item.OrganisationName) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              913018311
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.organisations.length > 0 &&
      (_vm.filterSelected == "organisations" || _vm.filterSelected == "all")
        ? _c("SearchResultGroup", {
            attrs: {
              events: _vm.organisations,
              title: "Organisations",
              type: "Organisations",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "description",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        "\n            " + _vm._s(item.Address) + "\n        "
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2196472420
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }