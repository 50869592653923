var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: { id: "americano-campaign-modal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
            },
            [_vm._v("\n                ×\n            ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "americano-body" }, [
              _c("img", {
                attrs: {
                  src: "https://cdn.rankedin.com/images/americano-iphone.png",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "americano-text-wrapper" }, [
                _c("div", { staticClass: "americano-best" }, [
                  _vm._v(
                    "\n                            Best\n                        "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "americano-referal" }, [
                  _vm._v(
                    "\n                            - Danish padel federation\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btn-wrapper" },
                  [
                    _vm.isLoggedIn
                      ? _c(
                          "router-link",
                          {
                            staticClass: "btn btn-success play-americano-btn",
                            attrs: { to: _vm.createUrl },
                          },
                          [_vm._v("Play americano")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLoggedIn
                      ? _c(
                          "router-link",
                          {
                            staticClass: "btn btn-success play-americano-btn",
                            attrs: { to: _vm.createUrl },
                          },
                          [_vm._v("Play americano")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "americano-hero" }, [_vm._v("Americano")]),
      _c("span", { staticClass: "americano-app" }, [_vm._v("App")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }