var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "freemium-no-worries" }, [
    _c("h4", { staticClass: "title" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.translations["monetization_Freemium_NoWorries"]) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "first-card" },
      [
        _c("BaseCard", {
          attrs: { type: "secondary", border: true },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.translations["monetization_Freemium_Basic"]) +
                      "\n            "
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("ul", { staticClass: "list-wrapper" }, [
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations[
                              "monetization_Freemium_PersonalProfile"
                            ]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.translations["monetization_Freemium_Join"])
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_Create"]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.translations["monetization_Freemium_Play"])
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations[
                              "monetization_Freemium_TournamentList"
                            ]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_Calendar"]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.translations["monetization_Freemium_Chat"])
                        ),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "second-card" },
      [
        _c("BaseCard", {
          attrs: { type: "secondary", border: true },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v("\n                Rankedin "),
                  _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.translations["monetization_Freemium_PlayerPlan"]
                      ) +
                      "\n            "
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("ul", { staticClass: "list-wrapper" }, [
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_Unlock"]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_KeepTrack"]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_Follow"]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_Upcoming"]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations[
                              "monetization_Freemium_SkillRating"
                            ]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations[
                              "monetization_Freemium_LastVisited"
                            ]
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "freemium-item" }, [
                      _c("div", [
                        _c(
                          "span",
                          [_c("BaseIcon", { attrs: { name: "check" } })],
                          1
                        ),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_NoAds"]
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }