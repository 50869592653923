var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-register-dob-container rin-register-form" },
    [
      _c("h1", [
        _vm._v(_vm._s(_vm.translations["register_DateOfBirthHeading"])),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rin-register-input-container" },
        [
          _c("label", { staticClass: "rin-register-label" }, [
            _vm._v(_vm._s(_vm.translations["register_DateOfBirth"])),
          ]),
          _vm._v(" "),
          _c("dropdown-datepicker", {
            attrs: {
              "default-date": _vm.defaultDate,
              "default-date-format": _vm.format,
              "max-date": _vm.maxDate,
              "min-date": _vm.minDate,
              required: true,
              "day-label": null,
              "month-label": null,
              "year-label": null,
              "day-suffix-values": ["", "", "", ""],
            },
            on: { input: _vm.updateDob },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "BaseButton",
        {
          attrs: { type: "success", size: "m", disabled: _vm.isNextDisabled },
          on: { click: _vm.goToNext },
        },
        [_vm._v(" " + _vm._s(_vm.translations["shared_Next"]) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }