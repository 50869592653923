var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("hr")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-hr" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Croatian")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("dk")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-dk" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Danish")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("nl")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-nl" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Dutch")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("en")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-gb" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" English")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("fr")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-fr" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" French")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("de")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-de" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" German")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("it")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-it" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Italian")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("pl")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-pl" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Polish")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("pt")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-pt" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Portuguese")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("ru")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-ru" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Russian")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("si")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-si" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Slovenian")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("es")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-es" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Spanish")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("se")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-se" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Swedish")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage("sr")
            },
          },
        },
        [
          _c("i", { staticClass: "flag-icon flag-icon-rs" }),
          _c("span", { staticClass: "langName" }, [_vm._v(" Serbian")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }