var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-profile-image" }, [
    _vm.hasPlayerImage
      ? _c("img", {
          attrs: { src: _vm.playerImage, alt: "logo", height: "25" },
        })
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasPlayerImage
      ? _c("div", { staticClass: "img-placeholder" }, [
          _vm._v(_vm._s(_vm.playerInitials)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }