var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "rin-base-checkbox",
      class: { disabled: _vm.disabled },
      on: { click: _vm.click },
    },
    [
      _c("BaseIcon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isChecked,
            expression: "isChecked",
          },
        ],
        attrs: { name: "check" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }