var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        offset: _vm.showHeader,
        "rin-main-content-mobile-menu-opened":
          _vm.isSearchShown || _vm.isMenuShown,
        "rin-admin-part": _vm.$route.meta.isAdminPart,
        "rin-page-navbar-visible": _vm.isPageNavBarVisible,
        "rin-alternative-bg": _vm.isAlternativeBackgroud,
      },
      staticStyle: {
        display: "flex",
        "flex-flow": "column nowrap",
        "min-height": "100vh",
      },
      attrs: { id: "body-content-main" },
    },
    [
      _vm.showHeader
        ? _c(
            "header",
            {
              class: {
                "rin-search-open": _vm.isSearchShown || _vm.isMenuShown,
              },
            },
            [
              !_vm.layout.isEmbed ? _c("use-google-chrome-bar") : _vm._e(),
              _vm._v(" "),
              _vm.layout.isOldStoreApp
                ? _c("OldStoreAppWarningTopBar")
                : _vm._e(),
              _vm._v(" "),
              !_vm.layout.isEmbed &&
              _vm.layout.player.isAuthenticated &&
              !_vm.layout.player.IsProPlayer
                ? _c("NoWorriesTopBar")
                : _vm._e(),
              _vm._v(" "),
              !_vm.layout.isEmbed &&
              _vm.layout.player.isAuthenticated &&
              _vm.layout.player.IsProPlayer
                ? _c("LogosTopBar")
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Transition",
                { attrs: { name: "slide" } },
                [
                  !_vm.layout.isEmbed && _vm.showNavbar
                    ? _c("TopNav", {
                        attrs: { "show-back-arrow": _vm.scrolledPassHeader },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("portal-target", { attrs: { name: "page-navbar" } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isTopNotificationShown,
              expression: "isTopNotificationShown",
            },
          ],
          staticClass: "wrap margin-fix-afterheader",
          class: { "rin-search-open": _vm.isSearchShown || _vm.isMenuShown },
        },
        [
          _c(
            "div",
            {
              staticClass: "alert alert-top-notification",
              attrs: { role: "alert" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.topNotificationMessage) +
                  "\n            "
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: { click: _vm.closeTopNotification },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        {
          staticClass: "rin-main-content",
          class: {
            authenticated: _vm.layout.player.isAuthenticated && _vm.showHeader,
            notauthenticated:
              !_vm.layout.player.isAuthenticated && _vm.showHeader,
            "ads-offset": _vm.isAdVisible && _vm.$windowSize.isMobile,
          },
        },
        [
          _vm.isSearchShown ? _c("Search") : _vm._e(),
          _vm._v(" "),
          _vm.isMenuShown ? _c("NavMenu") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "container-search",
              class: {
                "rin-search-open": _vm.isSearchShown || _vm.isMenuShown,
              },
              attrs: { id: "landingPageContainer" },
            },
            [
              _vm.isAdVisible && !_vm.$windowSize.isMobile
                ? _c(
                    "div",
                    { class: _vm.currentFeature, attrs: { id: "banner-ad" } },
                    [_c("DesktopAdBanner")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdVisible && _vm.$windowSize.isMobile
                ? _c(
                    "div",
                    { attrs: { id: "anchor-ad" } },
                    [_c("BottomAdBanner")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("portal-target", { attrs: { name: "messages" } }),
              _vm._v(" "),
              _c("router-view", { key: _vm.getPathKey }),
            ],
            1
          ),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "main-body-modal" } }),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "main-base-modal" } }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.layout.isEmbed && _vm.$windowSize.isMobile && _vm.showFooter
        ? _c("BottomNav")
        : _vm._e(),
      _vm._v(" "),
      _c(_vm.adsComponentName, { tag: "component" }),
      _vm._v(" "),
      !_vm.layout.isEmbed && _vm.showFooter
        ? _c("layoutFooter", {
            class: { "rin-search-open": _vm.isSearchShown || _vm.isMenuShown },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.layout.isEmbed && _vm.showFooter ? _c("DesktopFooter") : _vm._e(),
      _vm._v(" "),
      _c("head2head-modal"),
      _vm._v(" "),
      _c("spinner-modal"),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        attrs: { size: "l", show: _vm.isMonetizationModalOpen },
        on: { close: _vm.hideProPlayersModal },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [_c("ModalProPlayers")]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        attrs: { show: _vm.showFreeTrialModal, backdrop: false },
        on: { close: _vm.hideFreeTrialModal },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("TrialModal", {
                  on: {
                    trialStarted: function ($event) {
                      return _vm.updateIsFreeTrialStarted(true)
                    },
                    close: _vm.hideFreeTrialModal,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        staticStyle: { "z-index": "10000000000" },
        attrs: { show: _vm.isBootboxShown },
        on: { close: _vm.rinBootbox.close },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("h3", [_vm._v(_vm._s(_vm.rinBootboxTitle))])]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.rinBootboxBody) +
                    "\n            "
                ),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.rinBootboxBodyHtml) },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _vm.rinBootboxSuccessBtn
                  ? _c(
                      "BaseButton",
                      {
                        attrs: { type: "primary", size: "m" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.rinBootbox.success.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.rinBootboxSuccessBtnText) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "BaseButton",
                  {
                    attrs: { type: "secondary", size: "m" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.rinBootbox.close.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.translations["shared_CloseButton"]) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("RegisterIndex"),
      _vm._v(" "),
      _c("div", { attrs: { "data-fuse-privacy-tool": "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }