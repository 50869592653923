var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.secondaryFreemiumModalShown,
          expression: "!secondaryFreemiumModalShown",
        },
      ],
    },
    [
      _c("BaseModalWrapper", {
        attrs: { size: "l", show: _vm.showFreemiumCampaign, backdrop: false },
        on: {
          close: function ($event) {
            _vm.showFreemiumCampaign = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "rin-freemium-campaing-content" }, [
                  _c("h1", [
                    _vm._v(
                      _vm._s(
                        _vm.translations["monetization_Freemium_Main_Introduce"]
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "rin-italic" }, [
                    _vm._v("(28.09.2022)"),
                  ]),
                  _vm._v(" "),
                  _c("h6", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.translations[
                            "monetization_Freemium_Main_NoWorries"
                          ]
                        ) +
                        "\n                    "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "rin-fremium-redirect",
                        on: { click: _vm.showNoWorries },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_Here"]
                          ) + "."
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h6", { staticClass: "rin-freemium-price" }, [
                    _vm._v(
                      _vm._s(
                        _vm.translations["monetization_Freemium_Main_Price"]
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "h6",
                    { staticClass: "rin-freemium-description rin-italic" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.translations[
                              "monetization_Freemium_Main_BodyText"
                            ]
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("h6", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.translations[
                            "monetization_Freemium_Main_FederationText"
                          ]
                        ) +
                        "\n                    "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "rin-fremium-redirect",
                        on: { click: _vm.showWhyFree },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.translations["monetization_Freemium_Here"]
                          ) + "."
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "rin-freemium-help-text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.translations[
                            "monetization_Freemium_Main_AnyQuestions"
                          ]
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "rin-fremium-redirect rin-freemium-contact",
                        attrs: { href: "mailto:support@rankedin.com" },
                      },
                      [_vm._v("support@rankedin.com")]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "rin-freemium-checkbox-wrapper" },
                      [
                        _c("BaseCheckbox", {
                          attrs: { "is-checked": _vm.checked },
                          nativeOn: {
                            click: function ($event) {
                              _vm.checked = !_vm.checked
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "rin-freemium-dont-show-again-text" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.translations[
                                  "monetization_Freemium_Main_DontShow"
                                ]
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "rin-freemium-actions-wrapper" },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { type: "primary", size: "l" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.okGotIt.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.translations[
                                    "monetization_Freemium_Main_Ok"
                                  ]
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }