var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "container-fluid container-lp-topbar ie",
        staticStyle: {
          display: "none",
          "background-color": "#ffce00",
          width: "100%",
        },
        attrs: { id: "browserSupport" },
      },
      [
        _c("p", { staticClass: "alignleft text-uppercase" }, [
          _vm._v("For best experience, please use Google Chrome"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }