var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-search-history" }, [
    _c("div", { staticClass: "rin-search-history-header" }, [
      _c("h4", [_vm._v("Last visited")]),
      _vm._v(" "),
      _c("span", { on: { click: _vm.removeAll } }, [_vm._v("clear all")]),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "rin-search-history-list" },
      _vm._l(_vm.searchHistory, function (history, index) {
        return _c(
          "li",
          { key: history.url },
          [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      event: "",
                      to: {
                        name: history.route.name,
                        params: history.route.params,
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.close(
                          {
                            name: history.route.name,
                            params: history.route.params,
                          },
                          index
                        )
                      },
                    },
                  },
                  [
                    !_vm.layout.player.IsProPlayer && index > 0
                      ? _c("BaseTypeLabel", {
                          attrs: { type: "pro" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.close.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n                    " +
                        _vm._s(history.name) +
                        "\n                "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isTournamentKillSwitchActive && history.isPremium
                  ? _c("StarIndicator", { attrs: { size: "s" } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("BaseIcon", {
              attrs: { name: "close" },
              nativeOn: {
                click: function ($event) {
                  return _vm.remove(history)
                },
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }