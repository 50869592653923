var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-register-country rin-register-form" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.translations["register_CountryHeading"]))]),
      _vm._v(" "),
      _c("BaseDropdown", {
        class: {
          "rin-concrete-selection": _vm.country && _vm.country.code > 0,
        },
        attrs: { buttonSize: "m", buttonType: "tertiary" },
        scopedSlots: _vm._u([
          {
            key: "button",
            fn: function () {
              return [
                _c("span", [
                  _vm.country != null
                    ? _c("i", {
                        class: ["flag-icon flag-icon-" + _vm.country.short],
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.country != null
                            ? _vm.country.name
                            : _vm.translations["calendarIndex_CountryFilter"]
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("li", { staticClass: "no-hover" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterCountriesInput,
                        expression: "filterCountriesInput",
                      },
                    ],
                    staticClass: "countries-input rounded-pill form-control",
                    attrs: { inputmode: "search" },
                    domProps: { value: _vm.filterCountriesInput },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.filterCountriesInput = $event.target.value
                        },
                        _vm.updateFilter,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _vm._l(_vm.countriesList, function (c) {
                  return _c(
                    "li",
                    {
                      key: c.code,
                      on: {
                        click: function ($event) {
                          return _vm.updateUserCountry(c)
                        },
                      },
                    },
                    [
                      _c("i", { class: ["flag-icon flag-icon-" + c.short] }),
                      _vm._v("\n\t\t\t\t" + _vm._s(c.name) + "\n\t\t\t"),
                    ]
                  )
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c(
            "BaseAlert",
            { attrs: { type: "danger", "has-background": false } },
            [
              _vm.triggerRequiredError
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(_vm._s(_vm.translations["shared_T12"])),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "BaseButton",
        {
          attrs: { type: "success", size: "m", disabled: _vm.isNextDisabled },
          on: { click: _vm.goToNext },
        },
        [_vm._v(" " + _vm._s(_vm.translations["shared_Next"]) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }