var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-navmenu-backdrop" }, [
    _c("div", { staticClass: "wrap rin-navmenu-wrap" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.close,
              expression: "close",
            },
          ],
          staticClass: "rin-navmenu",
        },
        [
          _c("div", { staticClass: "rin-navmenu-close" }, [
            _c("div", { staticClass: "rin-navmenu-menu" }, [_vm._v("Menu")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "rin-navmenu-close-icon",
                on: { click: _vm.close },
              },
              [_c("BaseIcon", { attrs: { name: "close" } })],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isAuthorized
            ? _c(
                "div",
                { staticClass: "rin-navmenu-user-card" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "player-info",
                          params: {
                            language: _vm.language,
                            id: _vm.rankedinId,
                            name: _vm.urlName,
                          },
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.close.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("ProfileImage"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "rin-player-info" },
                        [
                          _c("BaseTypeLabel", {
                            staticClass: "ml-0",
                            attrs: {
                              type: _vm.layout.player.IsProPlayer
                                ? "pro"
                                : "notpro",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "rin-player-name" }, [
                            _vm._v(_vm._s(_vm.playerName)),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "rin-player-link" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.translations[
                                    "topNavigation_Menu_SeeProfile"
                                  ]
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rin-navmenu-actions-wrap" },
            [
              _vm.isAuthorized
                ? _c("NavMenuAction", {
                    attrs: {
                      icon: "club",
                      title: _vm.translations["topNavigation_Menu_Club"],
                      destinationName: _vm.homeClubDestination,
                      params: { language: _vm.language, id: _vm.homeClubId },
                      description:
                        _vm.translations["topNavigation_Menu_ClubDescription"],
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isAuthorized
                ? _c("NavMenuAction", {
                    class: "custom-increase-font-size",
                    attrs: {
                      icon: "ranking",
                      title: _vm.translations["topNavigation_Menu_Rankings"],
                      destinationName: _vm.rankingDestination,
                      params: { language: _vm.language, id: _vm.rankingId },
                      description:
                        _vm.translations[
                          "topNavigation_Menu_RankingsDescription"
                        ],
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isAuthorized
                ? _c("NavMenuAction", {
                    attrs: {
                      icon: "ereferee",
                      title: _vm.translations["topNavigation_Menu_EReferee"],
                      destinationName: "refereeBoard",
                      params: { language: _vm.language },
                      description:
                        _vm.translations[
                          "topNavigation_Menu_ERefereeDescription"
                        ],
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isAuthorized
                ? _c("NavMenuAction", {
                    staticClass: "admin",
                    attrs: {
                      icon: "my-panel",
                      title: _vm.translations["topNavigation_Menu_AdminPanel"],
                      destinationName: "player-manage",
                      params: { language: _vm.language },
                      description:
                        _vm.translations[
                          "topNavigation_Menu_AdminPanelDescription"
                        ],
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAuthorized
                ? _c("NavMenuAction", {
                    class: "not-logged-in-menu-icon",
                    attrs: {
                      icon: "features",
                      title: _vm.translations["topNavigation_Menu_Features"],
                      destinationName: "landing-page",
                      params: { language: _vm.language },
                      hash: "#features",
                      description: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAuthorized
                ? _c("NavMenuAction", {
                    class: "not-logged-in-menu-icon",
                    attrs: {
                      icon: "pricing",
                      title: _vm.translations["topNavigation_Menu_Pricing"],
                      destinationName: "pricing",
                      params: { language: _vm.language },
                      description: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAuthorized
                ? _c("NavMenuAction", {
                    class: "not-logged-in-menu-icon",
                    attrs: {
                      icon: "demo",
                      title: _vm.translations["topNavigation_Menu_Demo"],
                      destinationName: "request-demo",
                      params: { language: _vm.language },
                      description:
                        _vm.translations["topNavigation_Menu_DemoDescription"],
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAuthorized
                ? _c("NavMenuAction", {
                    class: "not-logged-in-menu-icon",
                    attrs: {
                      icon: "contact",
                      title: _vm.translations["topNavigation_Menu_Contact"],
                      destinationName: "landing-page",
                      params: { language: _vm.language },
                      hash: "#contact",
                      description: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAuthorized ? _c("NavLoginOrCreate") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "rin-navmenu-list",
              class: { "logged-out-list": !_vm.isAuthorized },
            },
            [
              _c("ul", [
                _vm.isAdmin
                  ? _c(
                      "li",
                      { staticClass: "rin-admin-panel" },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "/" + _vm.language + "/statistics" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("BaseIcon", { attrs: { name: "profile" } }),
                                _vm._v(" Super admin"),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("BaseIcon", {
                          staticClass: "rin-menu-right",
                          attrs: { name: "v" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthorized && _vm.layout.player.IsProPlayer
                  ? _c("li", { on: { click: _vm.manageSubscription } }, [
                      _c(
                        "div",
                        [
                          _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.translations[
                                  "topNavigation_Menu_ManageSubscription"
                                ]
                              ) +
                              "\n                        "
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthorized && !_vm.layout.player.IsProPlayer
                  ? _c("li", { on: { click: _vm.updgradeToProPlan } }, [
                      _c(
                        "div",
                        { staticClass: "pro-color" },
                        [
                          _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.translations[
                                  "monetization_Player_TopbarUpgradeDescription1"
                                ]
                              ) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "font-italic" }, [
                            _vm._v("PRO"),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    class: { open: _vm.languageMenuOpen },
                    on: { click: _vm.openLanguageMenu },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("BaseFlag", {
                          attrs: { country: _vm.languageFlag },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.translations["topNavigation_Menu_Language"]
                            ) +
                            "  "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("BaseIcon", {
                      staticClass: "rin-menu-right",
                      attrs: { name: "v" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "rin-nav-language-select",
                    class: { open: _vm.languageMenuOpen },
                  },
                  [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("hr")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "hr" } }),
                        _vm._v(" Croatian\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("dk")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "dk" } }),
                        _vm._v(" Danish\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("nl")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "nl" } }),
                        _vm._v(" Dutch\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("en")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "gb" } }),
                        _vm._v(" English\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("fr")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "fr" } }),
                        _vm._v(" French\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("de")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "de" } }),
                        _vm._v(" German\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("it")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "it" } }),
                        _vm._v(" Italian\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("pl")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "pl" } }),
                        _vm._v(" Polish\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("pt")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "pt" } }),
                        _vm._v(" Portuguese\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("ru")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "ru" } }),
                        _vm._v(" Russian\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("si")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "si" } }),
                        _vm._v(" Slovenian\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("es")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "es" } }),
                        _vm._v(" Spanish\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("se")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "se" } }),
                        _vm._v(" Swedish\n                        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLanguage("rs")
                          },
                        },
                      },
                      [
                        _c("BaseFlag", { attrs: { country: "rs" } }),
                        _vm._v(" Serbian\n                        "),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isAuthorized
                  ? _c(
                      "li",
                      { on: { click: _vm.settingsClick } },
                      [
                        _c(
                          "div",
                          [
                            _c("BaseIcon", { attrs: { name: "settings" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.translations[
                                    "topNavigation_Menu_Settings"
                                  ]
                                )
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("BaseIcon", {
                          staticClass: "rin-menu-right",
                          attrs: { name: "v" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthorized
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.language + "/account/logoff",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("BaseIcon", { attrs: { name: "log_out" } }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.translations[
                                        "topNavigation_Menu_LogOut"
                                      ]
                                    )
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.showInstallButton
                ? _c("div", { staticClass: "rin-navmenu-pwa-option" }, [
                    _c(
                      "div",
                      { staticClass: "rin-pwa-install-menu-option" },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1",
                            alt: "Rankedin",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c("h4", [_vm._v("Rankedin")]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.translations["campaign_Pwa_Title"])
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "BaseButton",
                          {
                            attrs: { type: "success", size: "s" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.installClicked.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.translations["campaign_Pwa_Install"]
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isModalVisible && !_vm.shouldInstallPwaNow
                ? [
                    _c("PwaCampaign", {
                      on: {
                        "close-pwa-modal": function ($event) {
                          _vm.isModalVisible = false
                        },
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }