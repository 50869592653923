var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hide-ads-container" },
    [
      _c(
        "button",
        { staticClass: "hide-ads-button", on: { click: _vm.toggleModal } },
        [_vm._v(" " + _vm._s(_vm.translations["shared_Ads_HideAds"]))]
      ),
      _vm._v(" "),
      _vm.showProPurchaseModal
        ? _c(
            "portal",
            { attrs: { to: "main-base-modal" } },
            [
              _c("BaseModalWrapper", {
                attrs: { size: "l", show: _vm.showProPurchaseModal },
                on: {
                  close: function ($event) {
                    _vm.showProPurchaseModal = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function () {
                        return [_c("ModalProPlayers")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3616782622
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showTrialModal
        ? _c(
            "portal",
            { attrs: { to: "main-base-modal" } },
            [
              _c("BaseModalWrapper", {
                staticClass: "rin-modal-no-padding",
                attrs: { size: "m", show: _vm.showTrialModal },
                on: { close: _vm.closeTrialModal },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("TrialModal", {
                            on: {
                              trialStarted: _vm.trialStarted,
                              close: _vm.closeTrialModal,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4141644419
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }