var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-page-top-bar-container" },
    [
      _c("div", { staticClass: "wrap" }, [
        _vm.$windowSize.isMobile
          ? _c(
              "div",
              {
                staticClass: "rin-page-top-bar-back-btn",
                on: { click: _vm.goBack },
              },
              [_c("BaseIcon", { attrs: { name: "back" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "rin-no-worries-bar-text" }, [
          _c("div", { staticClass: "rin-page-top-bar-upgrade-description" }, [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(
                  _vm.translations["freemium_Top_Bar_Cant_Find_Something"]
                ) +
                "\n\t\t\t\t"
            ),
            _c(
              "span",
              {
                staticClass: "rin-top-bar-click-here",
                on: { click: _vm.showNoWorriesModal },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.translations["freemium_Top_Bar_Click_Here"]) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "portal",
            { attrs: { to: "main-base-modal" } },
            [
              _c("BaseModalWrapper", {
                attrs: { size: "l", show: _vm.showModal },
                on: {
                  close: function ($event) {
                    _vm.showModal = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function () {
                        return [_c("FreemiumNoWorries")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2254608470
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }