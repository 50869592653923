var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: "searchref",
    staticClass: "rin-search-input",
    attrs: {
      type: "text",
      placeholder: _vm.translations["shared_Search_Global_Placeholder"],
      autocomplete: "off",
    },
    domProps: { value: _vm.term },
    on: {
      input: (event) => (_vm.term = event.target.value),
      click: _vm.showSearch,
      keyup: _vm.search,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }