var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { class: `rin-type-label ${_vm.type}` }, [
    _vm._v("\n    " + _vm._s(_vm.text(_vm.isShortLabel)) + "\n    "),
    _vm.isShortLabel
      ? _c(
          "span",
          { staticClass: "tooltiptext", class: `rin-type-label ${_vm.type}` },
          [_vm._v("\n        " + _vm._s(_vm.text(false)) + "\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }