var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-register-sport rin-register-form" },
    [
      _c("h1", [_vm._v("Pick your sport")]),
      _vm._v(" "),
      _c("BaseDropdown", {
        class: { "rin-concrete-selection": _vm.sport },
        attrs: { buttonSize: "m", buttonType: "tertiary" },
        scopedSlots: _vm._u([
          {
            key: "button",
            fn: function () {
              return [
                _c("span", [
                  _c("i", {
                    class: _vm.nomenclatures.SportIconClass[_vm.sport - 1],
                  }),
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.selectedSportDescription) +
                      "\n\t\t\t"
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return _vm._l(_vm.sports, function (sport) {
                return _c(
                  "li",
                  {
                    key: sport.Id,
                    on: {
                      click: function ($event) {
                        return _vm.updateSport(sport.Id)
                      },
                    },
                  },
                  [
                    _c("i", {
                      class: _vm.nomenclatures.SportIconClass[sport.Id - 1],
                    }),
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(sport.Description) + "\n\t\t\t"
                    ),
                  ]
                )
              })
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c(
            "BaseAlert",
            { attrs: { type: "danger", "has-background": false } },
            [
              _vm.triggerRequiredError
                ? _c("span", { staticClass: "rin-register-error" }, [
                    _vm._v(_vm._s(_vm.translations["register_2"])),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "BaseButton",
        {
          attrs: { type: "success", size: "m", disabled: _vm.isNextDisabled },
          on: { click: _vm.goToNext },
        },
        [_vm._v(" Next ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }