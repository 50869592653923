var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "navbar-header navbar-search mainSearch",
        attrs: { id: "search-app" },
      },
      [
        _c(
          "ul",
          { staticClass: "nav navbar-nav navbar-iconic-search pull-right" },
          [
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "navbar-search-wrapper",
                  class: { active: _vm.isMobileSearchOpen },
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "navbar-search-form search-form-mobile",
                      attrs: { onsubmit: "return false;", role: "search" },
                    },
                    [
                      _c("div", { staticClass: "input-group" }, [
                        _c("span", { staticClass: "input-group-addon" }, [
                          !_vm.isSearchOpen
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.openMobileSearch },
                                },
                                [_c("i", { staticClass: "fa ti-search" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSearchOpen
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.closeResults },
                                },
                                [_c("i", { staticClass: "fa ti-close" })]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchTerm,
                              expression: "searchTerm",
                            },
                          ],
                          ref: "searchinput",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.translations["shared_T26"] + "...",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.searchTerm },
                          on: {
                            keyup: _vm.search,
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.searchTerm = $event.target.value
                              },
                              function ($event) {
                                _vm.searchTerm = $event.target.value
                              },
                            ],
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "hidden-lg btn-search-mobile",
                  staticStyle: { "margin-right": "40px" },
                  attrs: { href: "#" },
                  on: { click: _vm.openMobileSearch },
                },
                [
                  _c("i", { staticClass: "fa ti-search" }),
                  _c("p", { staticClass: "hidden-xs hidden-sm" }, [
                    _vm._v("Search"),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { attrs: { id: "search-results-app" } }, [
      _vm.resultsOpen
        ? _c(
            "div",
            { staticClass: "dropdown-menu dropdown-menu-search-results" },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn-default",
                  staticStyle: {
                    float: "right",
                    position: "static",
                    "margin-top": "15px",
                  },
                  attrs: { href: "#" },
                  on: { click: _vm.closeResults },
                },
                [_c("i", { staticClass: "fa ti-close" })]
              ),
              _vm._v(" "),
              _vm.noResults
                ? _c(
                    "div",
                    {
                      staticClass: "text-center alert-warning",
                      staticStyle: { "font-size": "20px" },
                    },
                    [_vm._v(_vm._s(_vm.translations["shared_T27"]) + ".")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tournaments.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "header searchHeader" }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.translations["topBar_18"])),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.tournaments, function (tournament) {
                        return _c("div", [
                          _c("a", { attrs: { href: tournament.EventUrl } }, [
                            _c(
                              "div",
                              { staticClass: "search-result clearfix" },
                              [
                                _c("div", { staticClass: "sr-desc" }, [
                                  _c("div", { staticClass: "flag-and-name" }, [
                                    _c("i", {
                                      staticClass: "flag-icon",
                                      class:
                                        "flag-icon-" + tournament.CountryFlag,
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      { staticClass: "organisation-name" },
                                      [_vm._v(_vm._s(tournament.Name))]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "searchResultInfo" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label-info" },
                                      [_vm._v("Start date:")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "startdate",
                                        staticStyle: {
                                          "vertical-align": "text-top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateAndTime")(
                                              tournament.StartDate
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fa",
                                      class:
                                        _vm.nomenclatures.SportIconClass[
                                          tournament.Sport
                                        ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "showmore showmoreTournaments col-sm-offset-4 col-sm-4",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-lg btn-block btn-primary btn-primary-outlined",
                                on: { click: _vm.tournamentsLoad },
                              },
                              [
                                _c("i", { staticClass: "fa fa-ellipsis-h" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translations["shared_ShowMore"])
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "showmore clearfix" }),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.participants.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "header searchHeader" }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.translations["topBar_19"])),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.participants, function (participant) {
                        return _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "search-result sr-type-player clearfix",
                            },
                            [
                              _c("div", { staticClass: "sr-desc" }, [
                                _c("div", { staticClass: "flag-and-name" }, [
                                  _c(
                                    "h4",
                                    { staticClass: "participant-name" },
                                    [
                                      _c("i", {
                                        staticClass: "flag-icon",
                                        class: "flag-icon-" + participant.Flag,
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(participant.Name)),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "result-details" }, [
                                  _vm._v(_vm._s(participant.Details)),
                                ]),
                                _c("small", [_vm._v("ID: ")]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(_vm._s(participant.RankedinId)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "sr-player-actions hidden" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "btn-group",
                                        attrs: { role: "group" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-default btn-sm",
                                            attrs: {
                                              href:
                                                "/" +
                                                _vm.layout.language +
                                                "/player/" +
                                                participant.RankedinId,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-fw fa-user",
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "hidden-xs" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.translations[
                                                        "shared_Matches_Player_Profile"
                                                      ]
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(0, true),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "showmore showmoreParticipants col-sm-offset-4 col-sm-4",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-lg btn-block btn-primary btn-primary-outlined",
                                on: { click: _vm.participantsLoad },
                              },
                              [
                                _c("i", { staticClass: "fa fa-ellipsis-h" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translations["shared_ShowMore"])
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "showmore clearfix" }),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.rankings.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "header searchHeader" }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.translations["shared_Rankings"])),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.rankings, function (ranking) {
                        return _c("div", [
                          _c("div", { staticClass: "search-result clearfix" }, [
                            _c("div", { staticClass: "sr-desc" }, [
                              _c("div", { staticClass: "flag-and-name" }, [
                                _c("i", {
                                  staticClass: "flag-icon",
                                  class: "flag-icon-" + ranking.Flag,
                                }),
                                _vm._v(" "),
                                _c("h4", { staticClass: "ranking-name" }, [
                                  _vm._v(_vm._s(ranking.Name)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "result-details" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(ranking.OrganisationName)),
                                  ]),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa text-active",
                                    class: ranking.SportClass,
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "sr-player-actions hidden" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn-group",
                                      attrs: { role: "group" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-default btn-sm",
                                          attrs: { href: ranking.Url },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw fa-trophy",
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "hidden-xs" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.translations["topBar_31"]
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(1, true),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "showmore showmoreRankings col-sm-offset-4 col-sm-4",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-lg btn-block btn-primary btn-primary-outlined",
                                on: { click: _vm.rankingsLoad },
                              },
                              [
                                _c("i", { staticClass: "fa fa-ellipsis-h" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translations["shared_ShowMore"])
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "showmore clearfix" }),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.organisations.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "header searchHeader" }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.translations["topBar_22"])),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.organisations, function (organisation) {
                        return _c("div", [
                          _c("div", { staticClass: "search-result clearfix" }, [
                            _c("div", { staticClass: "sr-desc" }, [
                              _c("div", { staticClass: "flag-and-name" }, [
                                _c("i", {
                                  staticClass: "flag-icon",
                                  class: "flag-icon-" + organisation.Flag,
                                }),
                                _vm._v(" "),
                                _c("h4", { staticClass: "organisation-name" }, [
                                  _vm._v(_vm._s(organisation.Name)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "searchResultInfo" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "result-details",
                                    staticStyle: { "vertical-align": "middle" },
                                  },
                                  [_vm._v(_vm._s(organisation.Location))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "sr-player-actions hidden" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn-group",
                                      attrs: { role: "group" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-default btn-sm",
                                          attrs: { href: organisation.Url },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-fw fa-home",
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "hidden-xs" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.translations[
                                                      "tournament_Shared_Organization"
                                                    ]
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(2, true),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "showmore showmoreOrganisations col-sm-offset-4 col-sm-4",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-lg btn-block btn-primary btn-primary-outlined",
                                on: { click: _vm.organisationsLoad },
                              },
                              [
                                _c("i", { staticClass: "fa fa-ellipsis-h" }),
                                _vm._v(
                                  _vm._s(_vm.translations["shared_ShowMore"])
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "showmore clearfix" }),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.clubleagues.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "header searchHeader" }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.translations["topBar_20"])),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.clubleagues, function (clubleague) {
                        return _c("div", [
                          _c("a", { attrs: { href: clubleague.EventUrl } }, [
                            _c(
                              "div",
                              { staticClass: "search-result clearfix" },
                              [
                                _c("div", { staticClass: "sr-desc" }, [
                                  _c("i", {
                                    staticClass: "flag-icon",
                                    class:
                                      "flag-icon-" + clubleague.CountryFlag,
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "h4",
                                    { staticClass: "organisation-name" },
                                    [_vm._v(_vm._s(clubleague.Name))]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "searchResultInfo" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label-info" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.translations["topBar_26"]
                                          ) + ":"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "startdate",
                                        staticStyle: {
                                          "vertical-align": "text-top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateAndTime")(
                                              clubleague.StartDate
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fa",
                                      class:
                                        _vm.nomenclatures.SportIconClass[
                                          clubleague.Sport
                                        ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "showmore showmoreClubLeagues col-sm-offset-4 col-sm-4",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-lg btn-block btn-primary btn-primary-outlined",
                                on: { click: _vm.clubLeaguesLoad },
                              },
                              [
                                _c("i", { staticClass: "fa fa-ellipsis-h" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translations["shared_ShowMore"])
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "showmore clearfix" }),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.teamleagues.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "header searchHeader" }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.translations["teamLeaguesTitle"])),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.teamleagues, function (teamleague) {
                        return _c("div", [
                          _c("a", { attrs: { href: teamleague.EventUrl } }, [
                            _c(
                              "div",
                              { staticClass: "search-result clearfix" },
                              [
                                _c("div", { staticClass: "sr-desc" }, [
                                  _c("i", {
                                    staticClass: "flag-icon",
                                    class:
                                      "flag-icon-" + teamleague.CountryFlag,
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "h4",
                                    { staticClass: "organisation-name" },
                                    [_vm._v(_vm._s(teamleague.Name))]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "searchResultInfo" }, [
                                    _c(
                                      "span",
                                      { staticClass: "label label-info" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.translations["topBar_26"]
                                          ) + ":"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "startdate",
                                        staticStyle: {
                                          "vertical-align": "text-top",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("dateAndTime")(
                                              teamleague.StartDate
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fa",
                                      class:
                                        _vm.nomenclatures.SportIconClass[
                                          teamleague.Sport
                                        ],
                                    }),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "footer" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "showmore showmoreTeamLeagues col-sm-offset-4 col-sm-4",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-lg btn-block btn-primary btn-primary-outlined",
                                on: { click: _vm.teamLeaguesLoad },
                              },
                              [
                                _c("i", { staticClass: "fa fa-ellipsis-h" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.translations["shared_ShowMore"])
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "showmore clearfix" }),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-primary btn-sm close-sr-player-actions",
        attrs: { type: "button" },
      },
      [_c("i", { staticClass: "fa fa-fw fa-times" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-primary btn-sm close-sr-player-actions",
        attrs: { type: "button" },
      },
      [_c("i", { staticClass: "fa fa-fw fa-times" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-primary btn-sm close-sr-player-actions",
        attrs: { type: "button" },
      },
      [_c("i", { staticClass: "fa fa-fw fa-times" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }