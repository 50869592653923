var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { tabindex: "-1", role: "dialog", id: "modal-enter-results" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("p", [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.translations["shared_MatchesListPartial_EnterResults"]
                  )
                ),
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "sport-icon",
                class: _vm.sportCSSClass + " fa-2x",
              }),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("div", { staticClass: "modal-body" }, [
                _c("form", { attrs: { id: "modal-form-enter-result" } }, [
                  _c("div", { staticClass: "row jcb aie" }, [
                    _c(
                      "div",
                      { staticClass: "col col-5 form-group text-center" },
                      [
                        _c("label", { attrs: { for: "player1" } }, [
                          _vm._v(
                            _vm._s(_vm.participant1Name) +
                              " " +
                              _vm._s(_vm.firstPositionWinState)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isCancellationType,
                              expression: "!isCancellationType",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.firstPlayerScore,
                              expression: "firstPlayerScore",
                            },
                          ],
                          class: {
                            required:
                              _vm.validation.gamesAndSetsMismatch ||
                              (!_vm.isValidSetGameFields &&
                                _vm.validation.participantScoresChanged),
                          },
                          attrs: {
                            id: "firstPlayerScore",
                            type: "number",
                            min: "0",
                            max: "5",
                            oninput: "validity.valid||(value='');",
                          },
                          domProps: { value: _vm.firstPlayerScore },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.firstPlayerScore = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "dropdown col col-2 text-center opt opt-result",
                      },
                      [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isTournament || _vm.isTeamLeague,
                                expression: "isTournament || isTeamLeague",
                              },
                            ],
                            attrs: {
                              href: "#",
                              role: "button",
                              id: "result-option",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.resultOptionText) +
                                " "
                            ),
                            _c("div", { staticClass: "icon icon-v small" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu optMenu",
                            attrs: { "aria-labelledby": "result-option" },
                          },
                          [
                            _c(
                              "a",
                              {
                                class: { active: _vm.cancellationTypeId === 2 },
                                attrs: { href: "#", "data-toggle": "collapse" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setCancellation(2)
                                  },
                                },
                              },
                              [_vm._v("W.O")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                class: { active: _vm.cancellationTypeId === 4 },
                                attrs: { href: "#", "data-toggle": "collapse" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setCancellation(4)
                                  },
                                },
                              },
                              [_vm._v("Ret.")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                class: { active: _vm.cancellationTypeId === 3 },
                                attrs: { href: "#", "data-toggle": "collapse" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setCancellation(3)
                                  },
                                },
                              },
                              [_vm._v("Dis.")]
                            ),
                            _vm._v(" "),
                            _vm.isCancellationType
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "reset-canecllation-href",
                                    attrs: {
                                      href: "#",
                                      "data-toggle": "collapse",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCancellation(null)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.translations["drawsStep_Clear"]
                                      ) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-5 form-group text-center" },
                      [
                        _c("label", { attrs: { for: "player2" } }, [
                          _vm._v(
                            _vm._s(_vm.participant2Name) +
                              " " +
                              _vm._s(_vm.secondPositionWinState)
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.isCancellationType
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.secondPlayerScore,
                                  expression: "secondPlayerScore",
                                },
                              ],
                              staticClass: "player",
                              class: {
                                required:
                                  _vm.validation.gamesAndSetsMismatch ||
                                  (!_vm.isValidSetGameFields &&
                                    _vm.validation.participantScoresChanged),
                              },
                              attrs: {
                                id: "secondPlayerScore",
                                type: "number",
                                min: "0",
                                max: "5",
                                oninput: "validity.valid||(value='');",
                              },
                              domProps: { value: _vm.secondPlayerScore },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.secondPlayerScore = $event.target.value
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCancellationType,
                            expression: "!isCancellationType",
                          },
                        ],
                        staticClass: "col col-12 form-check detailedScoring",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.shouldShow.showExtendedScores,
                              expression: "shouldShow.showExtendedScores",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "checkbox",
                            id: "detailedScoring",
                            href: "javascript:;",
                            role: "button",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.shouldShow.showExtendedScores
                            )
                              ? _vm._i(
                                  _vm.shouldShow.showExtendedScores,
                                  null
                                ) > -1
                              : _vm.shouldShow.showExtendedScores,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleExtendedScoring()
                            },
                            change: function ($event) {
                              var $$a = _vm.shouldShow.showExtendedScores,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.shouldShow,
                                      "showExtendedScores",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.shouldShow,
                                      "showExtendedScores",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.shouldShow,
                                  "showExtendedScores",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "detailedScoring" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.translations[
                                  "pLayer_PLayerEventsPartial_ExtendedScore"
                                ]
                              ) + ":"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row collapse",
                      class: { show: _vm.isCancellationType },
                    },
                    [
                      _c("div", { staticClass: "col col-12 p-0" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("div", { staticClass: "col col-12" }, [
                              _c("label", { attrs: { for: "player01" } }, [
                                _vm._v(
                                  _vm._s(_vm.translations["shared_T16"]) + ":"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-12 form-radio" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.winnerPosition,
                                      expression: "winnerPosition",
                                    },
                                  ],
                                  staticClass: "form-radio-input",
                                  attrs: {
                                    type: "radio",
                                    value: "1",
                                    id: "player01",
                                    name: "player",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.winnerPosition, "1"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.winnerPosition = "1"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-radio-label",
                                    attrs: { for: "player01" },
                                  },
                                  [_vm._v(_vm._s(_vm.participant1Name))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col col-12 form-radio" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.winnerPosition,
                                      expression: "winnerPosition",
                                    },
                                  ],
                                  staticClass: "form-radio-input",
                                  attrs: {
                                    type: "radio",
                                    value: "2",
                                    id: "player02",
                                    name: "player",
                                  },
                                  domProps: {
                                    checked: _vm._q(_vm.winnerPosition, "2"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.winnerPosition = "2"
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-radio-label",
                                    attrs: { for: "player02" },
                                  },
                                  [_vm._v(_vm._s(_vm.participant2Name))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.validation.cancelationWinnerNotSelected
                              ? _c(
                                  "BaseAlert",
                                  {
                                    attrs: {
                                      type: "danger",
                                      "has-background": false,
                                    },
                                  },
                                  [
                                    _vm.winnerPosition == null
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translations[
                                                "shared_SelectWinner_ValidationError"
                                              ]
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isCancellationTypeAllowResultSubmit
                    ? _c(
                        "div",
                        { staticClass: "row jcb aie score-on-cancel-div" },
                        [
                          _c(
                            "div",
                            { staticClass: "col col-5 form-group text-center" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.firstPlayerScore,
                                    expression: "firstPlayerScore",
                                  },
                                ],
                                class: {
                                  required:
                                    _vm.validation.gamesAndSetsMismatch ||
                                    (!_vm.isValidSetGameFields &&
                                      _vm.validation.participantScoresChanged),
                                },
                                attrs: {
                                  id: "firstPlayerScore",
                                  type: "number",
                                  min: "0",
                                  max: "5",
                                  oninput: "validity.valid||(value='');",
                                },
                                domProps: { value: _vm.firstPlayerScore },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.firstPlayerScore = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "dropdown col col-2 text-center opt opt-result",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col col-5 form-group text-center" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.secondPlayerScore,
                                    expression: "secondPlayerScore",
                                  },
                                ],
                                staticClass: "player",
                                class: {
                                  required:
                                    _vm.validation.gamesAndSetsMismatch ||
                                    (!_vm.isValidSetGameFields &&
                                      _vm.validation.participantScoresChanged),
                                },
                                attrs: {
                                  id: "secondPlayerScore",
                                  type: "number",
                                  min: "0",
                                  max: "5",
                                  oninput: "validity.valid||(value='');",
                                },
                                domProps: { value: _vm.secondPlayerScore },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.secondPlayerScore = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col col-12 form-check detailedScoring",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shouldShow.showExtendedScores,
                                    expression: "shouldShow.showExtendedScores",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "detailedScoring",
                                  href: "javascript:;",
                                  role: "button",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.shouldShow.showExtendedScores
                                  )
                                    ? _vm._i(
                                        _vm.shouldShow.showExtendedScores,
                                        null
                                      ) > -1
                                    : _vm.shouldShow.showExtendedScores,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleExtendedScoring()
                                  },
                                  change: function ($event) {
                                    var $$a = _vm.shouldShow.showExtendedScores,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.shouldShow,
                                            "showExtendedScores",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.shouldShow,
                                            "showExtendedScores",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.shouldShow,
                                        "showExtendedScores",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "detailedScoring" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.translations[
                                        "pLayer_PLayerEventsPartial_ExtendedScore"
                                      ]
                                    ) + ":"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row collapse",
                      class: {
                        show:
                          (!_vm.isCancellationType ||
                            _vm.isCancellationTypeAllowResultSubmit) &&
                          _vm.shouldShow.showExtendedScores,
                      },
                      attrs: { id: "detailedScoringCollapse" },
                    },
                    [
                      _c("div", { staticClass: "col col-12 col-md-5 p-0" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _vm._l(_vm.extendedScores, function (score, index) {
                              return _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col col-6" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: score[0],
                                        expression: "score[0]",
                                      },
                                    ],
                                    staticClass: "gameResult",
                                    class: {
                                      required:
                                        score[0] == score[1] &&
                                        _vm.validation.resultSubmitClicked,
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "0",
                                      value: "0",
                                      oninput: "validity.valid||(value='');",
                                    },
                                    domProps: { value: score[0] },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(score, 0, $event.target.value)
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col col-6" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: score[1],
                                        expression: "score[1]",
                                      },
                                    ],
                                    staticClass: "gameResult",
                                    class: {
                                      required:
                                        score[0] == score[1] &&
                                        _vm.validation.resultSubmitClicked,
                                    },
                                    attrs: {
                                      type: "number",
                                      min: "0",
                                      value: "0",
                                      oninput: "validity.valid||(value='');",
                                    },
                                    domProps: { value: score[1] },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(score, 1, $event.target.value)
                                      },
                                    },
                                  }),
                                ]),
                              ])
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.hasTiebreaks,
                              expression: "hasTiebreaks",
                            },
                          ],
                          staticClass: "col col-12 col-md-4",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _vm._l(
                                _vm.extendedScores,
                                function (score, index) {
                                  return _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col col-6" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: score[3],
                                            expression: "score[3]",
                                          },
                                        ],
                                        staticClass: "gameResult tiebreaks",
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          oninput:
                                            "validity.valid||(value='');",
                                        },
                                        domProps: { value: score[3] },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              score,
                                              3,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col col-6" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: score[4],
                                            expression: "score[4]",
                                          },
                                        ],
                                        staticClass: "gameResult tiebreaks",
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          oninput:
                                            "validity.valid||(value='');",
                                        },
                                        domProps: { value: score[4] },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              score,
                                              4,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col col-12 col-md-3" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col col-12 text-center" },
                              [
                                _c("label", { attrs: { for: "lorem3" } }, [
                                  _vm._v(
                                    _vm._s(_vm.translations["shared_T15"]) +
                                      " (min)"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.extendedScores, function (score, index) {
                              return _c("div", { staticClass: "col col-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: score[2],
                                      expression: "score[2]",
                                    },
                                  ],
                                  staticClass: "gameResult setDuration",
                                  attrs: {
                                    type: "number",
                                    value: "0",
                                    min: "0",
                                    oninput: "validity.valid||(value='');",
                                  },
                                  domProps: { value: score[2] },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(score, 2, $event.target.value)
                                    },
                                  },
                                }),
                              ])
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: {
                        "margin-top": "1.5rem",
                        "margin-bottom": ".5rem",
                      },
                    },
                    [
                      _c("div", { staticClass: "col-12 p-0" }, [
                        _c("div", { staticClass: "row aic" }, [
                          _c("div", { staticClass: "col col-auto" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-sm",
                                attrs: { href: "#", "data-dismiss": "modal" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations[
                                      "clubLeagueSettingsPartial_Cancel"
                                    ]
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col col-auto" }, [
                            _c("input", {
                              staticClass:
                                "text-white btn btn-info btn-sm width-auto",
                              attrs: {
                                type: "button",
                                id: "submitResults",
                                value: "Submit",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitResults()
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col text-right" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-sm",
                                attrs: {
                                  href: "#",
                                  "data-toggle": "collapse",
                                  role: "button",
                                  "aria-expanded": "false",
                                  "aria-controls": "confirmBox",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetResult()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations[
                                      "shared_Matches_ResetResult_Action"
                                    ]
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [
        _c("span", {
          staticClass: "icon icon-plus",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col col-12 text-center" }, [
      _c("label", { attrs: { for: "lorem3" } }, [_vm._v("Sets")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col col-12 text-center" }, [
      _c("label", { attrs: { for: "lorem3" } }, [_vm._v("Tiebreak")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }