var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { id: "createEventModal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header text-center" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _c("span", [_vm._v(_vm._s(_vm.creatingEventName))]),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row aic" }, [
              _c("div", { staticClass: "col col-12 col-md" }, [
                _c("h4", [
                  _vm.showSportChange
                    ? _c("span", {
                        staticClass: "icon",
                        class: [_vm.sportIcon],
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n                            " +
                      _vm._s(_vm.translations["shared_WouldYouCreate"]) +
                      "\n                            "
                  ),
                  _vm.showSportChange
                    ? _c("span", [_vm._v(_vm._s(_vm.currentSportName))])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.creatingEventName))]),
                  _vm._v("?\n                        "),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "buttons col-auto" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-close",
                      attrs: {
                        href: "#",
                        "data-dismiss": "modal",
                        "aria-label": "Close",
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.translations["invitePlayerSMPartial_btn_Close"]
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.eventType != "tournament" && _vm.eventType != "teamleague"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-sm btn-info createEventBtn",
                          attrs: { href: _vm.createEventUrl },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.translations["topBar_2"]) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          staticClass: "btn btn-sm btn-info createEventBtn",
                          attrs: { to: _vm.createEventUrl },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeModal.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.translations["topBar_2"]) +
                              "\n                        "
                          ),
                        ]
                      ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row jcb" }, [
              _c(
                "div",
                { staticClass: "change col-auto" },
                [
                  _vm.showSportChange
                    ? [
                        _c("p", { staticClass: "d-inline" }, [
                          _vm._v(
                            _vm._s(_vm.translations["change_Sport"]) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: _vm.closeSport,
                                expression: "closeSport",
                              },
                            ],
                            staticClass: "select",
                            class: { active: _vm.isSportOpen },
                            attrs: { href: "#" },
                            on: { click: _vm.toggleSport },
                          },
                          [
                            _c("i", {
                              staticClass: "flag-icon",
                              class: [_vm.sportIcon],
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.currentSportName))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "icon icon-v" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass: "create-event-ul",
                            class: { open: _vm.isSportOpen },
                          },
                          _vm._l(_vm.sortedSports, function (sport) {
                            return _c(
                              "li",
                              {
                                class: {
                                  active: _vm.layout.player.sport == sport.id,
                                },
                                attrs: { "data-sport-value": sport.id },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      change_sport:
                                        _vm.layout.player.sport != sport.id,
                                    },
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setSport(sport.id)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(sport.name))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.eventType == "tournament"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-auto createTournamentInfoText createInfoText",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://rankedin.ladesk.com/507420-2-Tournaments",
                          },
                        },
                        [_vm._v("How does it work?")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.eventType == "teamleague"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-auto createTeamLeagueInfoText createInfoText",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://rankedin.ladesk.com/640247-6-Team-League",
                          },
                        },
                        [_vm._v("How does it work?")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.eventType == "clubleague"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-auto createClubLeagueInfoText createInfoText",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://rankedin.ladesk.com/774315-5-Club-League",
                          },
                        },
                        [_vm._v("How does it work?")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.eventType == "organisation"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-auto createOrganisationInfoText createInfoText",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://rankedin.ladesk.com/141367-7-Club--Organization--Federation--Friends-Group",
                          },
                        },
                        [_vm._v("How does it work?")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.eventType == "ranking"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-auto createRankingInfoText createInfoText",
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://rankedin.ladesk.com/443991-4-Ranking",
                          },
                        },
                        [_vm._v("How does it work?")]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("br"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [
        _c("span", {
          staticClass: "icon icon-plus",
          attrs: { "aria-hidden": "true" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }