var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: `rin-btn-size-${_vm.size} rin-btn-type-${_vm.type}`,
      attrs: { role: _vm.role, disabled: _vm.disabled },
      on: { click: _vm.click },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }