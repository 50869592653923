var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "rin-match-participant-name-wrapper",
      class: { "is-winner": _vm.player.IsWinner },
    },
    [
      _c("BaseFlag", {
        attrs: { country: _vm.player.CountryShort.toLowerCase() },
      }),
      _vm._v(" "),
      _vm.player.ShortName !== "Pending"
        ? _c("router-link", { attrs: { to: _vm.player.PlayerUrl } }, [
            _vm._v("\n        " + _vm._s(_vm.player.ShortName) + "\n    "),
          ])
        : _c("span", [_vm._v(_vm._s(_vm.player.ShortName))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }