var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "trial" }, [
    !_vm.showSuccessScreen
      ? _c(
          "div",
          { staticClass: "trial-information-wrapper" },
          [
            _c("BaseCard", {
              attrs: { type: "secondary" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "header-intro-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.translations[
                                "monetization_Player_ModalSupertitle"
                              ]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "title-small" },
                          [
                            _vm._v("\n                        Rankedin "),
                            _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.translations["monetization_Trial_Plan"]
                                ) +
                                "\n                    "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "title-big" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.translations["monetization_Trial_14Day"]
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "trial-description" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.translations[
                                  "monetization_Trial_Description"
                                ]
                              ) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "trial-body-wrapper" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { type: "warn", size: "l" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.activateTrial.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations[
                                      "monetization_Trial_StartTrial"
                                    ]
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("small", { staticClass: "no-card-needed" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.translations[
                                      "monetization_Trial_NoCard"
                                    ]
                                  ) +
                                  ")"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "trial-modal-subtitle" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.translations[
                                      "monetization_Player_ModalSubtitle"
                                    ]
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "img-responsive",
                                attrs: {
                                  src:
                                    "https://cdn.rankedin.com/images/static/underline-text.svg",
                                  alt: "underline text",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "trial-modal-bulletpoints" },
                              [
                                _c(
                                  "li",
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalBulletpoint1"
                                          ]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalBulletpoint2"
                                          ]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalBulletpoint3"
                                          ]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalBulletpoint4"
                                          ]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Real_Time_Indicator"
                                          ]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalBulletpoint6"
                                          ]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "monetization_Player_ModalBulletpoint7"
                                          ]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "choose-plan-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "trial-modal-pricing-description",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.translations[
                                          "monetization_Plans_PricingText"
                                        ]
                                      ) +
                                      ":\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "trial-modal-plans-container" },
                                _vm._l(_vm.plans, function (plan) {
                                  return _c("BaseCard", {
                                    key: plan.priceId,
                                    class: {
                                      "rin-popular-plan-container":
                                        plan.type ===
                                        _vm.nomenclatures
                                          .PlayerSubscriptionPeriodTypesEnum
                                          .Yearly,
                                    },
                                    attrs: {
                                      type: "primary",
                                      size: "s",
                                      shadow: false,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              plan.type ===
                                              _vm.nomenclatures
                                                .PlayerSubscriptionPeriodTypesEnum
                                                .Yearly
                                                ? _c("BaseTypeLabel", {
                                                    attrs: { type: "popular" },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        plan.type ===
                                        _vm.nomenclatures
                                          .PlayerSubscriptionPeriodTypesEnum
                                          .Yearly
                                          ? {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c("span", [_vm._v("-55%")]),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                        {
                                          key: "body",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rin-pro-player-modal-plan-period",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.translations[
                                                          `monetization_Player_ModalPlanPeriod${
                                                            _vm.nomenclatures
                                                              .PlayerSubscriptionPeriodTypesEnum[
                                                              plan.type
                                                            ]
                                                          }`
                                                        ]
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rin-pro-player-modal-plan-cost",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.parsePrice(
                                                          plan.price
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.currencySymbol(
                                                          plan.currency
                                                        )
                                                      ) +
                                                      "\n                                            "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                                                /\n                                                " +
                                                        _vm._s(
                                                          _vm.translations[
                                                            `monetization_Player_ModalPlanPer${
                                                              _vm.nomenclatures
                                                                .PlayerSubscriptionPeriods[
                                                                _vm
                                                                  .nomenclatures
                                                                  .PlayerSubscriptionPeriodTypesEnum[
                                                                  plan.type
                                                                ]
                                                              ]
                                                            }`
                                                          ]
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rin-pro-player-modal-plan-breakdown",
                                                },
                                                [
                                                  plan.type ===
                                                  _vm.nomenclatures
                                                    .PlayerSubscriptionPeriodTypesEnum
                                                    .Yearly
                                                    ? [
                                                        _vm._v(
                                                          "\n                                                12 " +
                                                            _vm._s(
                                                              _vm.translations[
                                                                "monetization_Player_ModalPlanMonths"
                                                              ]
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.annualPlanBreakdown
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.currencySymbol(
                                                                plan.currency
                                                              )
                                                            ) +
                                                            " /\n                                                " +
                                                            _vm._s(
                                                              _vm.translations[
                                                                `monetization_Player_ModalPlanPer${_vm.nomenclatures.PlayerSubscriptionPeriods.Monthly}`
                                                              ]
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cancel-any-time",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(
                                                        _vm.translations[
                                                          "monetization_Player_ModalPlanDescription"
                                                        ]
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "BaseButton",
                                                {
                                                  attrs: {
                                                    type: "warn",
                                                    size: "m",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.actionButton(
                                                        plan.priceId,
                                                        plan.type
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.layout.player
                                                    .isAuthenticated
                                                    ? [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.translations[
                                                                "monetization_Player_ModalPlanBuyButton"
                                                              ]
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.translations[
                                                                "shared_SeeMore"
                                                              ]
                                                            ) +
                                                            "\n                                            "
                                                        ),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "or" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.translations["monetization_Trial_Or"]
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "underlined",
                                on: { click: _vm.close },
                              },
                              [
                                _c("u", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.translations[
                                        "monetization_Trial_StayFree"
                                      ]
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4255350929
              ),
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "trial-success-wrapper" },
          [
            _c("BaseCard", {
              attrs: { type: "secondary" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "title-small" },
                        [
                          _vm._v("\n                        Rankedin "),
                          _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.translations["monetization_Trial_Plan"]
                              ) +
                              "\n                    "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "trial-success" }, [
                        _c("div", { staticClass: "title-big" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.translations["monetization_Trial_Success"]
                              ) +
                              "\n                            " +
                              _vm._s(
                                _vm.translations[
                                  "monetization_Trial_StartedTrial"
                                ]
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "trial-footer" },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: { type: "primary", size: "m" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.close.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.translations["monetization_Trial_GotIt"]
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }