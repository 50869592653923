var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hidden-print" }, [
    _c("footer", { staticClass: "container-fluid container-footer" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row colophon" }, [
        _c(
          "div",
          {
            staticClass:
              "col-xs-12 col-sm-6 col-md-4 col-lg-2 col-lg-offset-1 text-center",
          },
          [
            _c("h4", [_vm._v("Rankedin")]),
            _vm._v(" "),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/" +
                        _vm.layout.language +
                        "/content/shownews/about-us",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_1"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/" +
                        _vm.layout.language +
                        "/content/shownews/rankedin-for-players",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_2"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/" +
                        _vm.layout.language +
                        "/content/shownews/rankedin-for-clubs",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_3"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://cdn.rankedin.com/content/RI_presentation_for_CLUB_LEAGUE.pdf",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_ClubLeague"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/" + _vm.layout.language + "/content/shownews/faq",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_4"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/" + _vm.layout.language + "/home/features",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["shared_Menu_Features"]))]
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center" },
          [
            _c("h4", [_vm._v("General")]),
            _vm._v(" "),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/" + _vm.layout.language + "/termsandconditions",
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_5"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/" + _vm.layout.language + "/privacypolicy",
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_6"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/" + _vm.layout.language + "/home/cookiepolicy",
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.translations["landingPage_BS4_CookiePolicy"])
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "https://rankedin.ladesk.com/" } }, [
                  _vm._v(_vm._s(_vm.translations["footer_8"])),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/" +
                        _vm.layout.language +
                        "/content/shownews/federations",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["shared_T18"]))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://rankedin.ladesk.com/446847-Changelog---Release-Notes---Rankedin-",
                    },
                  },
                  [_vm._v(_vm._s(_vm.translations["footer_changelog"]))]
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center" },
          [
            _c("h4", [
              _vm._v(_vm._s(_vm.translations["home_COntactForm_Contacts"])),
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
          ]
        ),
        _vm._v(" "),
        _vm._m(4),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row attribution" }, [
        _c("p", [
          _vm._v("© " + _vm._s(_vm.yearNow) + " Rankedin. All rights reserved"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row footer-search" }, [
      _c("div", { staticClass: "col-xs-12" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center" },
      [
        _c("h4", [_vm._v("Social")]),
        _vm._v(" "),
        _c("ul", { staticClass: "list-unstyled" }, [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.facebook.com/rankedin",
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "fa fa-facebook fa-fw" }),
                _vm._v(" Facebook"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://www.youtube.com/channel/UC-60J-i89RdsRxLeNKv4FKQ",
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "fa fa-youtube-square fa-fw" }),
                _vm._v(" YouTube"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://twitter.com/RankedinApp",
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "fa fa-twitter fa-fw" }),
                _vm._v(" Twitter"),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("address", [
      _vm._v("\n                    Rankedin AS"),
      _c("br"),
      _vm._v("\n                    Lysaker Torg 8"),
      _c("br"),
      _vm._v("\n                    1366 Lysaker"),
      _c("br"),
      _vm._v("\n                    Norway\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "list-unstyled" }, [
      _c("li", [
        _c("a", { attrs: { href: "mailto:info@rankedin.com" } }, [
          _vm._v("info@rankedin.com"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center" },
      [
        _c("h4", [_vm._v("Certified software")]),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href:
                "http://www.worldsquash.org/calendar/wsf-certified-software/",
              target: "_blank",
            },
          },
          [
            _c("img", {
              staticStyle: { height: "100px" },
              attrs: {
                src:
                  "https://cdn.rankedin.com/images/static/fed/logo_fed_wsf_color.jpg",
                alt: "WSF",
              },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }