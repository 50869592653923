var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _vm._t("title"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "bookmarks-container",
              class: { flash: _vm.isProcessingBookmark || _vm.isLoadingMore },
            },
            [
              _vm.isProPlayer && _vm.hasBookmarks
                ? [
                    _vm._l(_vm.bookmarks, function (bookmark) {
                      return [
                        _c("Card", {
                          key: bookmark.id,
                          attrs: { type: "secondary" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "bookmark-title-container",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bookmarks-header-left-side",
                                          },
                                          [
                                            _c("Label", {
                                              attrs: {
                                                type: _vm.nomenclatures.BookmarkTypes[
                                                  bookmark.bookmarkType
                                                ].toLowerCase(),
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "b",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "event-name-link",
                                                    attrs: {
                                                      to: _vm.getBookmarkURL(
                                                        bookmark
                                                      ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(bookmark.name)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bookmarks-header-right-side",
                                          },
                                          [
                                            _c("Popover", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "trigger",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "opt",
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    "javascript:;",
                                                                },
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "icon icon-dots",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "tooltip",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "tooltip-buttons-wrapper",
                                                          },
                                                          [
                                                            _c(
                                                              "Button",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "tertiary",
                                                                  size: "s",
                                                                },
                                                                nativeOn: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.moveBookmark(
                                                                      bookmark.id,
                                                                      true
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .translations[
                                                                      "bookmarks_MoveUp"
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "Button",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "tertiary",
                                                                  size: "s",
                                                                },
                                                                nativeOn: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.moveBookmark(
                                                                      bookmark.id,
                                                                      false
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .translations[
                                                                      "bookmarks_MoveDown"
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "Button",
                                                              {
                                                                attrs: {
                                                                  type:
                                                                    "tertiary",
                                                                  size: "s",
                                                                },
                                                                nativeOn: {
                                                                  click: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeBookmark(
                                                                      bookmark.id
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .translations[
                                                                      "shared_Remove"
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    bookmark.bookmarkType ==
                                      _vm.nomenclatures.BookmarkTypes[
                                        "Tournament"
                                      ] ||
                                    bookmark.bookmarkType ==
                                      _vm.nomenclatures.BookmarkTypes[
                                        "ClubLeague"
                                      ] ||
                                    bookmark.bookmarkType ==
                                      _vm.nomenclatures.BookmarkTypes[
                                        "TeamLeague"
                                      ] ||
                                    bookmark.bookmarkType ==
                                      _vm.nomenclatures.BookmarkTypes["Team"]
                                      ? [
                                          bookmark.country !== "Unknown"
                                            ? _c("span", [
                                                bookmark.countryCss
                                                  ? _c("span", {
                                                      staticClass:
                                                        "bookmark-flag",
                                                      class: bookmark.countryCss.toLowerCase(),
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                bookmark.city
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "bookmark-city",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            bookmark.city
                                                          ) + ","
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bookmark-country",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(bookmark.country)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          bookmark.bookmarkType ==
                                          _vm.nomenclatures.BookmarkTypes.Team
                                            ? _c("span", {}, [
                                                _c("b", [
                                                  _vm._v(
                                                    "League: " +
                                                      _vm._s(
                                                        bookmark.teamEventName
                                                      )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("br"),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          bookmark.state > 0
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bookmark-status",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.translations[
                                                            "playerProfile_Status"
                                                          ]
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.translations[
                                                            "shared_Events_" +
                                                              _vm.nomenclatures
                                                                .EventStatesReversed[
                                                                bookmark.state
                                                              ]
                                                          ]
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          bookmark.ranking &&
                                          bookmark.bookmarkType !=
                                            _vm.nomenclatures.BookmarkTypes.Team
                                            ? _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bookmark-ranking",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.translations[
                                                            "rankingsPartial_Ranking"
                                                          ]
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          bookmark.ranking
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("br"),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          bookmark.bookmarkType !=
                                            _vm.nomenclatures.BookmarkTypes
                                              .Team &&
                                          _vm.isDateReal(bookmark.startDate)
                                            ? _c("span", {}, [
                                                _c("span", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "bookmark-date-from",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.translations[
                                                              "calendarIndex_DateFilterFrom"
                                                            ]
                                                          ) + ":"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "bookmark-date",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            _vm.momentFunc(
                                                              bookmark.startDate
                                                            )
                                                          ) +
                                                          "\n                                            " +
                                                          _vm._s(
                                                            _vm.translations[
                                                              "calendarIndex_DateFilterTo"
                                                            ].toLowerCase()
                                                          ) +
                                                          "\n                                            " +
                                                          _vm._s(
                                                            _vm.momentFunc(
                                                              bookmark.endDate
                                                            )
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("br"),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    bookmark.bookmarkType ==
                                    _vm.nomenclatures.BookmarkTypes.Organization
                                      ? [
                                          _c("span", [
                                            bookmark.countryCss
                                              ? _c("span", {
                                                  staticClass: "bookmark-flag",
                                                  class: bookmark.countryCss.toLowerCase(),
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            bookmark.city
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bookmark-city",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(bookmark.city) +
                                                        ","
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "bookmark-country",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(bookmark.country)
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("br"),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    bookmark.bookmarkType ==
                                    _vm.nomenclatures.BookmarkTypes
                                      .PlayerProfile
                                      ? [
                                          bookmark.clubName
                                            ? _c("span", [
                                                _c("span", [
                                                  _c("b", [_vm._v("Club:")]),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "bookmark-club",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(bookmark.clubName)
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    }),
                  ]
                : [
                    _vm._l([0, 1], function (num) {
                      return _c("Card", {
                        key: num,
                        staticClass: "watermark",
                        attrs: { type: "secondary" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "bookmark-title-container" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bookmarks-header-left-side",
                                        },
                                        [
                                          _c("Label", {
                                            attrs: { type: "tournament" },
                                          }),
                                          _vm._v(" "),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("b", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "event-name-link",
                                              },
                                              [_vm._v("cool naym")]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "bookmarks-header-right-side",
                                        },
                                        [
                                          _c("Popover", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "trigger",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        { staticClass: "opt" },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "#",
                                                              },
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "icon icon-dots",
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  [
                                    _c("span", [
                                      _c("span", [
                                        _c("span", {
                                          staticClass: "bookmark-flag",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "bookmark-city" },
                                          [_vm._v("cool city,")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "bookmark-country" },
                                          [_vm._v("cool country")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {}, [
                                      _c("b", [_vm._v("League: cool")]),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _c(
                                        "span",
                                        { staticClass: "bookmark-status" },
                                        [
                                          _c("b", [_vm._v("cool status:")]),
                                          _vm._v(
                                            " status cool\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _c(
                                        "span",
                                        { staticClass: "bookmark-ranking" },
                                        [
                                          _c("b", [_vm._v("cool ranking:")]),
                                          _vm._v(
                                            " some cool ranking\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {}, [
                                      _c("span", [
                                        _c(
                                          "span",
                                          { staticClass: "bookmark-date-from" },
                                          [
                                            _c("b", [
                                              _vm._v("cool date watermark:"),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "bookmark-date" },
                                          [
                                            _vm._v(
                                              "\n                                        cool date to other cool date\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]),
                                  ],
                                  _vm._v(" "),
                                  [
                                    _c("span", [
                                      _c("span", [
                                        _c("span", {
                                          staticClass: "bookmark-flag",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "bookmark-city" },
                                          [_vm._v("cool city,")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "bookmark-country" },
                                          [_vm._v(" cool country")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                    ]),
                                  ],
                                  _vm._v(" "),
                                  [
                                    _c("span", {}, [
                                      _c("span", [_c("b", [_vm._v("Club:")])]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "bookmark-club" },
                                        [_vm._v("cool name")]
                                      ),
                                    ]),
                                  ],
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    _vm._v(" "),
                    !_vm.isProPlayer
                      ? _c(
                          "div",
                          { staticClass: "watermark-square" },
                          [
                            _vm._v(
                              '\n\t\t\t\t\tUpgrade to our PRO plan to unlock this feature\n\t\t\t\t\t"Followed PRO" tab will be only visible to you, not to any other visitor\n\t\t\t\t\t'
                            ),
                            _c("UpgradeToProButton", {
                              attrs: { "is-overlayed": false },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hasBookmarks && _vm.isProPlayer
                      ? _c(
                          "div",
                          { staticClass: "watermark-square" },
                          [
                            [
                              _c("div", { staticClass: "followBig" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations["bookmarks_AddBookmarks"]
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "followWrapper" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.translations["Bookmarks_JustClick"]
                                      ) +
                                      "\n                            "
                                  ),
                                  _c(
                                    "Button",
                                    { attrs: { type: "secondary", size: "s" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations["bookmarks_Follow"]
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.translations["Bookmarks_JustClick2"]
                                      ) +
                                      "\n                        "
                                  ),
                                ],
                                1
                              ),
                            ],
                            _vm._v(" "),
                            _c("div", { staticClass: "followSmall" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations["bookmarks_FollowedPRO"]
                                  )
                                ),
                              ]),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
              _vm._v(" "),
              _c("br"),
            ],
            2
          ),
          _vm._v(" "),
          _vm.isProPlayer && _vm.hasBookmarks
            ? _c(
                "div",
                { staticClass: "show-more-wrapper" },
                [
                  _c(
                    "Button",
                    {
                      attrs: { type: "tertiary", size: "s" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.loadMoreBookmarks(_vm.bookmarks.length)
                        },
                      },
                    },
                    [
                      _vm.isLoadingMore
                        ? _c(
                            "span",
                            [_c("Spinner", { attrs: { size: "s" } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isLoadingMore
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.translations["shared_ShowMore"])),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _c("Spinner", { attrs: { size: "m" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }