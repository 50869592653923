var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-matches-placeholder" }, [
    _c("h5", [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.translations["playerProfile_PlaceholderYouHaveNoMatches"]
          ) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("img", {
      attrs: {
        src:
          "https://cdn.rankedin.com/images/static/no-events-matches-placeholder.png",
      },
    }),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("span", [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.translations["playerProfile_PlaceholderPastMatchesAreYourSkill"]
          ) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("span", [
      _vm._v(
        _vm._s(_vm.translations["playerProfile_PlaceholderMatchesWillBeHere"])
      ),
    ]),
    _vm._v(" "),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }