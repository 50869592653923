var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-search-filters" },
    [
      _c(
        "BaseFilterButton",
        {
          attrs: { selected: _vm.filterSelected == "all" },
          nativeOn: {
            click: function ($event) {
              return _vm.filterClick("all")
            },
          },
        },
        [_vm._v("All")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "rin-search-filter-container",
          on: {
            click: function ($event) {
              return _vm.filterClick("tournaments")
            },
          },
        },
        [
          _c(
            "BaseFilterButton",
            { attrs: { selected: _vm.filterSelected == "tournaments" } },
            [_vm._v("\n            Tournaments\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "rin-search-filter-container",
          on: {
            click: function ($event) {
              return _vm.filterClick("clubleagues")
            },
          },
        },
        [
          _c(
            "BaseFilterButton",
            { attrs: { selected: _vm.filterSelected == "clubleagues" } },
            [_vm._v("\n            Club leagues\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "rin-search-filter-container",
          on: {
            click: function ($event) {
              return _vm.filterClick("teamleagues")
            },
          },
        },
        [
          _c(
            "BaseFilterButton",
            { attrs: { selected: _vm.filterSelected == "teamleagues" } },
            [_vm._v("\n            Team leagues\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "rin-search-filter-container",
          on: {
            click: function ($event) {
              return _vm.filterClick("players")
            },
          },
        },
        [
          _c(
            "BaseFilterButton",
            { attrs: { selected: _vm.filterSelected == "players" } },
            [_vm._v("\n            Players\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "rin-search-filter-container",
          on: {
            click: function ($event) {
              return _vm.filterClick("rankings")
            },
          },
        },
        [
          _c(
            "BaseFilterButton",
            { attrs: { selected: _vm.filterSelected == "rankings" } },
            [_vm._v("\n            Rankings\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "rin-search-filter-container",
          on: {
            click: function ($event) {
              return _vm.filterClick("organisations")
            },
          },
        },
        [
          _c(
            "BaseFilterButton",
            { attrs: { selected: _vm.filterSelected == "organisations" } },
            [_vm._v("\n            Organisations\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }