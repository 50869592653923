var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-nav-login-or-create-wrap" },
    [
      _c(
        "router-link",
        {
          attrs: { to: { name: "login", params: { language: _vm.language } } },
          nativeOn: {
            click: function ($event) {
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c("BaseButton", { attrs: { size: "m", type: "success" } }, [
            _vm._v(" Login "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v("\n\tor\n\t"),
      _vm._v(" "),
      _c(
        "BaseButton",
        {
          attrs: { size: "m", type: "tertiary" },
          nativeOn: {
            click: function ($event) {
              return _vm.openRegisterModal.apply(null, arguments)
            },
          },
        },
        [_vm._v(" create account ")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "italic" }, [_vm._v(" for free! ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }