var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { id: "h2hmodal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body", attrs: { id: "h2hmodal-body" } },
            [
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col img" }, [
                  _c("img", {
                    attrs: { width: "88", src: _vm.model.ChallengerImageUrl },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }),
                _vm._v(" "),
                _c("div", { staticClass: "col img text-right" }, [
                  _c("img", {
                    attrs: { width: "88", src: _vm.model.ChallengedImageUrl },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col name" }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _c("i", {
                      staticClass: "flag-icon",
                      class: "flag-icon-" + _vm.model.ChallengerCountry,
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("firstChar")(_vm.model.Challenger1FirstName)
                        ) +
                        ". " +
                        _vm._s(_vm.model.Challenger1LastName)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }),
                _vm._v(" "),
                _c("div", { staticClass: "col name text-right" }, [
                  _c("a", { attrs: { href: "#" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("firstChar")(_vm.model.Challenged1FirstName)
                      ) +
                        ". " +
                        _vm._s(_vm.model.Challenged1LastName)
                    ),
                    _c("i", {
                      staticClass: "flag-icon",
                      class: "flag-icon-" + _vm.model.ChallengedCountry,
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.model.IsDouble
                ? _c("div", { staticClass: "row nowrap" }, [
                    _c("div", { staticClass: "col name" }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("i", {
                          class: "flag-icon-" + _vm.model.Challenger1Country,
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("firstChar")(
                                _vm.model.Challenger2FirstName
                              )
                            ) +
                            ". " +
                            _vm._s(_vm.model.Challenger2LastName)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 text-center m-auto" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col name text-right" }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("firstChar")(_vm.model.Challenged2FirstName)
                          ) +
                            ". " +
                            _vm._s(_vm.model.Challenged2LastName)
                        ),
                        _c("i", {
                          staticClass: "flag-icon",
                          class: "flag-icon-" + _vm.model.Challenged1Country,
                        }),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col score" }, [
                  _vm._v(_vm._s(_vm.model.ChallengerWinsH2H) + " "),
                  _c("span", { staticClass: "small" }, [
                    _vm._v(
                      _vm._s(_vm.model.ChallengerH2HPercents) +
                        "% " +
                        _vm._s(_vm.translations["player_Wins"])
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }),
                _vm._v(" "),
                _c("div", { staticClass: "col score text-right" }, [
                  _c("span", { staticClass: "small" }, [
                    _vm._v(
                      _vm._s(_vm.model.ChallengedH2HPercents) +
                        "% " +
                        _vm._s(_vm.translations["player_Wins"])
                    ),
                  ]),
                  _vm._v(" " + _vm._s(_vm.model.ChallengedWinsH2H)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenger1Age)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["player_Age"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenged1Age)),
                ]),
              ]),
              _vm._v(" "),
              _vm.model.IsDouble
                ? _c("div", { staticClass: "row nowrap" }, [
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenger2Age)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 text-center m-auto" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenged2Age)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenger1BirthPlace)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["player_BirthPlace"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenged1BirthPlace)),
                ]),
              ]),
              _vm._v(" "),
              _vm.model.IsDouble
                ? _c("div", { staticClass: "row nowrap" }, [
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenger2BirthPlace)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 text-center m-auto" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenged2BirthPlace)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenger1Hand)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["player_StrongHand"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenged1Hand)),
                ]),
              ]),
              _vm._v(" "),
              _vm.model.IsDouble
                ? _c("div", { staticClass: "row nowrap" }, [
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenger2Hand)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 text-center m-auto" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenged2Hand)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenger1Backhand)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["player_Backhand"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenged1Backhand)),
                ]),
              ]),
              _vm._v(" "),
              _vm.model.IsDouble
                ? _c("div", { staticClass: "row nowrap" }, [
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenger2Backhand)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 text-center m-auto" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenged2Backhand)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.ChallengerWinsAll)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["player_CareerWins"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.ChallengedWinsAll)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenger1Racket)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["player_Racket"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenger1Shoes)),
                ]),
              ]),
              _vm._v(" "),
              _vm.model.IsDouble
                ? _c("div", { staticClass: "row nowrap" }, [
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenger2Racket)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 text-center m-auto" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenged2Racket)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row nowrap" }, [
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenger1Shoes)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["player_Shoes"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col box text-center" }, [
                  _vm._v(_vm._s(_vm.model.Challenged1Shoes)),
                ]),
              ]),
              _vm._v(" "),
              _vm.model.IsDouble
                ? _c("div", { staticClass: "row nowrap" }, [
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenger2Shoes)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 text-center m-auto" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col box text-center" }, [
                      _vm._v(_vm._s(_vm.model.Challenged2Shoes)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 box text-center" }, [
                  _vm._v(_vm._s(_vm.translations["match_Date"]) + ": "),
                  _c("strong", [_vm._v(_vm._s(_vm.dateString))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 box text-center m-auto" }, [
                  _vm._v(_vm._s(_vm.translations["match_Place"]) + ": "),
                  _c("strong", [_vm._v(_vm._s(_vm.model.Place))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12 box text-center" }, [
                  _vm._v(
                    _vm._s(_vm.translations["clubLeague_Index_Ranking"]) + ": "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.model.Ranking))]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header text-center" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "", "data-dismiss": "modal", "aria-label": "Close" },
        },
        [
          _c("span", {
            staticClass: "icon icon-plus",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }