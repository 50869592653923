var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: { id: "search-results-app", role: "dialog" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.resultsOpen,
              expression: "resultsOpen",
            },
          ],
          staticClass: "modal-dialog",
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "div",
                { staticClass: "secondary-search fixed-search col-12 px-0" },
                [
                  _c(
                    "div",
                    { staticClass: "searchTop-secondary py-2 px-1 col-auto" },
                    [
                      _c("form", { attrs: { action: "javascript:void(0);" } }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col px-0" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchTerm,
                                  expression: "searchTerm",
                                },
                              ],
                              staticClass: "search-input-field",
                              attrs: {
                                type: "text",
                                name: "searchtop",
                                placeholder: "Search Players, Events, Clubs",
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.searchTerm },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) return
                                    _vm.searchTerm = $event.target.value
                                  },
                                  function ($event) {
                                    _vm.searchTerm = $event.target.value
                                  },
                                ],
                                keyup: _vm.search,
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col px-0 col-auto" }, [
                            _c(
                              "button",
                              {
                                staticClass: "search-magnifying-glass",
                                attrs: { type: "submit" },
                                on: { click: _vm.search },
                              },
                              [_c("span", { staticClass: "icon icon-search" })]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "col-1 pl-3 pb-3 pr-1 bg-transparent close-modal",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "aria-label": "Close",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeResults()
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "text-dark icon icon-plus",
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "submenu submenu-search m-auto" }, [
                _c("div", {}, [
                  _c("div", { staticClass: "row jcc jcb-lg aic" }, [
                    _c("div", { staticClass: "col col-auto" }, [
                      _c("ul", [
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showAll()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.translations["refereeCodesPartial_All"]
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { active: _vm.isTournamentsSelected },
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showTournaments()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.translations["topBar_18"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { active: _vm.isPlayersSelected },
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showPlayers()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.translations["topBar_19"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { active: _vm.isRankingSelected },
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showRankings()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.translations["topBar_31"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { active: _vm.isOrganisationsSelected },
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showOrganisations()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.translations["topBar_16"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { active: _vm.isClubLeaguesSelected },
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showClubLeagues()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.translations["topBar_20"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              class: { active: _vm.isTeamLeaguesSelected },
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  return _vm.showTeamLeagues()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.translations["teamLeaguesTitle"])
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "submenuFixSearch col-12" }),
              _vm._v(" "),
              _c("section", { staticClass: "clear" }, [
                _c("div", {}, [
                  _c("div", { staticClass: "row ais" }, [
                    _c("div", { staticClass: "content col-12" }, [
                      _c("div", { staticClass: "searchResults" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.noResults,
                                    expression: "noResults",
                                  },
                                ],
                                staticClass: "col-12 text-center",
                                staticStyle: { "font-size": "20px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.translations["shared_T27"]) + "."
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isTournamentsShown && _vm.tournaments.length > 0
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.translations["topBar_18"])
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.tournaments,
                              function (tournament, index) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isTournamentsShown,
                                        expression: "isTournamentsShown",
                                      },
                                    ],
                                    staticClass: "col-12 searchResultsSingle",
                                  },
                                  [
                                    _c("div", { staticClass: "row nowrap" }, [
                                      _c("div", { staticClass: "cont col" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "flag col-auto" },
                                            [
                                              _c("i", {
                                                staticClass: "flag-icon",
                                                class:
                                                  "flag-icon-" +
                                                  tournament.CountryFlag,
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "name col" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: tournament.EventUrl,
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.closeResults.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(tournament.Name)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "info col-12" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.translations[
                                                      "topBar_26"
                                                    ]
                                                  ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm._f("dateAndTime")(
                                                        tournament.StartDate
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("i", {
                                                  staticClass: "fa",
                                                  class:
                                                    _vm.nomenclatures
                                                      .SportIconClass[
                                                      tournament.Sport
                                                    ],
                                                }),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.isTournamentsShown && _vm.tournaments.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 text-center my-3" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { href: "javascript:;" },
                                        on: { click: _vm.tournamentsLoad },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.translations["shared_T57"])
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPlayersShown && _vm.participants.length > 0
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.translations["topBar_19"])
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.participants,
                              function (participant, index) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isPlayersShown,
                                        expression: "isPlayersShown",
                                      },
                                    ],
                                    staticClass: "col-12 searchResultsSingle",
                                  },
                                  [
                                    _c("div", { staticClass: "row nowrap" }, [
                                      _c("div", { staticClass: "cont col" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "flag col-auto" },
                                            [
                                              _c("i", {
                                                staticClass: "flag-icon",
                                                class:
                                                  "flag-icon-" +
                                                  participant.Flag,
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "name col" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:;",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showParticipantContextButtons(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(participant.Name)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "info col-12" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(participant.Details) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _c("strong", [
                                                  _vm._v(
                                                    "ID: " +
                                                      _vm._s(
                                                        participant.RankedinId
                                                      )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isParticipantContextButtonsShown(
                                                    index
                                                  ),
                                                  expression:
                                                    "isParticipantContextButtonsShown(index)",
                                                },
                                              ],
                                              staticClass: "buttons col-12",
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-success",
                                                  attrs: {
                                                    to:
                                                      "/" +
                                                      _vm.layout.language +
                                                      "/player/" +
                                                      participant.RankedinId,
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.closeResults.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.translations[
                                                        "shared_Matches_Player_Profile"
                                                      ]
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-primary",
                                                  attrs: {
                                                    playerid: participant.Id,
                                                    to:
                                                      "/" +
                                                      _vm.layout.language +
                                                      "/messages/beginchat?playerId=" +
                                                      participant.Id,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.translations[
                                                        "matchesPartial_Message"
                                                      ]
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "close",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.hideParticipantContextButtons()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "icon icon-plus",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.isPlayersShown && _vm.participants.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 text-center my-3" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { href: "javascript:;" },
                                        on: { click: _vm.participantsLoad },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.translations["shared_T57"])
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isRankingShown && _vm.rankings.length > 0
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations["shared_Rankings"]
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.rankings, function (ranking, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isRankingShown,
                                      expression: "isRankingShown",
                                    },
                                  ],
                                  staticClass: "col-12 searchResultsSingle",
                                },
                                [
                                  _c("div", { staticClass: "row nowrap" }, [
                                    _c("div", { staticClass: "cont col" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "flag col-auto" },
                                          [
                                            _c("i", {
                                              staticClass: "flag-icon",
                                              class:
                                                "flag-icon-" + ranking.Flag,
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "name col" }, [
                                          _c("strong", [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "javascript:;" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showRankingContextButtons(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(ranking.Name))]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "info col-12" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(ranking.OrganisationName)
                                              ),
                                              _c("i", {
                                                staticClass: "fa text-active",
                                                class: ranking.SportClass,
                                              }),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.isRankingContextButtonsShown(
                                                  index
                                                ),
                                                expression:
                                                  "isRankingContextButtonsShown(index)",
                                              },
                                            ],
                                            staticClass: "buttons col-12",
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-success",
                                                attrs: {
                                                  to:
                                                    "/" +
                                                    _vm.layout.language +
                                                    "/organisation/" +
                                                    ranking.RankingOrganisationId,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.closeResults.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.translations[
                                                      "tournament_Shared_Organization"
                                                    ]
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-primary",
                                                attrs: {
                                                  to:
                                                    "/" +
                                                    _vm.layout.language +
                                                    "/ranking/" +
                                                    ranking.Id,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.closeResults.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.translations[
                                                      "topBar_31"
                                                    ]
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "btn btn-sm",
                                                attrs: {
                                                  to:
                                                    "/" +
                                                    _vm.layout.language +
                                                    "/ranking/settings/" +
                                                    ranking.Id,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.translations[
                                                      "player_Settings"
                                                    ]
                                                  ) + " "
                                                ),
                                                _c("span", {
                                                  staticClass:
                                                    "icon icon-settings",
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "close",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.hideRankingContextButtons()
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "icon icon-plus",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.isRankingShown && _vm.rankings.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 text-center my-3" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { href: "#" },
                                        on: { click: _vm.rankingsLoad },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.translations["shared_T57"])
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isOrganisationsShown &&
                            _vm.organisations.length > 0
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.translations["topBar_16"])
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.organisations,
                              function (organisation, index) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isOrganisationsShown,
                                        expression: "isOrganisationsShown",
                                      },
                                    ],
                                    staticClass: "col-12 searchResultsSingle",
                                  },
                                  [
                                    _c("div", { staticClass: "row nowrap" }, [
                                      _c("div", { staticClass: "cont col" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "flag col-auto" },
                                            [
                                              _c("i", {
                                                staticClass: "flag-icon",
                                                class:
                                                  "flag-icon-" +
                                                  organisation.Flag,
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "name col" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to:
                                                      "/" +
                                                      _vm.layout.language +
                                                      "/organisation/" +
                                                      organisation.Id,
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.closeResults.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(organisation.Name)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "info col-12" },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(organisation.Location)
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.isOrganisationsShown &&
                            _vm.organisations.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 text-center my-3" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { href: "javascript:;" },
                                        on: { click: _vm.organisationsLoad },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.translations["shared_T57"])
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isClubLeaguesShown && _vm.clubleagues.length > 0
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.translations["topBar_20"])
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.clubleagues, function (clubleague) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isClubLeaguesShown,
                                      expression: "isClubLeaguesShown",
                                    },
                                  ],
                                  staticClass: "col-12 searchResultsSingle",
                                },
                                [
                                  _c("div", { staticClass: "row nowrap" }, [
                                    _c("div", { staticClass: "cont col" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "flag col-auto" },
                                          [
                                            _c("i", {
                                              staticClass: "flag-icon",
                                              class:
                                                "flag-icon-" +
                                                clubleague.CountryFlag,
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "name col" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: clubleague.EventUrl,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.closeResults.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(clubleague.Name)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "info col-12" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.translations["topBar_26"]
                                                ) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm._f("dateAndTime")(
                                                      clubleague.StartDate
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c("i", {
                                                staticClass: "fa",
                                                class:
                                                  _vm.nomenclatures
                                                    .SportIconClass[
                                                    clubleague.Sport
                                                  ],
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.isClubLeaguesShown && _vm.clubleagues.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 text-center my-3" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { href: "javascript:;" },
                                        on: { click: _vm.clubLeaguesLoad },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.translations["shared_T57"])
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isTeamLeaguesShown && _vm.teamleagues.length > 0
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations["teamLeaguesTitle"]
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.teamleagues, function (teamleague) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isTeamLeaguesShown,
                                      expression: "isTeamLeaguesShown",
                                    },
                                  ],
                                  staticClass: "col-12 searchResultsSingle",
                                },
                                [
                                  _c("div", { staticClass: "row nowrap" }, [
                                    _c("div", { staticClass: "cont col" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "flag col-auto" },
                                          [
                                            _c("i", {
                                              staticClass: "flag-icon",
                                              class:
                                                "flag-icon-" +
                                                teamleague.CountryFlag,
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "name col" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: teamleague.EventUrl,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.closeResults.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(teamleague.Name)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "info col-12" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.translations["topBar_26"]
                                                ) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm._f("dateAndTime")(
                                                      teamleague.StartDate
                                                    )
                                                  ) +
                                                  "  "
                                              ),
                                              _c("i", {
                                                staticClass: "fa",
                                                class:
                                                  _vm.nomenclatures
                                                    .SportIconClass[
                                                    teamleague.Sport
                                                  ],
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm.isTeamLeaguesShown && _vm.teamleagues.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 text-center my-3" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: { href: "javascript:;" },
                                        on: { click: _vm.teamLeaguesLoad },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.translations["shared_T57"])
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "submenuNav" }, [
      _c("div", { staticClass: "prev icon icon-v" }),
      _vm._v(" "),
      _c("div", { staticClass: "next icon icon-v" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }