var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    { attrs: { to: { name: _vm.destinationName, params: _vm.params } } },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isAuthorized,
            expression: "!isAuthorized",
          },
        ],
        attrs: {
          src: "https://cdn.rankedin.com/images/email/emailLogo.png",
          alt: "logo",
          height: "25",
        },
      }),
      _vm._v(" "),
      _c("ProfileImage", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isAuthorized,
            expression: "isAuthorized",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }