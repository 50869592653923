var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "rin-topnav wrap" },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$windowSize.isMobile && _vm.showBackArrow,
              expression: "$windowSize.isMobile && showBackArrow",
            },
          ],
          staticClass: "rin-topnav-back-button back",
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("ul", [
        _c(
          "li",
          {
            staticClass: "rin-top-nav-logo",
            class: { "logo-circle": _vm.isAuthorized },
          },
          [
            _c("NavLogo", {
              attrs: { destinationName: _vm.destination, params: _vm.params },
            }),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.$windowSize.isMobile
          ? _c(
              "li",
              { staticClass: "rin-topnav-search-wrap" },
              [
                _c("SearchInput"),
                _vm._v(" "),
                _c("BaseIcon", { attrs: { name: "search" } }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isAuthorized && !_vm.$windowSize.isMobile
          ? _c(
              "li",
              { staticClass: "rin-topnav-login-or-create" },
              [_c("NavLoginOrCreate")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isAuthorized
          ? _c(
              "li",
              { staticClass: "rin-topnav-group" },
              [
                !_vm.isAuthorized && _vm.$windowSize.isMobile
                  ? _c("NavButton", {
                      attrs: { icon: "search" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showSearch.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("NavLink", {
                  attrs: {
                    destinationName: "calendar",
                    params: { language: _vm.language },
                    icon: "calendar-2",
                    description: "",
                  },
                }),
                _vm._v(" "),
                _c("NavButton", {
                  attrs: { icon: "burger-menu" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.navMenuOpen.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isAuthorized && !_vm.$windowSize.isMobile
          ? _c(
              "li",
              { staticClass: "rin-topnav-group rin-topnav-group-m" },
              [
                _c("NavButton", {
                  attrs: {
                    icon: "create",
                    description: _vm.translations["topNavigation_Create"],
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addBodyModal({
                        name: "event-create",
                        forceClose: true,
                      })
                    },
                  },
                }),
                _vm._v(" "),
                _c("NavButton", {
                  attrs: {
                    icon: "follow",
                    description: _vm.translations["topNavigation_FollowedPRO"],
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addBodyModal({
                        name: "bookmarks",
                        forceClose: true,
                      })
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "NavButton",
                  {
                    staticClass: "notification-wrapper",
                    attrs: {
                      icon: "cup-3",
                      description: _vm.translations["topNavigation_Matches"],
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.addBodyModal({
                          name: "profile-matches",
                          forceClose: true,
                        })
                      },
                    },
                  },
                  [
                    _c("template", { slot: "indicator-counter" }, [
                      _vm.notificationsCount > 0 &&
                      _vm.layout.player.IsProPlayer
                        ? _c("div", { staticClass: "circle" }, [
                            _vm._v(_vm._s(_vm.notificationsCount)),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("NavButton", {
                  attrs: {
                    icon: "my-panel",
                    description: _vm.translations["topNavigation_IAdmin"],
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addBodyModal({
                        name: "i-admin",
                        forceClose: true,
                      })
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isAuthorized
          ? _c(
              "li",
              { staticClass: "rin-topnav-group" },
              [
                _c("NavLink", {
                  attrs: {
                    destinationName: "calendar",
                    params: { language: _vm.language },
                    icon: "calendar-2",
                    description: "",
                  },
                }),
                _vm._v(" "),
                _c("NavLink", {
                  attrs: {
                    destinationName: "messages",
                    params: { language: _vm.language },
                    badge: _vm.layout.player.unreadMessages,
                    icon: "chat-2",
                    description: "",
                  },
                }),
                _vm._v(" "),
                _c("NavLink", {
                  attrs: {
                    destinationName: "notifications",
                    params: { language: _vm.language },
                    badge: _vm.layout.player.unreadNotifications,
                    icon: "notification",
                    description: "",
                  },
                }),
                _vm._v(" "),
                _c("NavButton", {
                  attrs: { icon: "burger-menu" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.navMenuOpen.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.lastOpenedBodyModal == "event-create"
        ? _c(
            "portal",
            { attrs: { to: "main-body-modal" } },
            [
              _c("BaseBodyModal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [_c("CreateModal")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3211275214
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lastOpenedBodyModal == "profile-matches"
        ? _c(
            "portal",
            { attrs: { to: "main-body-modal" } },
            [
              _c("BaseBodyModal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("ProfileMatches", {
                            attrs: { "force-own-profile-data": true },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "rin-modal-header" },
                                        [_c("span", [_vm._v("Matches")])]
                                      ),
                                      _vm._v(" "),
                                      _c("hr"),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2649486439
                            ),
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3191248591
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lastOpenedBodyModal == "i-admin"
        ? _c(
            "portal",
            { attrs: { to: "main-body-modal" } },
            [
              _c("BaseBodyModal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("IAdmin", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "rin-modal-header" },
                                        [
                                          _c("span", [
                                            _vm._v("You are MANAGER of:"),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr"),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3582952631
                            ),
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1059298845
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lastOpenedBodyModal == "bookmarks"
        ? _c(
            "portal",
            { attrs: { to: "main-body-modal" } },
            [
              _c("BaseBodyModal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("ProfileBookmarks", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "rin-modal-header" },
                                        [
                                          _c("span", [
                                            _vm._v("Followed "),
                                            _c("i", [_vm._v("PRO")]),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr"),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4005786373
                            ),
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1278685060
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { onclick: "window.history.back()" } }, [
      _c("span", { staticClass: "icon icon-back" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }