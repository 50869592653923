var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "rin-nav-item",
      attrs: {
        to: { name: _vm.destinationName, params: _vm.params, query: _vm.query },
      },
    },
    [
      _c("BaseIcon", { attrs: { name: _vm.icon } }),
      _vm._v(" "),
      _vm.description != null
        ? _c("div", { staticClass: "rin-navlink-description" }, [
            _vm._v("\n        " + _vm._s(_vm.description) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.badge > 0
        ? _c("span", { staticClass: "rin-nav-badge" }, [
            _vm._v("\n        " + _vm._s(_vm.badge) + "\n    "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }