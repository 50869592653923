var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hide,
          expression: "hide",
        },
      ],
      staticClass: "rin-dropdown-container",
    },
    [
      _c(
        "BaseButton",
        {
          staticClass: "rin-dropdown-toggle",
          attrs: {
            size: _vm.buttonSize,
            type: _vm.buttonType,
            role: _vm.buttonRole,
            disabled: _vm.disabled,
          },
          nativeOn: {
            click: function ($event) {
              _vm.state = !_vm.state
            },
          },
        },
        [
          _vm._t("button"),
          _vm._v(" "),
          _c("span", {
            staticClass: "rin-dropdown-toggle-icon",
            class: { "rin-dropdown-toggle-icon-open": _vm.state },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state,
              expression: "state",
            },
          ],
          staticClass: "rin-dropdown-list",
          class: _vm.direction,
          on: { click: _vm.hide },
        },
        [_vm._t("content")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }