var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-modal-create-container" },
    [
      _c("div", { staticClass: "rin-modal-header" }, [
        _vm._v(_vm._s(_vm.translations["topBar_2"])),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "rin-modal-row first-row" }, [
        _c(
          "div",
          { staticClass: "rin-modal-create-entry" },
          [
            _c("BaseCard", {
              attrs: { type: "primary", shadow: true },
              nativeOn: {
                click: function ($event) {
                  return _vm.createPrivateMatch()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rin-modal-create-card-header" },
                        [
                          _c("span", {
                            staticClass: "icon-plus-private-match",
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.translations["createEvent_AddResult"]
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "rin-modal-create-card-body" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations["createEvent_ImproveRating"]
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "rin-modal-row" }, [
        _c(
          "div",
          { staticClass: "rin-modal-create-entry" },
          [
            _c("BaseCard", {
              attrs: { type: "primary", shadow: true },
              nativeOn: {
                click: function ($event) {
                  return _vm.createTournament()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("BaseTypeLabel", { attrs: { type: "tournament" } }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rin-modal-create-card-header" },
                        [_vm._v(_vm._s(_vm.translations["topBar_8"]))]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "rin-modal-create-card-body" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "createEvent_TournamentDescription"
                              ]
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "rin-modal-create-outbound-link",
                attrs: { href: "https://www.youtube.com/watch?v=z_KwoD8vPAs" },
              },
              [_vm._v("watch video")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rin-modal-create-entry" },
          [
            _c("BaseCard", {
              attrs: {
                type: "primary",
                shadow: true,
                "data-toggle": "modal",
                "data-target": "#createEventModal",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.createGenericEvent("clubleague")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("BaseTypeLabel", { attrs: { type: "clubleague" } }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rin-modal-create-card-header" },
                        [
                          _vm._v(
                            _vm._s(_vm.translations["createEvent_LeagueName"])
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "rin-modal-create-card-body" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "createEvent_ClubLeagueDescription"
                              ]
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "rin-modal-create-outbound-link",
                attrs: { href: "https://www.youtube.com/watch?v=-9XrgxW7jjk" },
              },
              [_vm._v("watch video")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rin-modal-create-entry" },
          [
            _c("BaseCard", {
              attrs: {
                type: "primary",
                shadow: true,
                "data-toggle": "modal",
                "data-target": "#createEventModal",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.createGenericEvent("teamleague")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("BaseTypeLabel", { attrs: { type: "teamleague" } }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rin-modal-create-card-header" },
                        [_vm._v(_vm._s(_vm.translations["shared_T29"]))]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "rin-modal-create-card-body" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "createEvent_TeamLeagueDescription"
                              ]
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "rin-modal-create-outbound-link",
                attrs: { href: "https://www.youtube.com/watch?v=kvDNDCwOuQc" },
              },
              [_vm._v("watch video")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rin-modal-create-entry" },
          [
            _c("BaseCard", {
              attrs: {
                type: "primary",
                shadow: true,
                "data-toggle": "modal",
                "data-target": "#createEventModal",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.createGenericEvent("ranking")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_c("BaseTypeLabel", { attrs: { type: "ranking" } })]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rin-modal-create-card-header" },
                        [_vm._v(_vm._s(_vm.translations["topBar_31"]))]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "rin-modal-create-card-body" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations["createEvent_RankingDescription"]
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "rin-modal-create-outbound-link",
                attrs: { href: "https://www.youtube.com/watch?v=HxWkiEriTf4" },
              },
              [_vm._v("watch video")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rin-modal-create-entry" },
          [
            _c("BaseCard", {
              attrs: {
                type: "primary",
                shadow: true,
                "data-toggle": "modal",
                "data-target": "#createEventModal",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.createGenericEvent("organisation")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("BaseTypeLabel", { attrs: { type: "organisation" } }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rin-modal-create-card-header" },
                        [_vm._v(_vm._s(_vm.translations["topBar_11"]))]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "rin-modal-create-card-body" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "createEvent_OrganizationDescription"
                              ]
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "rin-modal-create-outbound-link",
                attrs: { href: "https://www.youtube.com/watch?v=W3hzC_09l4Y" },
              },
              [_vm._v("watch video")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rin-modal-create-entry" },
          [
            _c("BaseCard", {
              attrs: { type: "primary", shadow: true },
              nativeOn: {
                click: function ($event) {
                  return _vm.createAmericano()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [
                      _c("BaseTypeLabel", { attrs: { type: "americano" } }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "rin-modal-create-card-header" },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.translations[
                                  "layoutHeader_Americano_Create"
                                ]
                              ) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "rin-modal-create-card-body" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "createEvent_AmericanoDescription"
                              ]
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rin-modal-footer" },
        [
          _c("router-link", { attrs: { to: { name: "request-demo" } } }, [
            _vm._v("Request a free demo"),
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: "https://rankedin.ladesk.com/" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.translations["shared_Menu_UserManual"]) +
                "\n\t\t"
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "portal",
            { attrs: { to: "main-base-modal" } },
            [
              _c("BaseModalWrapper", {
                attrs: { size: "l", show: _vm.showModal },
                on: {
                  close: function ($event) {
                    _vm.showModal = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function () {
                        return [
                          _c("ConfirmCreateEvent", {
                            attrs: { type: "tournament" },
                          }),
                          _vm._v(" "),
                          !_vm.isTournamentKillSwitchActive
                            ? _c("PremiumTournament")
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  151187297
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }