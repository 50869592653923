var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BaseModalWrapper", {
        attrs: { size: "l", show: _vm.showAd },
        on: {
          close: function ($event) {
            _vm.showAd = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row align-items-center pro-campaign-container",
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "rin-pro-player-modal-image-container" },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "https://cdn.rankedin.com/images/static/campaign/players-pro-campaign.png",
                              alt: "image player",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column campaign-text" },
                      [
                        _c("div", { staticClass: "rin-campaign-title" }, [
                          _vm._v(
                            _vm._s(_vm.translations["campaign_Pro_Header1"])
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "rin-campaign-subtitle" },
                          [
                            _vm._v(
                              _vm._s(_vm.translations["campaign_Pro_Header2"]) +
                                " "
                            ),
                            _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "not-so-bold p-3" }, [
                          _vm._v(
                            "  " +
                              _vm._s(_vm.translations["campaign_Pro_HeaderSub"])
                          ),
                        ]),
                        _vm._v(" "),
                        _c("BaseCard", {
                          staticClass: "pt-2",
                          attrs: { type: "primary", size: "m", shadow: false },
                          scopedSlots: _vm._u([
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-6 d-flex align-items-start flex-column text-left",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "p-2" },
                                          [
                                            _c("BaseIcon", {
                                              attrs: { name: "check" },
                                            }),
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.translations[
                                                    "campaign_Pro_Feature2"
                                                  ]
                                                ) +
                                                "\n                                        "
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "p-2" },
                                          [
                                            _c("BaseIcon", {
                                              attrs: { name: "check" },
                                            }),
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.translations[
                                                    "campaign_Pro_Feature3"
                                                  ]
                                                ) +
                                                "\n                                        "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-6 d-flex align-items-start flex-column text-left",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "p-2" },
                                          [
                                            _c("BaseIcon", {
                                              attrs: { name: "check" },
                                            }),
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.translations[
                                                    "campaign_Pro_Feature4"
                                                  ]
                                                ) +
                                                "\n                                        "
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "p-2" },
                                          [
                                            _c("BaseIcon", {
                                              attrs: { name: "check" },
                                            }),
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.translations[
                                                    "campaign_Pro_Feature1"
                                                  ]
                                                ) +
                                                "\n                                        "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt-2 col rin-campaign-feature-others",
                                      },
                                      [
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.translations[
                                                "campaign_Pro_FeatureOthers"
                                              ]
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-pro p-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.translations["campaign_Pro_StartPriceEUR"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "p-2 rin-campaign-footer" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.translations["campaign_Pro_FooterText"]
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "p-3 d-flex justify-content-center" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { type: "primary", size: "l" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.seeMore.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.translations[
                                        "campaign_Pro_ButtonText"
                                      ]
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        attrs: { size: "l", show: _vm.showUpgradeModal },
        on: {
          close: function ($event) {
            _vm.showUpgradeModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [_c("ModalProPlayers")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }