var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-default navbar-logged-in -navbar-fixed-top",
      attrs: { role: "navigation" },
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "navbar-header pull-left" }, [
            _c("a", {
              staticClass: "navbar-brand",
              attrs: { href: _vm.data.PlayerProfileUrl },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "navbar-header pull-right" }, [
            _c(
              "ul",
              {
                staticClass:
                  "nav navbar-nav navbar-static pull-left navbarList",
              },
              [
                _c("li", { staticClass: "visible-xs" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/" + _vm.layout.language + "/referee/refereeboard",
                      },
                    },
                    [_c("i", { staticClass: "fa fa-fw ti-mobile" })]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "visible-xs" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "/" + _vm.layout.language + "/messages" },
                    },
                    [_c("i", { staticClass: "fa fa-fw ti-comment" })]
                  ),
                  _vm._v(" "),
                  _vm.data.MessagesCount > 0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "badge badge-notification progress-bar-danger",
                        },
                        [_vm._v(_vm._s(_vm.data.MessagesCount))]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("li", { attrs: { id: "bell" } }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/" + _vm.layout.language + "/notifications",
                      },
                    },
                    [_c("i", { staticClass: "fa fa-fw ti-bell" })]
                  ),
                  _vm._v(" "),
                  _vm.data.NotificationsCount > 0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "badge badge-notification progress-bar-danger text-center",
                        },
                        [_vm._v(_vm._s(_vm.data.NotificationsCount))]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "dropdown hidden-xs",
                    attrs: { id: "my-ranked" },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        staticClass: "dropdown-menu dropdown-menu-myranked",
                        staticStyle: { right: "0", left: "auto" },
                        attrs: { role: "menu" },
                      },
                      [
                        _c("li", [
                          _c(
                            "a",
                            { attrs: { href: _vm.data.PlayerProfileUrl } },
                            [_vm._v(_vm._s(_vm.translations["shared_T22"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", {
                          staticClass: "divider",
                          attrs: { role: "separator" },
                        }),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" + _vm.layout.language + "/player/manage",
                              },
                            },
                            [_vm._v(_vm._s(_vm.translations["shared_T23"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" +
                                  _vm.layout.language +
                                  "/player/organizations",
                              },
                            },
                            [_vm._v(_vm._s(_vm.translations["shared_T24"]))]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isAdminLocal === "true"
                          ? [
                              _c("li", {
                                staticClass: "divider",
                                attrs: { role: "separator" },
                              }),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/" +
                                        _vm.layout.language +
                                        "/statistics",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.translations[
                                          "shared_Events_AdminPanel"
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li", {
                          staticClass: "divider",
                          attrs: { role: "separator" },
                        }),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass: "dropdown dropdown-nested",
                            attrs: { role: "presentation" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-toggle",
                                attrs: {
                                  id: "changeLang7",
                                  href: "#",
                                  "data-toggle": "dropdown",
                                  role: "button",
                                  "aria-expanded": "false",
                                },
                              },
                              [
                                _c("i", {
                                  class:
                                    "flag-icon flag-icon-" +
                                    _vm.countryflagCode,
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "hidden-sm navButton" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.translations[
                                          "shared_ContentEditor_Language"
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "caret" }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass: "dropdown-menu",
                                attrs: {
                                  role: "menu",
                                  "aria-labelledby": "changeLang7",
                                },
                              },
                              [_c("language-switch-items")],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("li", {
                          staticClass: "divider",
                          attrs: { role: "separator" },
                        }),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/" + _vm.layout.language + "/settings",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.translations["player_Settings"])
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" + _vm.layout.language + "/account/logoff",
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-fw fa-sign-out fa-rotate-180",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.translations["landing_6"])
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "navbar-toggle navbarMobileDropdown",
                attrs: {
                  "data-toggle": "collapse",
                  "data-target": ".navbar-collapse",
                },
              },
              [
                _c("span", { staticClass: "sr-only" }, [
                  _vm._v(_vm._s(_vm.translations["shared_T25"])),
                ]),
                _c("i", { staticClass: "fa fa-bars" }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("search"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "collapse navbar-collapse navbar-left navbarDropdown",
            },
            [
              _c(
                "ul",
                { staticClass: "nav navbar-nav navbar-iconic pull-right" },
                [
                  _c("li", { staticClass: "dropdown visible-xs" }, [
                    _c("a", { attrs: { href: _vm.data.PlayerProfileUrl } }, [
                      _c("i", { staticClass: "fa fa-fw ti-user" }),
                      _c("p", { staticClass: "navButton" }, [
                        _vm._v(_vm._s(_vm.translations["shared_T22"])),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown visible-xs" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/" + _vm.layout.language + "/player/manage",
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw ti-flag-alt-2" }),
                        _c("p", { staticClass: "navButton" }, [
                          _vm._v(_vm._s(_vm.translations["shared_T23"])),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown visible-xs" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/" +
                            _vm.layout.language +
                            "/player/manage?tab=organisations",
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw ti-flag-alt-2" }),
                        _c("p", { staticClass: "navButton" }, [
                          _vm._v(_vm._s(_vm.translations["shared_T24"])),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "dropdown", attrs: { id: "create" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-toggle",
                          attrs: {
                            href: "#",
                            "data-toggle": "dropdown",
                            role: "button",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-fw ti-plus" }),
                          _c("span", { staticClass: "caret" }),
                          _c(
                            "p",
                            {
                              staticClass: "hidden-sm navButton",
                              staticStyle: { "margin-left": "5px" },
                            },
                            [_vm._v(_vm._s(_vm.translations["topBar_2"]))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "dropdown-menu dropdown-menu-create",
                          staticStyle: { "padding-top": "15px" },
                          attrs: { role: "menu" },
                        },
                        [
                          _c("div", { staticClass: "text-right" }, [
                            _c(
                              "a",
                              {
                                staticClass: "bold btn btn-primary btn-sm",
                                attrs: {
                                  href: "https://rankedin.ladesk.com/",
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations["shared_Menu_UserManual"]
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("h2", { staticClass: "h4 text-uppercase" }, [
                              _vm._v(_vm._s(_vm.translations["topBar_4"])),
                              _c("br"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "container-controls" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-xs-6" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-orange col-xs-12 ranking-id",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#createEventModal",
                                        "data-event-name":
                                          _vm.translations["topBar_8"],
                                        "data-event-type": "tournament",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.translations["topBar_8"])
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-xs-6" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info col-xs-12",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#createEventModal",
                                        "data-event-name": "Team League",
                                        "data-event-type": "teamleague",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "shared_EventType_TeamLeague"
                                          ]
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("hr", { staticStyle: { margin: "5px 0" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-xs-6" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-dark col-xs-12",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#createEventModal",
                                        "data-event-name":
                                          _vm.translations["topBar_7"],
                                        "data-event-type": "clubleague",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.translations["topBar_7"])
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-xs-6" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-info col-xs-12",
                                      attrs: {
                                        href:
                                          "/" +
                                          _vm.layout.language +
                                          "/americano/create",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "layoutHeader_Americano_Create"
                                          ]
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("hr", { staticStyle: { margin: "5px 0" } }),
                            ]),
                            _vm._v(" "),
                            _c("h2", { staticClass: "h4 text-uppercase" }, [
                              _vm._v(_vm._s(_vm.translations["shared_T13"])),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-xs-6" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-yellow col-xs-12",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#createEventModal",
                                      "data-event-name":
                                        _vm.translations["topBar_11"],
                                      "data-event-type": "organisation",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.translations["topBar_11"])
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-xs-6" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-yellow col-xs-12",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#createEventModal",
                                      "data-event-name":
                                        _vm.translations["topBar_31"],
                                      "data-event-type": "ranking",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.translations["topBar_31"])
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown", attrs: { id: "club" } }, [
                    _c("a", { attrs: { href: _vm.data.HomeClubUrl } }, [
                      _c("i", { staticClass: "fa fa-fw ti-flag-alt" }),
                      _c("p", { staticClass: "hidden-sm navButton" }, [
                        _vm._v(
                          _vm._s(_vm.translations["clubsViewPartial_Club"])
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "dropdown", attrs: { id: "rankings" } },
                    [
                      _c("a", { attrs: { href: _vm.data.HomeRankingUrl } }, [
                        _c("i", { staticClass: "fa fa-fw ti-cup" }),
                        _c("p", { staticClass: "hidden-sm navButton" }, [
                          _vm._v(_vm._s(_vm.translations["shared_Rankings"])),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "dropdown", attrs: { id: "calendar" } },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/" + _vm.layout.language + "/calendar",
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-fw ti-calendar" }),
                          _c("p", { staticClass: "hidden-sm navButton" }, [
                            _vm._v(
                              _vm._s(_vm.translations["shared_Menu_Calendar"])
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown hidden-xs" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-space",
                        attrs: {
                          href:
                            "/" + _vm.layout.language + "/referee/refereeboard",
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw ti-mobile" }),
                        _c("p", { staticClass: "hidden-sm navButton" }, [
                          _vm._v(
                            _vm._s(_vm.translations["shared_Menu_EReferee"])
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "dropdown hidden-xs",
                      attrs: { id: "messages" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "/" + _vm.layout.language + "/messages",
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-fw ti-comment" }),
                          _c("p", { staticClass: "hidden-sm navButton" }, [
                            _vm._v(_vm._s(_vm.translations["shared_T28"])),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.data.MessagesCount > 0
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-notification progress-bar-danger",
                            },
                            [_vm._v(_vm._s(_vm.data.MessagesCount))]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isAdminLocal === "true"
                    ? [
                        _c("li", {
                          staticClass: "divider",
                          attrs: { role: "separator" },
                        }),
                        _vm._v(" "),
                        _c("li", { staticClass: "dropdown visible-xs" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/" + _vm.layout.language + "/statistics",
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-fw ti-desktop" }),
                              _c("p", { staticClass: "navButton" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations["shared_Events_AdminPanel"]
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "dropdown visible-xs",
                      attrs: { role: "presentation" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-toggle text-right",
                          attrs: {
                            href: "#",
                            "data-toggle": "dropdown",
                            role: "button",
                            "aria-expanded": "false",
                          },
                        },
                        [
                          _c("i", {
                            class: "flag-icon flag-icon-" + _vm.countryflagCode,
                          }),
                          _c("span", { staticClass: "caret" }),
                          _c(
                            "p",
                            {
                              staticClass: "hidden-sm navButton",
                              staticStyle: { "margin-left": "5px" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "shared_ContentEditor_Language"
                                  ]
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "dropdown-menu",
                          attrs: { role: "menu" },
                        },
                        [_c("language-switch-items")],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown visible-xs" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/" + _vm.layout.language + "/settings",
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-fw ti-settings" }),
                        _c("p", { staticClass: "navButton" }, [
                          _vm._v(_vm._s(_vm.translations["player_Settings"])),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "dropdown visible-xs" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/" + _vm.layout.language + "/account/logoff",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-fw fa-sign-out fa-rotate-180",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _c("p", { staticClass: "hidden-sm navButton" }, [
                          _vm._v(_vm._s(_vm.translations["landing_6"])),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: {
          href: "#",
          "data-toggle": "dropdown",
          role: "button",
          "aria-expanded": "false",
        },
      },
      [
        _c("i", { staticClass: "fa fa-fw ti-user" }),
        _c("span", { staticClass: "caret" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }