var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-register-email-password-container rin-register-form" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "rin-register-email-password-subtitle" }, [
        _vm._v(_vm._s(_vm.translations["register_CreateFreeAccount"])),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rin-register-form-container" },
        [
          _c("div", { staticClass: "rin-register-input-container" }, [
            _c(
              "label",
              { staticClass: "rin-register-label", attrs: { for: "email" } },
              [_vm._v(_vm._s(_vm.translations["email"]))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.useremail,
                  expression: "useremail",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "rin-register-input",
              class: {
                "has-error":
                  _vm.$v.useremail.$dirty &&
                  (!_vm.$v.useremail.required ||
                    !_vm.$v.useremail.isValidEmail ||
                    _vm.existingAccount ||
                    !_vm.$v.useremail.minLength ||
                    !_vm.$v.useremail.maxLength),
              },
              attrs: {
                id: "email",
                type: "email",
                autocomplete: "off",
                placeholder: _vm.translations["email"],
                inputmode: "search",
              },
              domProps: { value: _vm.useremail },
              on: {
                blur: [
                  function ($event) {
                    return _vm.$v.useremail.$touch()
                  },
                  function ($event) {
                    return _vm.$forceUpdate()
                  },
                ],
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.useremail = $event.target.value.trim()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c(
                "BaseAlert",
                { attrs: { type: "danger", "has-background": false } },
                [
                  _vm.$v.useremail.$dirty &&
                  (!_vm.$v.useremail.required || !_vm.$v.useremail.isValidEmail)
                    ? _c("span", { staticClass: "rin-register-error" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.translations["register_InvalidEmail"]) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$v.useremail.$dirty && !_vm.$v.useremail.minLength
                    ? _c("span", { staticClass: "rin-register-error" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.interpolateString(
                                _vm.translations["register_FieldMinLength"],
                                [6]
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$v.useremail.$dirty && !_vm.$v.useremail.maxLength
                    ? _c("span", { staticClass: "rin-register-error" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.interpolateString(
                                _vm.translations["register_FieldMaxLength"],
                                [150]
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.existingAccount
                    ? _c("span", { staticClass: "rin-register-error" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "register_UserExistsValidationMessage"
                              ]
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "rin-register-input-container" }, [
            _c(
              "label",
              { staticClass: "rin-register-label", attrs: { for: "password" } },
              [_vm._v(_vm._s(_vm.translations["register_Password"]))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userpassword,
                  expression: "userpassword",
                },
              ],
              staticClass: "rin-register-input",
              class: {
                "has-error":
                  _vm.$v.userpassword.$dirty &&
                  (!_vm.$v.userpassword.required ||
                    !_vm.$v.userpassword.minLength),
              },
              attrs: {
                id: "password",
                type: "password",
                inputmode: "search",
                placeholder: _vm.translations["register_Password"],
                autocomplete: "new-password",
              },
              domProps: { value: _vm.userpassword },
              on: {
                blur: function ($event) {
                  return _vm.$v.userpassword.$touch()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.userpassword = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c(
                "BaseAlert",
                { attrs: { type: "danger", "has-background": false } },
                [
                  _vm.$v.userpassword.$dirty && !_vm.$v.userpassword.required
                    ? _c("span", { staticClass: "rin-register-error" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "register_PasswordRequiredValidation"
                              ]
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$v.userpassword.$dirty && !_vm.$v.userpassword.minLength
                    ? _c("span", { staticClass: "rin-register-error" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "register_PasswordMinLengthValidation"
                              ]
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rin-register-checkbox-container mt-2" },
            [
              _c("BaseCheckbox", {
                attrs: { "is-checked": _vm.userNewsletter },
                nativeOn: {
                  click: function ($event) {
                    _vm.userNewsletter = !_vm.userNewsletter
                  },
                },
              }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.translations["register_NewsletterLabel"])),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rin-register-checkbox-container" },
            [
              _c("BaseCheckbox", {
                attrs: { "is-checked": _vm.userTerms },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleTerms.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.termsTranslation) },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c(
                "BaseAlert",
                { attrs: { type: "danger", "has-background": false } },
                [
                  _vm.$v.userTerms.$dirty && !_vm.$v.userTerms.sameAs
                    ? _c("span", { staticClass: "rin-register-error" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.translations[
                                "register_YouHaveToAcceptTermsFirst"
                              ]
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BaseButton",
            {
              attrs: { type: "success", size: "m", disabled: _vm.isChecking },
              on: { click: _vm.goToNext },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.translations["register_CreateAccount"]) +
                  "\n\t\t"
              ),
            ]
          ),
          _vm._v(" "),
          _c("h6", [
            _vm._v("Already a member? "),
            _c("span", { on: { click: _vm.goToLogin } }, [_vm._v("Log in")]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [
      _c("span", [_vm._v("Welcome to")]),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "https://cdn.rankedin.com/images/static/logoBlue.svg",
          alt: "logo",
          height: "20",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }