var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _vm._t("title"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iadmin-container" },
            _vm._l(_vm.model, function (entry) {
              return _c("Card", {
                key: entry.$id,
                attrs: { type: "secondary" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "event-card-header" }, [
                            _c(
                              "div",
                              [
                                _c("Label", { attrs: { type: entry.label } }),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "header-text" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(entry.title) +
                                      "\n                        "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    entry.Payload.length > 0
                      ? {
                          key: "body",
                          fn: function () {
                            return [
                              _c("hr"),
                              _vm._v(" "),
                              _vm._l(entry.Payload, function (event) {
                                return _c(
                                  "div",
                                  {
                                    key: entry.label + event.Id,
                                    staticClass: "event-wrapper",
                                  },
                                  [
                                    entry.label === "americano"
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "collapse-toggle" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "americano-link",
                                                  attrs: {
                                                    to: event.PublicUrl,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(event.Description)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("hr"),
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "collapse-toggle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleCollapse(
                                                    event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _vm._v(
                                                    _vm._s(event.Description)
                                                  ),
                                                  !_vm.isTournamentKillSwitchActive &&
                                                  event.IsPremium
                                                    ? _c("StarIndicator", {
                                                        attrs: { size: "m" },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("span", {
                                                class: {
                                                  "icon icon-v": !event.isCollapsed,
                                                  "icon icon-v collapsed":
                                                    event.isCollapsed,
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                "collapse-body-collapsed":
                                                  event.isCollapsed,
                                                "collapse-body": !event.isCollapsed,
                                              },
                                            },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "event-link-public",
                                                  attrs: {
                                                    to: event.PublicUrl,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.translations[
                                                        "iAdmin_PublicPage"
                                                      ]
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("br"),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "event-link-admin",
                                                  attrs: { to: event.AdminUrl },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.translations[
                                                        "iAdmin_AdminPage"
                                                      ]
                                                    ) + " "
                                                  ),
                                                  _c("span", {
                                                    staticClass:
                                                      "icon icon-pencil",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("hr"),
                                        ],
                                  ],
                                  2
                                )
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    entry.Payload.length === 0
                      ? {
                          key: "header",
                          fn: function () {
                            return [
                              _c("Alert", { attrs: { type: "info" } }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.translations["iAdmin_ListEmpty"]
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    entry.Payload.length > 0
                      ? {
                          key: "footer",
                          fn: function () {
                            return [
                              entry.label === "americano"
                                ? _c(
                                    "div",
                                    { staticClass: "button-wrapper" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "rin-btn-size-s rin-btn-type-tertiary",
                                          attrs: {
                                            to: `/${_vm.layout.language}/player/${_vm.rankedinId}/${_vm.urlName}/americano/0`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translations["iAdmin_ShowAll"]
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : entry.label === "organisation"
                                ? _c(
                                    "div",
                                    { staticClass: "button-wrapper" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "rin-btn-size-s rin-btn-type-tertiary",
                                          attrs: {
                                            to: `/${_vm.layout.language}/player/manage?tab=organisations`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translations["iAdmin_ShowAll"]
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "button-wrapper" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "rin-btn-size-s rin-btn-type-tertiary",
                                          attrs: {
                                            to: `/${_vm.layout.language}/player/manage`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translations["iAdmin_ShowAll"]
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        2
      )
    : _c("Spinner", { attrs: { size: "m" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }