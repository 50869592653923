var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isLoaded ? _c("BaseSpinner", { staticClass: "mt-5" }) : _vm._e(),
      _vm._v(" "),
      !_vm.isLoaded
        ? _c("div", { staticClass: "progress m-5" }, [
            _c("div", {
              staticClass:
                "progress-bar progress-bar-striped progress-bar-animated",
              style: { width: _vm.progress + "%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "0",
                "aria-valuemin": "0",
                "aria-valuemax": "100",
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded && _vm.isBS3 ? _c("mainAppBs3") : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded && !_vm.isBS3 ? _c("mainAppBs4") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }