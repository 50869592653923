var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: { id: "betsson-campaign-modal", tabindex: "-1", role: "dialog" },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                },
                [_vm._v("\n                ×\n            ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body text-center" }, [
                _c("div", { staticClass: "betsson-body mt-5 mb-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "betsson-mobile",
                      attrs: {
                        href: "https://betssonpadeltour.se",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "betsson-img",
                        attrs: {
                          src:
                            "https://cdn.rankedin.com/images/static/campaign/betsson-sm.jpeg",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "betsson-desktop",
                      attrs: {
                        href: "https://betssonpadeltour.se",
                        target: "_blank",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "betsson-img",
                        attrs: {
                          src:
                            "https://cdn.rankedin.com/images/static/campaign/betsson-lg.jpeg",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }