var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", {
    staticClass: "rin-matches-card",
    attrs: { type: "secondary", border: true },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _c("div", { staticClass: "match-title-container" }, [
              _c(
                "div",
                { staticClass: "matches-header-left-side" },
                [
                  _c("BaseLabel", {
                    attrs: {
                      type: _vm.getEventTypeAsString(_vm.invitation.EventType),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "rin-matches-name-wrapper" },
                    [
                      _c("span", {
                        staticClass: "icon-sport icon-custom",
                        class: _vm.invitation.SportIcon,
                      }),
                      _vm._v(" \n                    "),
                      _c(
                        "router-link",
                        {
                          staticClass: "rin-matches-title",
                          attrs: { to: _vm.invitation.EventUrl },
                        },
                        [_vm._v(_vm._s(_vm.invitation.EventName))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "header",
        fn: function () {
          return [
            _vm.invitation.Date !== null
              ? _c("div", { staticClass: "match-header-content" }, [
                  _c(
                    "div",
                    { staticClass: "header-item" },
                    [
                      _c("BaseIcon", { attrs: { name: "calendar" } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "rin-matches-max-width" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getDate(_vm.invitation.StartDate)) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.invitation.Location
              ? _c("div", { staticClass: "match-header-content" }, [
                  _c(
                    "div",
                    { staticClass: "header-item" },
                    [
                      _c("BaseIcon", { attrs: { name: "pin2" } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "rin-matches-max-width" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.invitation.Location) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.invitation.Ranking !== null
              ? _c("div", { staticClass: "match-header-content" }, [
                  _c(
                    "div",
                    { staticClass: "header-item" },
                    [
                      _c("BaseIcon", { attrs: { name: "cup-2" } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "rin-matches-max-width" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.invitation.Ranking) +
                            "\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("hr", { staticClass: "upper-row" }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "invitation-text" }, [
              _vm._v(_vm._s(_vm.invitation.Text)),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("hr", { staticClass: "lower-row" }),
            _vm._v(" "),
            _c("div", { staticClass: "matches-buttons-container" }, [
              _c("div", { staticClass: "left-buttons has-action-buttons" }, [
                _vm.invitation.Buttons.Accept.IsActive
                  ? _c(
                      "a",
                      {
                        staticClass: "rin-btn-size-s rin-btn-type-success",
                        class: { disabled: _vm.isInAsyncRequest },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.acceptInvitation()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.translations[
                                "player_RequestedMessagesPart_Accept"
                              ]
                            ) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.invitation.Buttons.Reject.IsActive
                  ? _c(
                      "a",
                      {
                        staticClass: "rin-btn-size-s rin-btn-type-warn",
                        class: { disabled: _vm.isInAsyncRequest },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.rejectInvitation()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.translations[
                                "player_RequestedMessagesPart_Reject"
                              ]
                            ) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right-buttons" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "rin-btn-size-s rin-btn-type-tertiary",
                      attrs: { to: _vm.invitation.Buttons.VisitEvent.Url },
                    },
                    [_vm._v("Visit Event")]
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }