var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "wrap" }, [
      _c("div", { staticClass: "row jcc jcb-lg" }, [
        _c("div", { staticClass: "col-12 col-md p-0" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-6 col-md-4 col-lg-3" }, [
              _c("h3", [_vm._v("Rankedin")]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse",
                  attrs: { id: "list01", "data-parent": "footer" },
                },
                [
                  _c("ul", [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/" +
                                _vm.languageCode +
                                "/content/shownews/about-us",
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations["footer_1"]))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/" +
                                _vm.languageCode +
                                "/content/shownews/rankedin-for-players",
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations["footer_2"]))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/" +
                                _vm.languageCode +
                                "/content/shownews/rankedin-for-clubs",
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations["footer_3"]))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://cdn.rankedin.com/content/RI_presentation_for_CLUB_LEAGUE.pdf",
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations["footer_ClubLeague"]))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://rankedin.ladesk.com/",
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations["footer_8"]))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://rankedin.ladesk.com/774135-13-FAQ",
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations["footer_4"]))]
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.isIOSorAndroidUserDeviceRin
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/" + _vm.languageCode + "/pricing",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations["shared_Menu_Pricing"]
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-6 col-md-4 col-lg-3" }, [
              _c("h3", [_vm._v("General")]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse",
                  attrs: { id: "list02", "data-parent": "footer" },
                },
                [
                  _c("ul", [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/" + _vm.languageCode + "/termsandconditions",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations["footer_5"]))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.languageCode + "/dataprotection",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.translations[
                                  "landingPage_BS4_DataProtection"
                                ]
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.languageCode + "/privacypolicy",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations["footer_6"]))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.languageCode + "/home/cookiepolicy",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.translations["landingPage_BS4_CookiePolicy"]
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/" +
                                _vm.languageCode +
                                "/content/shownews/federations",
                            },
                          },
                          [_vm._v(_vm._s(_vm.translations["shared_T18"]))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://rankedin.ladesk.com/446847-Changelog---Release-Notes---Rankedin-",
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations["footer_changelog"]))]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-6 col-md-4 col-lg-3" }, [
              _c("h3", [
                _vm._v(_vm._s(_vm.translations["landingPage_BS4_Office"])),
              ]),
              _vm._v(" "),
              _c("h4", [
                _c(
                  "a",
                  {
                    attrs: {
                      "data-toggle": "collapse",
                      href: "#list03",
                      role: "button",
                      "aria-expanded": "false",
                      "aria-controls": "list03",
                    },
                  },
                  [
                    _c("span", { staticClass: "icon icon-plus" }),
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\t" +
                        _vm._s(_vm.translations["landingPage_BS4_Office"])
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-md-4 col-lg-3 order-md-12 text-md-right" },
          [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.translations["landingPage_BS4_SupportCapital"])
              ),
            ]),
            _vm._v(" "),
            _c("h4", [
              _c(
                "a",
                {
                  attrs: {
                    "data-toggle": "collapse",
                    href: "#list04",
                    "aria-expanded": "false",
                    "aria-controls": "list04",
                  },
                },
                [
                  _c("span", { staticClass: "icon icon-plus" }),
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(_vm.translations["landingPage_BS4_SupportCapital"])
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "row jcb jce-md" }, [
              _c(
                "div",
                { staticClass: "copy col-auto col-md-12 order-md-12 p-0" },
                [
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tcopyright " +
                        _vm._s(_vm.yearNow) +
                        " rankedin.com "
                    ),
                    _c("br"),
                    _vm._v("\n\t\t\t\t\t\t\tAll rights reserved\n\t\t\t\t\t\t"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._m(4),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _c(
        "a",
        {
          attrs: {
            "data-toggle": "collapse",
            href: "#list01",
            role: "button",
            "aria-expanded": "false",
            "aria-controls": "list01",
          },
        },
        [_c("span", { staticClass: "icon icon-plus" }), _vm._v(" Rankedin")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", [
      _c(
        "a",
        {
          attrs: {
            "data-toggle": "collapse",
            href: "#list02",
            role: "button",
            "aria-expanded": "false",
            "aria-controls": "list02",
          },
        },
        [_c("span", { staticClass: "icon icon-plus" }), _vm._v(" General")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "collapse",
        attrs: { id: "list03", "data-parent": "footer" },
      },
      [
        _c("p", [
          _vm._v("Rankedin AS "),
          _c("br"),
          _vm._v("Lysaker Torg 8 "),
          _c("br"),
          _vm._v("1366 Lysaker "),
          _c("br"),
          _vm._v("Norway"),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("a", { attrs: { href: "mailto:info@rankedin.com" } }, [
            _vm._v("info@rankedin.com"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "collapse",
        attrs: { id: "list04", "data-parent": "footer" },
      },
      [
        _c("div", { staticClass: "support" }, [
          _c("a", { attrs: { href: "mailto:support@rankedin.com" } }, [
            _vm._v("support@rankedin.com"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social col-auto col-md-12 p-0" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/rankedin",
            target: "_blank",
            title: "fb",
          },
        },
        [_c("span", { staticClass: "icon icon-fb" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://twitter.com/RankedinApp",
            target: "_blank",
            title: "tw",
          },
        },
        [_c("span", { staticClass: "icon icon-tw" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.instagram.com/rankedin.app/",
            target: "_blank",
            title: "in",
          },
        },
        [_c("span", { staticClass: "icon icon-in" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }