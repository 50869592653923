var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-pwa-campaign-container" },
    [
      _c("BaseModalWrapper", {
        staticClass: "rin-android-pwa-popup",
        attrs: { size: "prompt", show: _vm.showAndroidPwaPopup },
        on: {
          close: function ($event) {
            _vm.showAndroidPwaPopup = false & _vm.$emit("close-pwa-modal")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.translations["campaign_Pwa_Title"])),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "rin-pwa-body" },
                  [
                    _c("div", { staticClass: "rin-pwa-description" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1",
                          alt: "Rankedin",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c("h4", [_vm._v("Rankedin")]),
                        _vm._v(" "),
                        _c("h6", [_vm._v("rankedin.app")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "BaseButton",
                      {
                        attrs: { type: "success", size: "s" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.installPwa.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.translations["campaign_Pwa_Install"]) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "rin-pwa-dont-show-again",
                    on: { click: _vm.dontShowAgain },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.translations["campaign_Pwa_Decline"]) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        staticClass: "rin-ios-change-browser-popup",
        attrs: { size: "prompt-bottom", show: _vm.showIosChangeBrowser },
        on: {
          close: function ($event) {
            _vm.showIosChangeBrowser = false & _vm.$emit("close-pwa-modal")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "rin-pwa-header" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://cdn.rankedin.com/images/static/rankedin_app_incon.svg",
                      alt: "Rankedin",
                    },
                  }),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(_vm._s(_vm.translations["campaign_Pwa_Title"])),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "rin-pwa-body" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.translations["campaign_Pwa_Steps_Title"])
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.translations["campaign_Pwa_Steps_Step_1"])
                    ),
                    _c("span", { staticClass: "ml-1" }, [_vm._v("Safari")]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.translations["campaign_Pwa_Steps_Step_2"])
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "rin-pwa-dont-show-again",
                    on: { click: _vm.dontShowAgain },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.translations["campaign_Pwa_Decline"]) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        staticClass: "rin-ios-pwa-popup",
        attrs: { size: "prompt-bottom", show: _vm.showIosPwaPopup },
        on: {
          close: function ($event) {
            _vm.showIosPwaPopup = false & _vm.$emit("close-pwa-modal")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "rin-pwa-header" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1",
                      alt: "Rankedin",
                    },
                  }),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v(_vm._s(_vm.translations["campaign_Pwa_Title"])),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "rin-pwa-body" }, [
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.translations["campaign_Pwa_Title_Ios"]) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm.translations["campaign_Pwa_Steps_Step_1_Ios"]
                        ) +
                        "\n\t\t\t\t\t\t"
                    ),
                    _c("img", {
                      attrs: {
                        src:
                          "https://cdn.rankedin.com/images/static/ios_share_icon.svg",
                        alt: "share",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm.translations["campaign_Pwa_Steps_Step_2_Ios"]
                        ) +
                        "\n\t\t\t\t\t\t"
                    ),
                    _c("img", {
                      attrs: {
                        src:
                          "https://cdn.rankedin.com/images/static/ios_add_to_home.svg",
                        alt: "add to home",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "rin-pwa-dont-show-again",
                    on: { click: _vm.dontShowAgain },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.translations["campaign_Pwa_Decline"]) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        staticClass: "rin-android-change-browser-popup",
        attrs: { size: "prompt", show: _vm.showAndroidChangeBrowser },
        on: {
          close: function ($event) {
            _vm.showAndroidChangeBrowser = false & _vm.$emit("close-pwa-modal")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.translations["campaign_Pwa_Title"])),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "rin-pwa-body" }, [
                  _c("div", { staticClass: "rin-pwa-description" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1",
                        alt: "Rankedin",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c("h4", [_vm._v("Rankedin")]),
                      _vm._v(" "),
                      _c("h6", [_vm._v("rankedin.app")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.translations["campaign_Pwa_Steps_Title"])
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.translations["campaign_Pwa_Steps_Step_1"])
                    ),
                    _c("span", [_vm._v(" Chrome")]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.translations["campaign_Pwa_Steps_Step_2"])
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "rin-pwa-dont-show-again",
                    on: { click: _vm.dontShowAgain },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.translations["campaign_Pwa_Decline"]) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }