var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-search-result-group" },
    [
      _c("h2", { staticClass: "rin-search-result-group-title" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.events, function (result) {
        return _c("Card", {
          key: result.Url,
          attrs: { type: _vm.$windowSize.isMobile ? "secondary" : "primary" },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "div",
                      [
                        _c("Label", {
                          attrs: { type: result.SearchResultType },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "rin-search-result-group-body" },
                      [
                        _c("Flag", { attrs: { country: result.CountryFlag } }),
                        _vm._v(" "),
                        _c(
                          "h4",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: { to: result.Url },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.closeSearch.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(result.Name) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.isTournamentKillSwitchActive &&
                            result.IsPremium
                              ? _c("StarIndicator", { attrs: { size: "s" } })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "rin-search-result-group-description" },
                      [_vm._t("description", null, { item: result })],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rin-search-show-more-wrapper" },
        [
          _c(
            "Button",
            {
              attrs: { size: "s", type: "tertiary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.showMore.apply(null, arguments)
                },
              },
            },
            [
              _vm.isloading
                ? _c("BaseSpinner", { attrs: { size: "s" } })
                : _vm._e(),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.translations["shared_ShowMore"]) +
                  "\n        "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }