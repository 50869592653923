var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "rin-bottom-nav" }, [
    _c("ul", [
      _vm.isAuthorized
        ? _c(
            "li",
            { class: { active: _vm.followedProOpen } },
            [
              _c("NavButton", {
                attrs: {
                  icon: "follow",
                  description: _vm.translations["topNavigation_FollowedPRO"],
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.addBodyModal({
                      name: "bookmarks",
                      forceClose: true,
                    })
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthorized
        ? _c(
            "li",
            { class: { active: _vm.matchesOpen } },
            [
              _c(
                "NavButton",
                {
                  staticClass: "notification-wrapper",
                  attrs: {
                    icon: "cup-3",
                    description: _vm.translations["topNavigation_Matches"],
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.addBodyModal({
                        name: "profile-matches",
                        forceClose: true,
                      })
                    },
                  },
                },
                [
                  _c("template", { slot: "indicator-counter" }, [
                    _vm.notificationsCount > 0
                      ? _c("div", { staticClass: "circle" }, [
                          _vm._v(_vm._s(_vm.notificationsCount)),
                        ])
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthorized
        ? _c(
            "li",
            { class: { active: _vm.createOpen } },
            [
              _c("NavButton", {
                attrs: {
                  icon: "create",
                  description: _vm.translations["topNavigation_Create"],
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.addBodyModal({
                      name: "event-create",
                      forceClose: true,
                    })
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthorized
        ? _c(
            "li",
            { class: { active: _vm.searchOpen } },
            [
              _c("NavButton", {
                attrs: {
                  icon: "search",
                  description: _vm.translations["topNavigation_Search"],
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showSearch.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthorized
        ? _c(
            "li",
            { class: { active: _vm.iAdminOpen } },
            [
              _c("NavButton", {
                attrs: {
                  icon: "my-panel",
                  description: _vm.translations["topNavigation_IAdmin"],
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.addBodyModal({
                      name: "i-admin",
                      forceClose: true,
                    })
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAuthorized
        ? _c(
            "li",
            { staticClass: "rin-bottom-nav-login-or-create" },
            [_c("NavLoginOrCreate")],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }