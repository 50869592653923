var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isProPlayer
    ? _c(
        "div",
        { staticClass: "upgrade-to-pro-button" },
        [
          _vm.isOverlayed
            ? _c(
                "div",
                { staticClass: "rin-upgrade-to-pro-overlay" },
                [
                  _vm._t("top"),
                  _vm._v(" "),
                  _c(
                    "BaseButton",
                    {
                      attrs: { type: "warn", size: _vm.size },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.toggleModal.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "rin-upgrade-to-pro-button-text" },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.translations["monetization_UpgradeTo"]
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("BaseTypeLabel", { attrs: { type: "pro-invert" } }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._t("bottom"),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isOverlayed
            ? _c(
                "BaseButton",
                {
                  attrs: { type: "warn", size: _vm.size },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.toggleModal.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "rin-upgrade-to-pro-button-text" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.translations["monetization_UpgradeTo"]) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("BaseTypeLabel", { attrs: { type: "pro-invert" } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showProPurchaseModal
            ? _c(
                "portal",
                { attrs: { to: "main-base-modal" } },
                [
                  _c("BaseModalWrapper", {
                    attrs: { size: "l", show: _vm.showProPurchaseModal },
                    on: {
                      close: function ($event) {
                        _vm.showProPurchaseModal = false
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function () {
                            return [_c("ModalProPlayers")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3616782622
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showTrialModal
            ? _c(
                "portal",
                { attrs: { to: "main-base-modal" } },
                [
                  _c("BaseModalWrapper", {
                    staticClass: "rin-modal-no-padding",
                    attrs: { size: "m", show: _vm.showTrialModal },
                    on: { close: _vm.closeTrialModal },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _c("TrialModal", {
                                on: {
                                  trialStarted: _vm.trialStarted,
                                  close: _vm.closeTrialModal,
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4141644419
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }