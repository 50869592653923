var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rin-page-top-bar-container" }, [
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "rin-old-store-app-not-supported" }, [
          _vm._v(
            "\n\t\t\t\tMobile APP is no longer supported - please use a Chrome Browser.\n\t\t"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }