var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lpDesign", class: [_vm.landingPageClass] }, [
    _c("div", { staticClass: "wrap" }, [
      _c("div", { staticClass: "burger-wrap row aic jcb" }, [
        _c(
          "div",
          { staticClass: "logo col-auto" },
          [
            _c(
              "router-link",
              { attrs: { to: "/?device=" + _vm.nomenclatures.UserDeviceRin } },
              [
                _c("img", {
                  attrs: {
                    src:
                      "https://cdn.rankedin.com/images/static/logoWLpDesign.svg",
                    width: "200",
                    height: "24",
                    alt: "logo",
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "menuLpDesign col-12 col-lg p-0" }, [
          _c(
            "div",
            { staticClass: "row aic jcc jce-lg" },
            [
              _c("search-not-logged"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "menuLpDesignWrapper col-12 col-lg-auto" },
                [
                  _c("ul", [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.layout.language + "/#features",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.translations["shared_Menu_Features"])
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.layout.language + "/calendar",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.translations["shared_Menu_Events"])
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isIOSorAndroidUserDeviceRin
                      ? _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/" + _vm.layout.language + "/pricing",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations["shared_Menu_Pricing"]
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.layout.language + "/requestdemo",
                            },
                          },
                          [_vm._v("Demo")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: "/" + _vm.layout.language + "#contact",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.translations["shared_Menu_Contact"])
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lang lpDesign col-12 col-lg-auto order-lg-12" },
                [
                  _c(
                    "ul",
                    [
                      _c("li", { staticClass: "active" }, [
                        _c("a", { attrs: { href: "#" } }, [
                          _c("i", {
                            class:
                              "flag-icon flag-icon-" +
                              _vm.countryFlagFromLanguage,
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "icon icon-v" }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("language-switch-items"),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "profile col-12 col-lg-auto" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-menu btn-ghost pro",
                    staticStyle: { cursor: "pointer" },
                  },
                  [_vm._v(_vm._s(_vm.translations["shared_Login_ButtonText"]))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "profileMenu" }, [
                  _c(
                    "form",
                    {
                      staticClass: "lpDesign",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.login()
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col col-12" }, [
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.translations["shared_Login_ButtonText"]
                                ) + ":"
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col col-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.username,
                                expression: "username",
                              },
                            ],
                            attrs: {
                              type: "email",
                              name: "UserName",
                              id: "email",
                              placeholder:
                                _vm.translations["shared_Login_EmailAddress"],
                              required: "",
                            },
                            domProps: { value: _vm.username },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.login()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.username = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col col-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                            ],
                            attrs: {
                              type: "password",
                              name: "Password",
                              id: "password",
                              placeholder:
                                _vm.translations["shared_Login_Password"],
                              required: "",
                            },
                            domProps: { value: _vm.password },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.login()
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.password = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col col-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.rememberMe,
                                expression: "rememberMe",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              name: "RememberMe",
                              id: "remember",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.rememberMe)
                                ? _vm._i(_vm.rememberMe, null) > -1
                                : _vm.rememberMe,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.rememberMe,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.rememberMe = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.rememberMe = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.rememberMe = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "remember" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.translations["shared_Login_RememberMe"]
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col col-12" },
                          [
                            _c(
                              "button",
                              {
                                attrs: {
                                  type: "button",
                                  id: "dropdown-login-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.login()
                                  },
                                },
                              },
                              [
                                _vm.isLoading
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "spinner-border-sm spinner-border",
                                        attrs: { role: "status" },
                                      },
                                      [
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v("Loading..."),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n                                            " +
                                    _vm._s(
                                      _vm.translations[
                                        "shared_Login_ButtonText"
                                      ]
                                    ) +
                                    "\n                                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "forgot",
                                attrs: {
                                  to:
                                    "/" +
                                    _vm.layout.language +
                                    "/account/cantsignin",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.translations[
                                      "shared_Login_ForgotPasswordLink"
                                    ]
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "new" },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.translations["shared_Login_NewHereQ"])
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-info",
                          attrs: {
                            to: "/" + _vm.layout.language + "/account/joinus",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.translations["shared_Login_CreateAccount"]
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "lang lpDesign lpDesignMobile col-12 col-lg-auto order-lg-12",
                    },
                    [
                      _c(
                        "ul",
                        [
                          _c("li", { staticClass: "active" }, [
                            _c("a", { attrs: { href: "#" } }, [
                              _c("i", {
                                class:
                                  "flag-icon flag-icon-" +
                                  _vm.countryFlagFromLanguage,
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "icon icon-v" }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("language-switch-items"),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "poweredBy text-center" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.translations["shared_PoweredBy"]) + ":"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "desktop" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-menu btn-info",
                      attrs: { href: "/" + _vm.layout.language + "#app" },
                    },
                    [_vm._v(_vm._s(_vm.translations["shared_Menu_GetApp"]))]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm._m(2),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        attrs: {
          src: "https://cdn.rankedin.com/images/static/logoBook.svg",
          width: "80",
          height: "18",
          alt: "book 24",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        attrs: {
          src: "https://cdn.rankedin.com/images/static/logoBlue.svg",
          width: "81",
          height: "11",
          alt: "rankedin logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "burger" }, [_c("span")])
  },
]
render._withStripped = true

export { render, staticRenderFns }