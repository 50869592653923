var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `rin-card rin-card-bg-${_vm.type} rin-card-${_vm.hasShadow} rin-card-${_vm.hasBorder}`,
    },
    [
      _vm.$slots.title
        ? _c("div", { staticClass: "rin-card-title" }, [_vm._t("title")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.header
        ? _c("div", { staticClass: "rin-card-header" }, [_vm._t("header")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.body
        ? _c(
            "div",
            {
              staticClass: "rin-card-body",
              class: { "visible-overflow": _vm.isVisibleOverflow },
            },
            [_vm._t("body")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c("div", { staticClass: "rin-card-footer" }, [_vm._t("footer")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }