var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-nav-item" },
    [
      _c("BaseIcon", { attrs: { name: _vm.icon } }),
      _vm._v(" "),
      _vm.description != null
        ? _c("div", { staticClass: "rin-navlink-description" }, [
            _vm._v("\n        " + _vm._s(_vm.description) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("indicator-counter"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }