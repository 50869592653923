var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-confirm-create-event-container" }, [
    _c(
      "div",
      { staticClass: "rin-confirm-create-title" },
      [
        _c("BaseIcon", {
          attrs: { name: _vm.currentSport.name.toLowerCase() },
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.translations["shared_WouldYouCreate"]) +
              "\n\t\t\t" +
              _vm._s(_vm.currentSport.description) +
              "\n\t\t\t" +
              _vm._s(_vm.eventTypeName) +
              "\n\t\t"
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "rin-confirm-create-body" }, [
      _c(
        "div",
        { staticClass: "rin-confirm-create-sport-selection" },
        [
          _c("label", { attrs: { for: "sport" } }, [
            _vm._v(_vm._s(_vm.translations["change_Sport"]) + ":"),
          ]),
          _vm._v(" "),
          _c("BaseDropdown", {
            staticClass: "sport-dropdown",
            attrs: { buttonSize: "m", buttonType: "tertiary" },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.currentSport.description))]),
                  ]
                },
                proxy: true,
              },
              {
                key: "content",
                fn: function () {
                  return _vm._l(_vm.sortedSports, function (sport) {
                    return _c(
                      "li",
                      {
                        key: sport.id,
                        on: {
                          click: function ($event) {
                            return _vm.setSport(sport.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(sport.description) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    )
                  })
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        { attrs: { href: "https://rankedin.ladesk.com/507420-2-Tournaments" } },
        [_vm._v("How does it work?")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rin-confirm-create-footer" },
      [
        _c(
          "BaseButton",
          { attrs: { type: "success", size: "m" } },
          [
            _c("router-link", { attrs: { to: _vm.createEventUrl } }, [
              _vm._v(" " + _vm._s(_vm.translations["topBar_2"])),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }