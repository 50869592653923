var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: _vm.transition, appear: "" } }, [
    _vm.show
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isHidden,
                expression: "!isHidden",
              },
            ],
            ref: "rin-modal",
            staticClass: "rin-modal-wrapper",
            class: {
              "rin-modal-wrapper-over-bottom-nav":
                _vm.size === "prompt-bottom" || _vm.size === "full-window",
            },
            style: _vm.applyContainerStyles,
          },
          [
            _c(
              "div",
              {
                staticClass: "rin-modal",
                class: `rin-modal-size-${_vm.size} rin-modal-${_vm.type}`,
                attrs: { role: "document" },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.clickedOutside,
                        expression: "clickedOutside",
                      },
                    ],
                    staticClass: "rin-modal-content",
                  },
                  [
                    _vm.showHeader
                      ? _c(
                          "div",
                          { staticClass: "rin-modal-header" },
                          [
                            _vm._t("header"),
                            _vm._v(" "),
                            _vm.showCloseIcon
                              ? _c("BaseIcon", {
                                  attrs: { name: "close" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.$emit("close")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "rin-modal-wrapper-body" },
                      [_vm._t("body")],
                      2
                    ),
                    _vm._v(" "),
                    _vm.showFooter
                      ? _c(
                          "div",
                          { staticClass: "rin-modal-footer" },
                          [_vm._t("footer")],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }