var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BaseModalWrapper", {
        attrs: { size: "l", show: _vm.showAd },
        on: {
          close: function ($event) {
            _vm.showAd = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center newsletter-campaign-container rin-newsletter-campaing-wrapper",
                  },
                  [
                    _c("div", { staticClass: "p-4" }, [
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          src:
                            "https://cdn.rankedin.com/images/static/campaign/newsletter-campaign.png",
                          alt: "newsletter campaign image",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column text-center" },
                      [
                        _c("div", { staticClass: "h1 p-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.translations["campaign_Newsletter_Line1"]
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-3" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "  " +
                                _vm._s(
                                  _vm.translations["campaign_Newsletter_Line2"]
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "text-left pt-2" }, [
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "campaign_Newsletter_Line2_1"
                                  ]
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "campaign_Newsletter_Line2_2"
                                  ]
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "campaign_Newsletter_Line2_3"
                                  ]
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "campaign_Newsletter_Line2_4"
                                  ]
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "campaign_Newsletter_Line2_5"
                                  ]
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "font-weight-bold p-3" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.translations["campaign_Newsletter_Line3"]
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "p-3 d-flex justify-content-center" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { type: "success", size: "m" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.subscribe.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                !_vm.isSubscribing
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translations[
                                            "campaign_Newsletter_ButtonText"
                                          ]
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isSubscribing
                                  ? _c("BaseSpinner", { attrs: { size: "s" } })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("BaseModalWrapper", {
        attrs: { size: "s", show: _vm.showConfirmation },
        on: {
          close: function ($event) {
            _vm.showConfirmation = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "p-3 d-flex flex-column text-center newsletter-campaign-container",
                  },
                  [
                    _c("div", { staticClass: "p-2" }, [
                      _c("span", { staticClass: "h1" }, [_vm._v("Thank you!")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "p-2" }, [
                      _vm._v("You have been subscribed successfully."),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex w-100 justify-content-center" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { type: "tertiary", size: "m" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.dismissConfirmation.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.translations["shared_CloseButton"]) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }