var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-search-backdrop" }, [
    _c("div", { staticClass: "wrap rin-search-wrap" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.close,
              expression: "close",
            },
          ],
          staticClass: "rin-search rin-scroll",
        },
        [
          _c(
            "div",
            { staticClass: "rin-search-header" },
            [
              _c("div", { staticClass: "rin-search-header-title" }, [
                _vm._v("\n                    Search\n                "),
              ]),
              _vm._v(" "),
              _c("BaseIcon", {
                attrs: { name: "close" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.close.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-input-mobile" },
            [_c("SearchInput", { attrs: { name: "mobile" } })],
            1
          ),
          _vm._v(" "),
          _vm.state === "results" ? _c("SearchFilter") : _vm._e(),
          _vm._v(" "),
          _vm.state === "loading" ? _c("BaseSpinner") : _vm._e(),
          _vm._v(" "),
          _vm.state === "history" ? _c("SearchHistory") : _vm._e(),
          _vm._v(" "),
          _vm.state === "results" ? _c("SearchResults") : _vm._e(),
          _vm._v(" "),
          _vm.state === "noresults" ? _c("SearchNoRes") : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }