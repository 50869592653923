var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", {
    staticClass: "rin-matches-card",
    attrs: { type: "secondary", border: true },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [
              _c("div", { staticClass: "match-title-container" }, [
                _c(
                  "div",
                  { staticClass: "matches-header-left-side" },
                  [
                    _c("BaseLabel", {
                      attrs: {
                        type: _vm.getEventTypeAsString(
                          _vm.match.Info.EventType
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "rin-matches-name-wrapper" },
                      [
                        _c("span", {
                          staticClass: "icon-sport icon-custom",
                          class: _vm.match.Info.SportIcon,
                        }),
                        _vm._v(" \n                    "),
                        _vm.match.Info.EventType !==
                        _vm.nomenclatures.FrontEndEventTypeEnum.PrivateMatch
                          ? _c(
                              "router-link",
                              {
                                staticClass: "rin-matches-title",
                                class: { underlined: _vm.isEvent(_vm.match) },
                                attrs: { to: _vm.match.Info.EventUrl },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.match.Info.EventName) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "rin-matches-title",
                                class: { underlined: _vm.isEvent(_vm.match) },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.match.Info.EventName) +
                                    "\n                    "
                                ),
                              ]
                            ),
                        _vm._v(" "),
                        !_vm.isTournamentKillSwitchActive &&
                        _vm.match.Info.IsPremium
                          ? _c("StarIndicator", { attrs: { size: "m" } })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "header",
          fn: function () {
            return [
              _vm.match.Info.Date !== null &&
              _vm.match.Info.EventType !==
                _vm.nomenclatures.FrontEndEventTypeEnum.PrivateMatch
                ? _c("div", { staticClass: "match-header-content" }, [
                    _c(
                      "div",
                      { staticClass: "header-item" },
                      [
                        _c("BaseIcon", { attrs: { name: "calendar" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "rin-matches-max-width" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.getDate(_vm.match.Info.Date)) +
                              "\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "header-item" },
                      [
                        _c("BaseIcon", { attrs: { name: "time" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "rin-matches-max-width" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.getHours(_vm.match.Info.Date)) +
                              "\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.match.Info.Location
                ? _c("div", { staticClass: "match-header-content" }, [
                    _c(
                      "div",
                      { staticClass: "header-item" },
                      [
                        _c("BaseIcon", { attrs: { name: "pin2" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "rin-matches-max-width" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.match.Info.Location) +
                              "\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.match.Info.Court !== null
                      ? _c("div", { staticClass: "header-item" }, [
                          _c("div", { staticClass: "rin-matches-max-width" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.match.Info.Court) +
                                "\n                "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.match.Info.Ranking !== null
                ? _c("div", { staticClass: "match-header-content" }, [
                    _c(
                      "div",
                      { staticClass: "header-item" },
                      [
                        _c("BaseIcon", { attrs: { name: "cup-2" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "rin-matches-max-width" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.match.Info.Ranking) +
                              "\n                "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("hr", { staticClass: "upper-row" }),
            ]
          },
          proxy: true,
        },
        {
          key: "body",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "rin-matches-card-body-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rin-matches-card-participant-names-container",
                    },
                    [
                      _c("MatchCardPlayerName", {
                        attrs: { player: _vm.match.Info.Challenger },
                      }),
                      _vm._v(" "),
                      _vm.match.Info.Challenger1 !== null
                        ? _c("MatchCardPlayerName", {
                            attrs: { player: _vm.match.Info.Challenger1 },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.match.Info.Challenged1 !== null
                        ? _c("div", { staticClass: "space" })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("MatchCardPlayerName", {
                        attrs: { player: _vm.match.Info.Challenged },
                      }),
                      _vm._v(" "),
                      _vm.match.Info.Challenged1 !== null
                        ? _c("MatchCardPlayerName", {
                            attrs: { player: _vm.match.Info.Challenged1 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.match.Score &&
                  _vm.match.Score.Score &&
                  _vm.match.Score.Score.length > 0
                    ? _vm._l(_vm.match.Score.Score, function (sc) {
                        return _c(
                          "div",
                          {
                            key: sc.$id,
                            staticClass: "rin-matches-score-container",
                            class: {
                              "doubles-match":
                                _vm.match.Info.Challenger1 !== null,
                            },
                          },
                          [
                            _c("div", { staticClass: "rin-matches-scorebox" }, [
                              _c("span", [_vm._v(_vm._s(sc.Score1))]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(sc.Score2))]),
                            ]),
                          ]
                        )
                      })
                    : [
                        !_vm.isWalkover(_vm.match)
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rin-matches-card-participant-score-container",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rin-matches-card-participant-scorebox",
                                    },
                                    [
                                      _c("span", { staticClass: "invisible" }, [
                                        _vm._v("0"),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "invisible" }, [
                                        _vm._v("0"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isWalkover(_vm.match)
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rin-matches-card-participant-score-container",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "in-matches-card-participant-scorebox",
                                    },
                                    [
                                      _vm.match.Info.Participant1Status ===
                                      _vm.nomenclatures.ParticipantStatuses
                                        .Walkover
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.translations[
                                                  "enterResult_WO"
                                                ]
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.match.Info.Participant1Status ===
                                        _vm.nomenclatures.ParticipantStatuses
                                          .Walkover ||
                                      _vm.match.Info.Participant2Status ===
                                        _vm.nomenclatures.ParticipantStatuses
                                          .Walkover
                                        ? _c("br")
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.match.Info.Participant2Status ===
                                      _vm.nomenclatures.ParticipantStatuses
                                        .Walkover
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.translations[
                                                  "enterResult_WO"
                                                ]
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                  _vm._v(" "),
                  _vm.match.Message.ShowMessageButton && _vm.isOwnProfile
                    ? _c(
                        "div",
                        { staticClass: "rin-matches-card-message" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: _vm.match.Message.ButtonUrl } },
                            [
                              _c("span", {
                                staticClass:
                                  "icon-chat icon-custom float-right",
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
        _vm.isOwnProfile
          ? {
              key: "footer",
              fn: function () {
                return [
                  _c("hr", { staticClass: "lower-row" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "matches-buttons-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "left-buttons",
                        class: { "has-action-buttons": _vm.hasActionButton },
                      },
                      [
                        _vm.match.Buttons.AcceptDate.IsActive
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "rin-btn-size-s rin-btn-type-success",
                                attrs: {
                                  href: `/challenges/acceptclubleaguechallenge/${_vm.match.ChallengeId}`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.translations[
                                        "profileMatchButtonsModel_T1"
                                      ]
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.match.Buttons.ConfirmResult.IsActive
                          ? _c(
                              "BaseButton",
                              {
                                attrs: { type: "success", size: "s" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.confirmResult(
                                      _vm.match.ChallengeId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.translations["button_ConfirmResults"]
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.match.Buttons.AwaitingDateConfirmation.IsActive
                          ? _c("label", { staticClass: "rin-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "profileMatchButtonsModel_T2"
                                  ]
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.match.Buttons.AwaitingResultConfirmation.IsActive
                          ? _c("label", { staticClass: "rin-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.translations[
                                    "matchesPartial_UnconfirmedResultButton"
                                  ].toLowerCase()
                                )
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right-buttons" },
                      [
                        _vm.match.Buttons.EnterResult.IsActive
                          ? _c(
                              "BaseButton",
                              {
                                attrs: { type: "tertiary", size: "s" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.enteringResultsLocal(_vm.match)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.translations[
                                        "matchesPartial_EditResuts"
                                      ]
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.match.Buttons.EditResult.IsActive
                          ? _c(
                              "BaseButton",
                              {
                                attrs: { type: "tertiary", size: "s" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.enteringResultsLocal(_vm.match)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.translations["button_EditResults"]
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.match.Buttons.DeletePrivateChallenge &&
                        _vm.match.Buttons.DeletePrivateChallenge.IsActive
                          ? _c(
                              "BaseButton",
                              {
                                attrs: { type: "tertiary", size: "s" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.deletePrivateChallenge(_vm.match)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.translations["button_Delete"]) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.match.Buttons.SetDate.IsActive
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "rin-btn-size-s rin-btn-type-tertiary",
                                attrs: {
                                  to: `/match/edit/${_vm.match.ChallengeId}`,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.translations["shared_SetDate"])
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.match.Buttons.EditDate.IsActive
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "rin-btn-size-s rin-btn-type-tertiary",
                                attrs: {
                                  to: `/match/edit/${_vm.match.ChallengeId}`,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.translations["shared_EditDate"])
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }