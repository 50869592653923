var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideOnClickOutside,
          expression: "hideOnClickOutside",
        },
      ],
      staticClass: "popover-wrapper",
    },
    [
      _c(
        "div",
        {
          attrs: {
            id: `button${_vm.id}`,
            "aria-describedby": `tooltip${_vm.id}`,
          },
        },
        [_vm._t("trigger")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: `tooltip${_vm.id}`, role: "tooltip" } },
        [
          _vm._t("tooltip"),
          _vm._v(" "),
          _c("div", { attrs: { id: "arrow", "data-popper-arrow": "" } }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }