var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "searchTop col-auto col-lg-3" }, [
    _c("form", { attrs: { action: "javascript:void(0);" } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            staticClass: "search-top-navbar",
            attrs: {
              type: "text",
              name: "searchtop",
              id: "searchtop",
              placeholder: _vm.translations["shared_Search_Global_Placeholder"],
              autocomplete: "off",
            },
            domProps: { value: _vm.searchTerm },
            on: {
              click: _vm.showSearch,
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
                function ($event) {
                  _vm.searchTerm = $event.target.value
                },
              ],
              keyup: _vm.search,
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col col-auto" }, [
          _c(
            "button",
            { attrs: { type: "submit" }, on: { click: _vm.search } },
            [_c("span", { staticClass: "icon icon-search" })]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }