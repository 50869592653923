var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rin-page-top-bar-container" },
    [
      _c("div", { staticClass: "wrap" }, [
        _vm.$windowSize.isMobile
          ? _c(
              "div",
              {
                staticClass: "rin-page-top-bar-back-btn",
                on: { click: _vm.goBack },
              },
              [_c("BaseIcon", { attrs: { name: "back" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "rin-page-top-bar-monetization-description",
            on: { click: _vm.showProPlayersModal },
          },
          [
            _c(
              "div",
              { staticClass: "rin-page-top-bar-upgrade-description" },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.translations[
                        "monetization_Player_TopbarUpgradeDescription1"
                      ]
                    ) +
                    "\n\t\t\t\t"
                ),
                _c("BaseTypeLabel", { attrs: { type: "pro" } }),
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _vm.translations[
                        "monetization_Player_TopbarUpgradeDescription2"
                      ]
                    ) +
                    "\n\t\t\t"
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "rin-page-top-bar-check-btn" }, [
              _c("span", { staticClass: "check-it" }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(
                      _vm.translations["monetization_Player_TopbarCheckIt"]
                    ) +
                    "\n\t\t\t\t"
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "portal",
            { attrs: { to: "main-base-modal" } },
            [
              _c("BaseModalWrapper", {
                attrs: { size: "l", show: _vm.showModal },
                on: {
                  close: function ($event) {
                    _vm.showModal = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body",
                      fn: function () {
                        return [_c("ModalProPlayers")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3616782622
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }