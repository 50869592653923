var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rin-register-phone rin-register-form" }, [
    _c("h1", [_vm._v(_vm._s(_vm.translations["register_PhoneHeading"]))]),
    _vm._v(" "),
    _c("div", { staticClass: "rin-register-input-container" }, [
      _c(
        "label",
        { staticClass: "rin-register-label", attrs: { for: "phone" } },
        [_vm._v(_vm._s(_vm.translations["register_PhoneNumber"]))]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userPhone,
            expression: "userPhone",
          },
        ],
        staticClass: "rin-register-input",
        attrs: {
          id: "phone",
          type: "tel",
          autocomplete: "off",
          placeholder: _vm.translations["register_PhoneNumber"],
        },
        domProps: { value: _vm.userPhone },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.userPhone = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {},
      [
        _c(
          "BaseAlert",
          { attrs: { type: "danger", "has-background": false } },
          [
            _vm.hasError
              ? _c("span", { staticClass: "rin-register-error" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.translations["register_2"]) +
                      "\n\t\t\t"
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }