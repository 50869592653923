var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseModalWrapper", {
    attrs: { size: "l", show: _vm.showAd },
    on: {
      close: function ($event) {
        _vm.showAd = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center private-match-campaign-container",
              },
              [
                _c("div", { staticClass: "p-4" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src:
                        "https://cdn.rankedin.com/images/static/campaign/privatematch-campaign-iphone.png",
                      alt: "private match image",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-column campaign-text" }, [
                  _c("div", { staticClass: "h1 p-3" }, [
                    _vm._v(
                      _vm._s(_vm.translations["campaign_PrivateMatch_Line1"])
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "p-3" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.translations["campaign_PrivateMatch_Line2"]
                        ) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "font-weight-bold p-3" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.translations["campaign_PrivateMatch_Line3"])
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "p-3 d-flex" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { type: "success", size: "m" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.createPrivateMatch.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.translations[
                                  "campaign_PrivateMatch_ButtonText"
                                ]
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }