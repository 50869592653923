var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-body-div" },
    [
      _c("use-google-chrome-bar"),
      _vm._v(" "),
      _vm.layout.player.isAuthenticated ? _c("logos-top-bar") : _vm._e(),
      _vm._v(" "),
      _vm.showHeader
        ? _c(
            "header",
            [_vm.layout.player.isAuthenticated ? _c("top-bar") : _vm._e()],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "container-fluid container-search",
          attrs: { id: "landingPageContainer" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTopNotificationShown,
                  expression: "isTopNotificationShown",
                },
              ],
              staticClass: "alert alert-warning text-center margin-top-sm",
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.topNotificationMessage) +
                  "\n            "
              ),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: { click: _vm.closeTopNotification },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showFooter ? _c("layoutFooter") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }