const DATETIMEPICKER_MALOT_FORMAT = 'dd/mm/yyyy';
const DATETIMEPICKER_MALOT_DATETIME_FORMAT = DATETIMEPICKER_MALOT_FORMAT + ' hh:ii';
const DATETIMEPICKER_EONASDAN_FORMAT = 'DD/MM/YYYY';
const MOMENT_DATE_FORMAT = 'DD/MM/YYYY';
const MOMENT_DATETIME_FORMAT = MOMENT_DATE_FORMAT + ' HH:mm';

const SPIDER_MAX_STAGE = 3;
const SPIDER_MAX_STRENGTH = 4;


if (typeof module == 'undefined') module = { exports: {} };
module.exports.DATETIMEPICKER_MALOT_FORMAT = DATETIMEPICKER_MALOT_FORMAT;
module.exports.MOMENT_DATE_FORMAT = MOMENT_DATE_FORMAT;
module.exports.MOMENT_DATETIME_FORMAT = MOMENT_DATETIME_FORMAT;
module.exports.SPIDER_MAX_STAGE = SPIDER_MAX_STAGE;
module.exports.SPIDER_MAX_STRENGTH = SPIDER_MAX_STRENGTH;